import axios from 'axios'

class SolicitacaoBaixaAPI {
  async getAgendamentosEmPatio(params) {
    try {
      const { data } = await axios.get(`/api/v1/patio/agendamento/em_patio`, {
        params,
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async addSolicitacaoBaixa(dados) {
    try {
      const { data } = await axios.post(`/api/v1/patio/solicitacoes/`, dados)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async finalizarSolicitacaoBaixa(dados) {
    try {
      const { data } = await axios.post(
        `/api/v1/patio/solicitacoes/finalizar/`,
        dados
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getSolicitacoesBaixa(params) {
    try {
      const { data } = await axios.get(`/api/v1/patio/solicitacoes/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getLiberados(params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/solicitacoes/liberados/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getEmTransito(params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/solicitacoes/emTransito/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getAprovados(params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/solicitacoes/aprovados/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async liberarCaminhoes(dados, params) {
    try {
      const { data } = await axios.post(
        `/api/v1/patio/solicitacoes/liberar/`,
        dados,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async confirmarTransito(dados, params) {
    try {
      const {
        data,
      } = await axios.post(
        `/api/v1/patio/solicitacoes/confirmarTransito/`,
        dados,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getResumoSolicitacaoBaixa(params) {
    try {
      const { data } = await axios.get(`/api/v1/patio/solicitacoes/resumo/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getResumoExtraFields(params) {
    try {
      const {
        data,
      } = await axios.get(`/api/v1/patio/solicitacoes/extra_fields/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export default new SolicitacaoBaixaAPI()
