import MenuApi from '@/services/menu/index'

const getDefaultState = () => {
  return {
    items: [],
    acessos: [],
  }
}

export const menu = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setMenuItems(state, menuItems) {
      state.items = menuItems
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    setAcessos(state, acessos) {
      state.acessos = acessos
    }
  },
  actions: {
    async getMenu({ commit }) {
      const menuItems = await MenuApi.getMenu()
      commit('setMenuItems', menuItems)
    },
    async getUserMenu({ commit, dispatch, rootState }, empresa = rootState.auth.empresaAtual) {
      try {
        let menuItems = []
        if (empresa.system_admin) {
          menuItems = await MenuApi.getMenu()
        } else if (empresa.grupo) {
          menuItems = await MenuApi.getUserMenu(rootState.auth.user, empresa.grupo)
        }
        else {
          dispatch("template/errorMessage", "Empresa não possui grupo", { root: true })
        }
        commit('setMenuItems', menuItems)

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getEmpresaAcessos({ commit, rootState }, {empresa = rootState.auth.empresaAtual, perfil}) {
      try {
        const data = await MenuApi.getEmpresaAcessos(empresa.public_id, perfil)
        commit('setAcessos', data)
        return Promise.resolve(data)
      } catch (e) {
        commit('setAcessos', [])
        return Promise.reject(e)
      }
    }
  },
}
