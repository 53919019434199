const parseDate = {
  methods: {
    parseDate(date) {
      if (!date) return null

      return `${`${date.getFullYear()}`.padStart(2, 0)}-${`${
        date.getMonth() + 1
      }`.padStart(2, 0)}-${`${date.getDate()}`.padStart(2, 0)}`
    },
  },
}
const date2br = {
  filters: {
    date2br(data) {
      if (!data) return '-'
      const data_splitted = data.split('-')
      return `${data_splitted[2]}/${data_splitted[1]}/${data_splitted[0]}`
    },
  },
}
const BRString2datetimeIso = {
  methods: {
    BRString2datetimeIso(datetime) {
      if (!datetime) return null
      const [date_part, time_part] = datetime.split(' ')
      const [day, month, year] = date_part.split('/')
      const [hour, minute, second] = time_part ? time_part.split(':') : []
      const date_formated = `${year}-${month}-${day}`
      const time_formated = `${hour || '00'}:${minute || '00'}:${
        second || '00'
      }`
      const datetime_formated = `${date_formated}T${time_formated}`
      return datetime_formated
    },
  },
}
const date2BRstring = {
  methods: {
    date2BRstring(date) {
      if (!date) return null
      return `${`${date.getDate()}`.padStart(2, 0)}/${`${
        date.getMonth() + 1
      }`.padStart(2, 0)}/${`${date.getFullYear()}`.padStart(4, 0)}`
    },
  },
}
const toReal = {
  methods: {
    toReal(value) {
      return Number(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },
}
const parseDatetime = {
  methods: {
    parseDatetime(date) {
      if (!date) return null

      return `${`${date.getDate()}`.padStart(2, 0)}/${`${
        date.getMonth() + 1
      }`.padStart(2, 0)}/${`${date.getFullYear()}`.padStart(
        2,
        0
      )} ${`${date.getHours()}`.padStart(
        2,
        0
      )}:${`${date.getMinutes()}`.padStart(
        2,
        0
      )}:${`${date.getSeconds()}`.padStart(2, 0)}`
    },
  },
}

const toLocaleDateTimeString = (date) => new Date(date).toLocaleString('pt-br')

const seconds2text = (time) => {
  let hours = Math.floor(time / 3600)
  let minutes = Math.floor((time % 3600) / 60)
  let seconds = Math.floor((time % 3600) % 60)
  return hours + 'h' + minutes + 'm' + seconds + 's'
}

const dateAgendamento = {
  methods: {
    dateAgendamento(date) {
      if (!date) return null

      return `${`${date.getDate()}`.padStart(2, 0)}/${`${
        date.getMonth() + 1
      }`.padStart(2, 0)}/${`${date.getFullYear()}`.padStart(
        2,
        0
      )} ${`${date.getHours()}`.padStart(
        2,
        0
      )}:${`${date.getMinutes()}`.padStart(2, 0)}`
    },
  },
}
const iso2br = (date) => {
  if (!date) return 'Não Definida'
  // Remove Z
  let data_splited = date.split('Z')[0]
  // Split DATE and TIME
  data_splited = data_splited.split('T')
  const data = data_splited[0].split('-')
  if (data.length != 3) return 'Não Definida'
  return `${data[2]}/${data[1]}/${data[0]}`
}

const toBrazilianDateTime = (date) => {
  if (!date) return 'Não Definida'
  return new Date(date).toLocaleString('pt-br')
}
const toBrazilianDateTimeAdded = (date) => {
  if (!date) return 'Não Definida'
  const data = new Date(date)
  const add_3_hours = 1000 * 60 * 60 * 3
  data.setTime(data.getTime() + add_3_hours)
  return data.toLocaleString('pt-br')
}

export {
  parseDate,
  toReal,
  parseDatetime,
  dateAgendamento,
  BRString2datetimeIso,
  date2BRstring,
  date2br,
  iso2br,
  toBrazilianDateTime,
  toBrazilianDateTimeAdded,
  toLocaleDateTimeString,
  seconds2text,
}
