import { getDefaultState, getDefaultControlador } from "./index"

const SET_CONTROLADORES_DO_GATE = async (state, controladores) => {
  state.controladores = controladores
}

const ADD_CONTROLADOR_DO_GATE = async (state, controladores) => {
  state.controladores.push(...controladores)
}

const EDIT_CONTROLADOR_DO_GATE = async (state, controladorEditado) => {
  const controladorAntigo = state.controladores.find(c => c.public_id === controladorEditado.public_id)
  Object.assign(controladorAntigo, controladorEditado)
}

const REMOVE_CONTROLADOR_DO_GATE = async (state, controlador) => {
  const c = state.controladores.find(c => c.public_id === controlador.public_id)
  state.controladores.splice(c, 1)
}

const setGrupos = async (state, grupos) => {
  state.grupos = grupos
}

const setAllGrupos = async (state, grupos) => {
  state.todosGrupos = grupos
}

const addGrupo = async (state, grupo) => {
  state.grupos.push(grupo)
}

const setColaboradores = async (state, colaboradores) => {
  state.colaboradores = colaboradores
}

const setColaboradoresInativos = async (state, colaboradores) => {
  state.colaboradoresInativos = colaboradores
}

const setPerfis = async (state, perfis) => {
  state.perfis = perfis
}

const addPerfil = async (state, perfil) => {
  state.perfis.push(perfil)
}

const RESET_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

const UPDATE_CONTROLADOR = (state, controlador) => {
  state.controlador = Object.assign({}, state.controlador, controlador)
}

const UPDATE_ATRIBUTO_CONTROLADOR = (state, { atributo, valor }) => {
  Object.assign(state.controlador, { [atributo]: valor })
}

const RESET_CONTROLADOR = (state) => {
  state.controlador = getDefaultControlador()
}

export default {
  SET_CONTROLADORES_DO_GATE,
  ADD_CONTROLADOR_DO_GATE,
  EDIT_CONTROLADOR_DO_GATE,
  REMOVE_CONTROLADOR_DO_GATE,
  UPDATE_CONTROLADOR,
  UPDATE_ATRIBUTO_CONTROLADOR,
  RESET_CONTROLADOR,
  setGrupos,
  setAllGrupos,
  addGrupo,
  setColaboradores,
  setColaboradoresInativos,
  setPerfis,
  addPerfil,
  RESET_STATE
}
