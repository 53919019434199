import ClientesApi from '@/services/tenants/clientes'

const getClientes = async ({ commit, rootState }, empresa = rootState.auth.empresaAtual) => {
  try {
    const clientes = await ClientesApi.getClientes(empresa.public_id)
    commit('setClientes', clientes)
    return Promise.resolve(clientes)
  } catch (error) {
    return Promise.reject(error)
  }
}
const getCliente = async ({ commit, rootState }, { public_id, empresa = rootState.auth.empresaAtual }) => {
  try {
    console.log("#####")
    console.log("public_id: ", public_id)
    const clientes = await ClientesApi.getCliente(empresa.public_id, public_id)
    commit('setClientes', clientes)
    return Promise.resolve(clientes)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getClientesByParams = async ({ commit, rootState }, { params, empresa = rootState.auth.empresaAtual }) => {
  try {
    const clientes = await ClientesApi.getClientes(empresa.public_id, params)
    commit('setClientes', clientes)
    return Promise.resolve(clientes)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarCliente = async ({ commit, rootState }, novaCliente) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const clienteAdicionada = await ClientesApi.adicionarCliente(empresa, novaCliente)
    commit('setNovoCliente', clienteAdicionada)
    return Promise.resolve(clienteAdicionada)
  } catch (error) {
    return Promise.reject(error)
  }
}
/**
 * Esta função é cadastrará um cliente em uma empresa. 
 * 
 * @example 
 *   adicionarClienteEmpresa({cliente: {public_id: '...', }, empresa: {public_id: '...', }}); // {...}
 *   adicionarClienteEmpresa({cliente: {public_id: '...', }, empresa: undefined}); // {...}
 * 
 * @param   {object} cliente   Parametro obrigatório.
 * @param   {object} [empresa]    Não obrigatório, caso não exista, pegará a empresa atual.
 * @returns {object}
 */
const adicionarClienteEmpresa = async ({ rootState }, { cliente, empresa = undefined }) => {
  if (!empresa) {
    empresa = rootState.auth.empresaAtual
  }
  try {
    const clienteAdicionada = await ClientesApi.adicionarCliente(empresa, cliente)
    return Promise.resolve(clienteAdicionada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const excluirCliente = async ({ commit, rootState }, cliente) => {
  const empresaAtual = rootState.auth.empresaAtual

  try {
    await ClientesApi.excluirCliente(empresaAtual, cliente)
    commit('excluirCliente', cliente)
    return Promise.resolve(true)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarContatos = async ({ rootState }, payload) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const cliente = await ClientesApi.atualizarContatosCliente(empresaAtual, payload.cliente, payload.contatos)
    return Promise.resolve(cliente)
  } catch (error) {
    return Promise.reject(error)
  }
}



export default {
  adicionarCliente,
  adicionarClienteEmpresa,
  getClientes,
  getCliente,
  getClientesByParams,
  atualizarContatos,
  excluirCliente
}
