//firebase-messaging-sw.js
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
// import { getAnalytics } from 'firebase/analytics'
import requestPermission from './message/request_permission'

const firebaseConfig = {
  apiKey: 'AIzaSyC40BzHRDZwkrf15quK21LllMrHugDPVT4',
  authDomain: 'pushnotification-vue.firebaseapp.com',
  projectId: 'pushnotification-vue',
  storageBucket: 'pushnotification-vue.appspot.com',
  messagingSenderId: '365802389130',
  appId: '1:365802389130:web:5f4b7556739dd1655bb773',
  measurementId: 'G-Y1RT9CGZT2',
}

const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
const messaging = getMessaging(app)
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload)
  // ...
})

getToken(messaging, {
  vapidKey:
    'BErLfp6Uyv6K_JO2hwKr7xuRhUBLZEQg10OUEHqTtSCk0Kfgb7muIgalWTV2AipINaXPgOSITEQWFEwl8p8_Xdk',
})
  .then((currentToken) => {
    if (currentToken) {
      console.log(currentToken)

      localStorage.setItem('notification-token', currentToken)
      // Send the token to your server and update the UI if necessary
      // ...
    } else {
      // Show permission request UI
      console.log(
        'No registration token available. Request permission to generate one.'
      )
      requestPermission()
      // ...
    }
  })
  .catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
    // ...
  })

console.log('Delete')

export default messaging
