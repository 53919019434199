import { Sequences } from '@/utils/permissions'

export default [
  {
    path: '/gerencial',
    component: () => import('@/layouts/full-layout/Layout'),
    children: [
      {
        name: 'Colaboradores',
        path: 'colaboradores',
        props: true,
        component: () => import('@/views/usuario/Colaboradores'),
        meta: {
          title: 'Colaboradores',
          permissions: [Sequences.Colaboradores],
        },
      },
      {
        name: 'Perfil',
        path: 'perfil',
        props: true,
        component: () => import('@/views/usuario/Perfil'),
        meta: {
          title: 'Perfil',
        },
      },
      {
        name: 'Grupos',
        path: 'grupos',
        props: true,
        component: () => import('@/views/gerencial/Grupos'),
        meta: {
          title: 'Grupos',
          permissions: [Sequences.Grupos],
        },
      },
      {
        name: 'Controladores',
        path: 'controladores',
        component: () => import('@/views/gerencial/controladores/Index'),
        meta: {
          title: 'Controladores de Senha',
          permissions: [Sequences.Controladores],
        },
      },
      {
        name: 'Logging',
        path: 'logging',
        component: () => import('@/views/gerencial/logging/Logging'),
        meta: {
          title: 'Logging do Sistema',
          permissions: [],
        },
      },
    ],
  },
]
