import UsuarioApi from '@/services/usuario'

const getDefaultState = () => {
  return {
    usuarios: [],
  }
}

export const usuario = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setUsuarios(state, usuarios) {
      state.usuarios = usuarios
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  getters: {
    usuarios: state => {
      return state.usuarios
    }
  },
  actions: {
    async getUsuarios({ commit }) {
      const usuarios = await UsuarioApi.getUsuarios()
      commit('setUsuarios', usuarios)
    },
    async addUsuario({ commit }, novoUsuario) {
      const usuario = await UsuarioApi.addUsuario(novoUsuario)
      commit('setUsuarios', usuario)
      return Promise.resolve(usuario)
    },
    async editarUsuario({ dispatch }, usuario) {
      try {
        const data = await UsuarioApi.editarUsuario(usuario)
        dispatch('getUsuarios')
        return Promise.resolve(data)
      } catch(err){
        return Promise.reject(err)
      }
    }
  },
}
