import HistoricoApi from '@/services/patio/veiculo/historico'

const getHistorico = async ({ commit }, { veiculo, params }) => {
  try {
    const _historico = await HistoricoApi.getHistorico(veiculo, params)
    commit('SET_HISTORICO', _historico)
    return Promise.resolve(_historico)
  } catch (error) {
    commit('SET_HISTORICO', [])
    return Promise.reject(error)
  }
}
const getHistoricoSimple = async ({ commit }, { veiculo, params }) => {
  try {
    const _historico = await HistoricoApi.getHistoricoSimple(veiculo, params)
    commit('SET_HISTORICO_SIMPLE', _historico)
    return Promise.resolve(_historico)
  } catch (error) {
    commit('SET_HISTORICO_SIMPLE', [])
    return Promise.reject(error)
  }
}
const retrieveHistorico = async ({ commit }, { veiculo, historico }) => {
  try {
    const _historico = await HistoricoApi.retrieveHistorico(veiculo, historico)
    commit('SET_HISTORICO_ITEM', _historico)
    return Promise.resolve(_historico)
  } catch (error) {
    commit('SET_HISTORICO_ITEM', {})
    return Promise.reject(error)
  }
}

export default {
  getHistorico,
  getHistoricoSimple,
  retrieveHistorico,
}
