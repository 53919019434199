import axios from 'axios'

class FormaPagamentoAPI {

  async getFormasPagamento(gatePublicID, params) {
    try {
      const { data } = await axios.get(`api/v1/tenants/gates/${gatePublicID}/forma_pagamento/`, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getFormaPagamento(gatePublicID, formaPagamentoPublicID) {
    try {
      const { data } = await axios.get(`api/v1/tenants/gates/${gatePublicID}/forma_pagamento/${formaPagamentoPublicID}/`,)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addFormaPagamento(gatePublicID, dados) {
    try {
      const { data } = await axios.post(`api/v1/tenants/gates/${gatePublicID}/forma_pagamento/`, dados)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editFormaPagamento(gatePublicID, dados) {
    try {
      const { data } = await axios.patch(`api/v1/tenants/gates/${gatePublicID}/forma_pagamento/${dados.public_id}/`, dados)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteFormaPagamento(gatePublicID, conferenciaPublicID) {
    try {
      const { data } = await axios.delete(`api/v1/tenants/gates/${gatePublicID}/forma_pagamento/${conferenciaPublicID}/`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

}

export default new FormaPagamentoAPI()
