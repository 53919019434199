import actions from './actions'
import mutations from './mutations'

export const getDefaultState = () => {
  return {
    message: {
      message: "",
      color: "",
      show: false
    },
    dialog: {
      title: "",
      message: "",
      show: false
    }
  }
}

export const template = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  mutations,
  getDefaultState
}
