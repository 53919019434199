import contratosApi from '@/services/tenants/contratos'
import gatesApi from '@/services/tenants/gates'
import Perfil from '@/utils/perfil'

const getContratos = async (
  { commit, rootState },
  { params, empresa = rootState.auth.empresaAtual }
) => {
  try {
    const contratos = await contratosApi.getContratos(empresa.public_id, params)
    commit('setContratos', contratos)
    return Promise.resolve(contratos)
  } catch (error) {
    commit('setContratos', [])
    return Promise.reject(error)
  }
}

const getContratosPrestadores = async (
  { commit, rootState },
  empresa = rootState.auth.empresaAtual
) => {
  try {
    const contratos = await contratosApi.getContratos(empresa.public_id)
    commit('setContratos', contratos)
    return Promise.resolve(contratos)
  } catch (error) {
    commit('setContratos', [])
    return Promise.reject(error)
  }
}

const getContratosInativos = async (
  { commit, rootState },
  { params, empresa = rootState.auth.empresaAtual }
) => {
  try {
    const contratos = await contratosApi.getContratosInativos(
      empresa.public_id,
      params
    )
    commit('setContratos', contratos)
    return Promise.resolve(contratos)
  } catch (error) {
    commit('setContratos', [])
    return Promise.reject(error)
  }
}

const adicionarContrato = async ({ commit, rootState }, novoContato) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const contatoAdicionado = await contratosApi.adicionarContrato(
      empresa,
      novoContato
    )
    commit('setNovoContrato', contatoAdicionado)
    return Promise.resolve(contatoAdicionado)
  } catch (error) {
    return Promise.reject(error)
  }
}

const desativarContrato = async ({ commit, rootState }, contrato) => {
  const empresaAtual = rootState.auth.empresaAtual

  try {
    await contratosApi.desativarContrato(empresaAtual, contrato)
    commit('desativarContrato', contrato)
    return Promise.resolve(true)
  } catch (error) {
    return Promise.reject(error)
  }
}

const ativarContrato = async ({ commit, rootState }, contrato) => {
  const empresaAtual = rootState.auth.empresaAtual

  try {
    await contratosApi.ativarContrato(empresaAtual, contrato)
    commit('ativarContrato', contrato)
    return Promise.resolve(true)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarContratos = async ({ dispatch, rootState }, payload) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const contrato = await contratosApi.atualizarContrato(
      empresaAtual,
      payload.contrato,
      payload.contatos
    )
    dispatch('getContratos')
    return Promise.resolve(contrato)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarParceria = async ({ commit, rootState }, payload) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const parceria = await contratosApi.atualizarParceria(
      empresaAtual,
      payload.contrato,
      payload.parceria
    )
    const dataToCommit = { contrato: payload.contrato, parceria }
    commit('UPDATE_PARCERIA', dataToCommit)
    return Promise.resolve(parceria)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addArmazens = async (
  { commit, rootState },
  { contratoPublicID, armazens }
) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const data = await contratosApi.addArmazens(
      empresaAtual,
      contratoPublicID,
      armazens
    )
    commit('ADD_ARMAZEM', ...data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editArmazem = async (
  { commit, rootState },
  { contratoPublicID, armazem }
) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const data = await contratosApi.editArmazem(
      empresaAtual,
      contratoPublicID,
      armazem
    )
    commit('EDIT_ARMAZEM', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const deleteArmazem = async (
  { commit, rootState },
  { contratoPublicID, armazemPublicID }
) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const data = await contratosApi.deleteArmazem(
      empresaAtual,
      contratoPublicID,
      armazemPublicID
    )
    commit('DELETE_ARMAZEM', { public_id: armazemPublicID })
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getArmazens = async (
  { commit, rootState },
  { contratoPublicID, empresa = rootState.auth.empresaAtual }
) => {
  try {
    const armazens = await contratosApi.getArmazens(empresa, contratoPublicID)
    commit('SET_ARMAZENS', armazens)
    return Promise.resolve(armazens)
  } catch (error) {
    return Promise.reject(error)
  }
}

const buscarTerminaisFornecedores = async (
  { commit, rootState },
  empresa = rootState.auth.empresaAtual
) => {
  try {
    const terminais = await gatesApi.buscarTerminaisFornecedores(
      empresa.public_id
    )
    commit('SET_TERMINAIS_FORNECEDORES', terminais)
  } catch (error) {
    return Promise.reject(error)
  }
}

const buscarTerminaisFornecedor = async (
  { commit, rootState },
  empresa = rootState.auth.empresaAtual
) => {

  try {
    const terminais = await contratosApi.getContratos(
      empresa.public_id,
      { perfil: Perfil.Terminal }
    )
    commit('SET_TERMINAIS_FORNECEDORES', terminais)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getRelatorioParceiro = async (_, payload) => {
  const params = {
    data_inicio: payload.data_inicio,
    data_fim: payload.data_fim,
    servico: payload.servico ? payload.servico.split(',') : null,
    terminal: payload.terminal
      ? payload.terminal.map((t) => t.public_id)
      : null,
  }
  return await contratosApi.getRelatorioParceria(
    payload.parceiro.public_id,
    params
  )
}

export default {
  getArmazens,
  addArmazens,
  editArmazem,
  deleteArmazem,
  adicionarContrato,
  getContratos,
  getContratosPrestadores,
  getContratosInativos,
  atualizarContratos,
  desativarContrato,
  ativarContrato,
  atualizarParceria,
  buscarTerminaisFornecedores,
  buscarTerminaisFornecedor,
  getRelatorioParceiro,
}
