import GrupoApi from '@/services/gerencial/grupo'
import ColaboradorApi from '@/services/gerencial/colaborador'
import EmpresaApi from '@/services/tenants/empresa'
import PerfilApi from '@/services/gerencial/perfil'
import ControladoresApi from '@/services/gerencial/controladores'

const getControladoresDoGate = async ({ commit, rootState }, { params, gate = rootState.auth.empresaAtual }) => {
  try {
    const controladores = await ControladoresApi.getControladoresDoGate(gate, params)
    commit('SET_CONTROLADORES_DO_GATE', controladores)
    return Promise.resolve(controladores)
  } catch (error) {
    commit('SET_CONTROLADORES_DO_GATE', [])
    return Promise.reject(error)
  }
}

const addControladorDoGate = async ({ commit, rootState }, { controladores, gate = rootState.auth.empresaAtual }) => {
  try {
    const data = await ControladoresApi.addControladorDoGate(gate, controladores)
    commit('ADD_CONTROLADOR_DO_GATE', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editControladorDoGate = async ({ commit, rootState }, { controlador, gate = rootState.auth.empresaAtual }) => {
  try {
    const controladorEditado = await ControladoresApi.editControladorDoGate(gate, controlador)
    commit('EDIT_CONTROLADOR_DO_GATE', controladorEditado)
    return Promise.resolve(controladorEditado)
  } catch (error) {
    return Promise.reject(error)
  }
}

const removerControladorDoGate = async ({ commit, rootState }, { controlador, gate = rootState.auth.empresaAtual }) => {
  try {
    await ControladoresApi.removerControladorDoGate(gate, controlador)
    commit('REMOVE_CONTROLADOR_DO_GATE', controlador)
    return Promise.resolve(controlador)
  } catch (error) {
    return Promise.reject(error)
  }
}

const reiniciarControladorDoGate = async ({ commit, rootState }, { controlador, gate = rootState.auth.empresaAtual }) => {
  try {
    await ControladoresApi.reiniciarControladorDoGate(gate, controlador)
    commit('EDIT_CONTROLADOR_DO_GATE', { ...controlador, sequencia_atual: 0 })
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const getGrupos = async ({ commit, rootState }, empresa = rootState.auth.empresaAtual) => {
  try {
    const grupos = await GrupoApi.getGrupos(empresa)
    commit('setGrupos', grupos)
    return Promise.resolve(grupos)
  } catch (error) {
    commit('setGrupos', [])
    return Promise.reject(error)
  }
}

const getAllGrupos = async ({ commit }) => {
  try {
    const grupos = await GrupoApi.getAllGrupos()
    commit('setAllGrupos', grupos)
    return Promise.resolve(grupos)
  } catch (error) {
    commit('setAllGrupos', [])
    return Promise.reject(error)
  }
}

const addGrupo = async ({ commit, rootState }, novoGrupo) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const grupo = await GrupoApi.addGrupo(empresaAtual, novoGrupo)
    commit('addGrupo', grupo)
    return Promise.resolve(grupo)
  } catch (error) {
    return Promise.reject(error)
  }
}

const delGrupo = async ({ dispatch, rootState }, grupo) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const { data } = await GrupoApi.delGrupo(empresaAtual, grupo)
    await dispatch('getGrupos')
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateMenuGrupo = async (_, payload) => {
  try {
    const data = await GrupoApi.setMenu(payload.grupo, payload.menu)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateGrupo = async (_, {grupoPublicId, payload}) => {
  try {
    const data = await GrupoApi.updateGrupo(grupoPublicId, payload)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateNomeGrupo = async (_, payload) => {
  try {
    const data = await GrupoApi.setMenu(payload.grupo, payload.menu)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getColaboradores = async ({ commit, rootState }, empresa = rootState.auth.empresaAtual) => {
  try {
    const colaboradores = await ColaboradorApi.getColaboradores(empresa)
    commit('setColaboradores', colaboradores)
    return Promise.resolve(colaboradores)
  } catch (error) {
    commit('setColaboradores', [])
    return Promise.reject(error)
  }
}

const getColaboradoresInativos = async ({ commit, rootState }, empresa = rootState.auth.empresaAtual) => {
  try {
    const colaboradores = await ColaboradorApi.getColaboradoresInativos(empresa)
    commit('setColaboradoresInativos', colaboradores)
    return Promise.resolve(colaboradores)
  } catch (error) {
    commit('setColaboradoresInativos', [])
    return Promise.reject(error)
  }
}

const getColaboradorByCPF = async ({ rootState }, cpf, empresa = rootState.auth.empresaAtual) => {
  try {
    const colaborador = await ColaboradorApi.getColaboradorByCPF(cpf, empresa)
    return Promise.resolve(colaborador)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getColaboradoresDaEmpresa = async ({ commit }, empresa) => {
  try {
    const colaboradores = await ColaboradorApi.getColaboradores(empresa)
    commit('setColaboradores', colaboradores)
    return Promise.resolve(colaboradores)
  } catch (error) {
    commit('setColaboradores','setColaboradoresInativos', [])
    return Promise.reject(error)
  }
}

const addColaborador = async ({ dispatch, rootState }, novoColaborador) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const colaborador = await ColaboradorApi.addColaborador(empresaAtual, novoColaborador)
    dispatch('getColaboradores')
    return Promise.resolve(colaborador)
  } catch (error) {
    return Promise.reject(error)
  }
}

const desativarColaborador = async ({ dispatch, rootState }, colaborador) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const data = await ColaboradorApi.desativarColaborador(empresaAtual, colaborador)
    dispatch('getColaboradores')
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const ativarColaborador = async ({ dispatch, rootState }, colaborador) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const data = await ColaboradorApi.ativarColaborador(empresaAtual, colaborador)
    dispatch('getColaboradoresInativos')
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateGrupoColaborador = async ({ dispatch, rootState }, payload) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const colaborador = await ColaboradorApi.updateGrupoColaborador(empresa, payload.colaborador, payload.grupo, payload.perfis)
    dispatch('getColaboradores')
    return Promise.resolve(colaborador)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getPerfis = async ({ commit }) => {
  try {
    const perfis = await PerfilApi.getPerfis()
    commit('setPerfis', perfis)
    return Promise.resolve(perfis)
  } catch (error) {
    commit('setPerfis', [])
    return Promise.reject(error)
  }
}

const getMenusByPerfilId = async (_, {perfilId, params}) => {
  try {
    const data = await PerfilApi.getMenusByPerfilId(perfilId, params)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getMenusByEmpresa = async (_, {empresa, params}) => {
  try {
    const data = await EmpresaApi.getMenusByEmpresa(empresa, params)
    console.log(data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addPerfil = async ({ commit }, novoPerfil) => {
  try {
    const perfil = await PerfilApi.addPerfil(novoPerfil)
    commit('addPerfil', perfil)
    return Promise.resolve(perfil)
  } catch (error) {
    return Promise.reject(error)
  }
}

const delPerfil = async ({ dispatch }, perfil) => {
  try {
    const { data } = await PerfilApi.delPerfil(perfil)
    await dispatch('getPerfis')
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updatePerfil = async (_, {perfilPublicId, payload}) => {
  try {
    const data = await PerfilApi.updatePerfil(perfilPublicId, payload)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateMenuPerfil = async (_, payload) => {
  try {
    const data = await PerfilApi.setMenu(payload.perfil, payload.menu)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateNomePerfil = async (_, payload) => {
  try {
    const data = await PerfilApi.setMenu(payload.perfil, payload.menu)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateEmpresa = async (_, {empresaPublicId, payload}) => {
  try {
    const data = await EmpresaApi.updateEmpresa(empresaPublicId, payload)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateMenuEmpresa = async (_, payload) => {
  try {
    const data = await EmpresaApi.setMenu(payload.empresa, payload.menu)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateNomeEmpresa = async (_, payload) => {
  try {
    const data = await EmpresaApi.setMenu(payload.empresa, payload.menu)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getControladoresDoGate,
  addControladorDoGate,
  editControladorDoGate,
  reiniciarControladorDoGate,
  removerControladorDoGate,
  getGrupos,
  addGrupo,
  delGrupo,
  getAllGrupos,
  updateGrupo,
  updatePerfil,
  updateEmpresa,
  updateMenuGrupo,
  updateNomeGrupo,
  getColaboradores,
  getColaboradorByCPF,
  getColaboradoresDaEmpresa,
  addColaborador,
  desativarColaborador,
  ativarColaborador,
  updateGrupoColaborador,
  getColaboradoresInativos,

  addPerfil,
  updateMenuPerfil,
  updateNomePerfil,
  updateMenuEmpresa,
  updateNomeEmpresa,
  delPerfil,
  getPerfis,
  getMenusByPerfilId,
  getMenusByEmpresa
}
