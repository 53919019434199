import axios from 'axios'

class HistoricoApi {
  /**
   * Acesso a api de conjunto.
   * -> api/v1/patio/veiculos/{parent_lookup_conjunto__veiculo_id}/historico/
   */
  //
  getUrlBase = (veiculo) => {
    return `api/v1/patio/veiculos/${veiculo.id}/historico/`
  }
  getUrlDetail = (veiculo, conjunto) => {
    return `api/v1/patio/veiculos/${veiculo.id}/historico/${conjunto.public_id}/`
  }
  urls = {
    get: this.getUrlBase,
    retrieve: this.getUrlDetail,
    simple: (veiculo) => {
      return this.getUrlBase(veiculo) + 'simple/'
    },
  }
  async getHistorico(veiculo, params) {
    try {
      const url = this.urls.get(veiculo)
      const { data } = await axios.get(url, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getHistoricoSimple(veiculo, params) {
    try {
      const url = this.urls.simple(veiculo)
      const { data } = await axios.get(url, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async retrieveHistorico(veiculo, conjunto) {
    try {
      const url = this.urls.retrieve(veiculo, conjunto)
      const { data } = await axios.get(url)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new HistoricoApi()
