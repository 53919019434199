import axios from 'axios'

class RegraPrestadorServicoAPI {

  async getRegrasPrestadores(gatePublicID) {
    try {
      const { data } = await axios.get(`api/v1/tenants/gates/${gatePublicID}/regras_prestadores/`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addRegraPrestador(gatePublicID, dados) {
    try {
      const { data } = await axios.post(`api/v1/tenants/gates/${gatePublicID}/regras_prestadores/`, dados)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editRegraPrestador(gatePublicID, dados) {
    try {
      const { data } = await axios.put(`api/v1/tenants/gates/${gatePublicID}/regras_prestadores/${dados.public_id}/`, dados)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteRegraPrestador(gatePublicID, regraPublicID) {
    try {
      await axios.delete(`api/v1/tenants/gates/${gatePublicID}/regras_prestadores/${regraPublicID}/`,)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

}

export default new RegraPrestadorServicoAPI()
