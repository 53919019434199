import { Sequences } from '@/utils/permissions'

export default [
  {
    path: '/relatorios',
    component: () => import('@/layouts/full-layout/Layout'),
    children: [
      {
        name: 'CargaDescargaRelatorio',
        path: 'carga-descarga',
        component: () =>
          import('@/views/patio/relatorioCargaDescarga/RelatorioCargaDescarga'),
        meta: {
          title: 'Relatório de Carga/Descarga',
          BasicPermissions: [Sequences.CargaDescarga],
        },
      },
      {
        name: 'RetornouOrigem',
        path: 'retornou-origem',
        component: () =>
          import(
            '@/views/patio/relatorioRetornouOrigem/RelatorioRetornouOrigem'
          ),
        meta: {
          title: 'Relatório de retornou origem',
        },
      },
      {
        name: 'Cancelados',
        path: 'cancelados',
        component: () =>
          import('@/views/patio/relatorioCancelados/RelatorioCancelados'),
        meta: {
          title: 'Relatório de Cancelados',
        },
      },
      {
        name: 'CotasCliente',
        path: 'cotas-cliente',
        component: () =>
          import('@/views/patio/relatorioCotasCliente/RelatorioCotasCliente'),
        meta: {
          title: 'Relatório de Cotas Cliente',
        },
      },
      {
        name: 'RelatorioPedidos',
        path: 'relatorio-pedido',
        component: () =>
          import('@/views/patio/relatorioPedidos/RelatorioPedidos'),
        meta: {
          title: 'Relatório de Pedidos',
        },
      },
      {
        name: 'CotasRegiao',
        path: 'cotas-regiao',
        component: () =>
          import('@/views/patio/relatorioCotasRegiao/RelatorioCotasRegiao'),
        meta: {
          title: 'Relatório de Cotas Regiao',
        },
      },
      {
        name: 'CotasUnidade',
        path: 'cotas-unidade',
        component: () =>
          import('@/views/patio/relatorioCotasUnidade/RelatorioCotasUnidade'),
        meta: {
          title: 'Relatório de Cotas Unidade',
        },
      },
      {
        name: 'Pedidos',
        path: 'pedidos',
        component: () =>
          import('@/views/patio/relatorioPedido/RelatorioPedido'),
        meta: {
          title: 'Relatório de Pedidos',
        },
      },
      {
        name: 'resumoTerminal',
        path: 'resumo-terminal',
        component: () => import('@/views/patio/ResumoTerminal'),
        meta: {
          title: 'Relatório de resumo por terminal',
        },
      },
      {
        name: 'programadoAtendido',
        path: 'programado-atendido',
        component: () => import('@/views/patio/programadoAtendido'),
        meta: {
          title: 'Relatório de Programado X Atendido',
        },
      },
      {
        name: 'Analytical',
        path: 'dashboard',
        component: () => import('@/views/dashboards/Analytical'),
      },
      {
        name: 'PerformanceRelatorio',
        path: 'performance',
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
          {
            name: 'Região',
            path: 'regiao',
            component: () =>
              import(
                '@/views/relatorios/performance/regiao/PerformanceRegiao.vue'
              ),
            meta: {
              title: 'Performance da Região',
              // BasicPermissions: [],
            },
          },
          {
            name: 'Cliente',
            path: 'cliente',
            component: () =>
              import(
                '@/views/relatorios/performance/cliente/PerformanceCliente.vue'
              ),
            meta: {
              title: 'Performance do Cliente',
              // BasicPermissions: [],
            },
          },
        ],
      },
    ],
  },
]
