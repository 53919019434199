import axios from 'axios'

class SubProdutosApi {
  async getSubProduto(params) {
    const { data } = await axios.get('api/v1/tenants/subprodutos/', {
      params
    });
    return data
  }

  async addSubProduto(novoSubProduto) {
    const { data } = await axios.post('api/v1/tenants/subprodutos/', novoSubProduto);
    return data
  }

  async editSubProduto(subProduto) {
    const { id } = subProduto
    const { data } = await axios.put(`api/v1/tenants/subprodutos/${id}`, subProduto);
    return data
  }
}

export default new SubProdutosApi;