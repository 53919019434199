const getDefaultState = () => {
  return {
    tipoAgendamento: '',
    pedidoSelecionado: {},
    agendamentoSelecionado: {},
    programacaoUnidade: null,
    programacaoFornecedor: null,
    programacaoRegiao: null,
    programacaoCliente: null,
    agendamento: {
      anexos: [],
      pedidos_agendados: [],
      tipo_documento: '',

      proprietario: {},
      gate: {},
      fornecedor: {
        fornecedor_id: '',
        servicos_autorizados: [],
      },
      cliente: null,
      transportadora: null,
      tipo_frete: null,
      tipo_operacao: null,
      terminal: null,
      conjunto: {
        carreta_1: '',
        carreta_2: '',
        carreta_3: '',
        dolly: '',
      },
      convenios: undefined,
      produtos: [],
      subProdutos: [],
      embalagens: [],

      numero_documento: '',
      numero_serie: '',
      cfop: '',
      ncm: '',

      pedidos: [],
      servicos_agendados: [],
      servicos_triados: [],

      senhas: [],

      veiculoId: '',
      veiculo: {
        placa_cavalo: '',
        placa_carreta_1: '',
        placa_carreta_2: '',
        modalidade: {
          eixo: '',
          id: '',
          descricao: '',
        },
        carroceria: '',
      },
      motorista: {
        public_id: '',
        user: {
          id: '',
          cpf: '',
          name: '',
          celular: '',
          cnh_registro: '',
        },
      },
      remetente: null,
      destinatario: null,
      programacao_unidade: null,
      programacao_fornecedor: null,
      programacao_regiao: null,
      programacao_cliente: null,

      motivo_cancelamento: '',
      cancelado_em: '',
      agendado_em: '',
      triado_em: '',
      vaga: null,
      pesagens: [],
    },
    gates: [],
    fornecedores: [],
    clientes: [],
    transportadoras: [],
    terminais: [],
    tiposFrete: [],
    estacionamentos: [],
    setores: [],
    vagas: [],
  }
}

export default getDefaultState
