import { getToken, deleteToken } from 'firebase/messaging'
import requestPermission from './request_permission'
import messaging from '@/firebase'
import ColaboradorApi from '@/services/gerencial/colaborador'

const getNewToken = async (empresa) => {
  let token = ''
  deleteToken(messaging, {
    vapidKey:
      'BErLfp6Uyv6K_JO2hwKr7xuRhUBLZEQg10OUEHqTtSCk0Kfgb7muIgalWTV2AipINaXPgOSITEQWFEwl8p8_Xdk',
  }).then(() => {
    getToken(messaging, {
      vapidKey:
        'BErLfp6Uyv6K_JO2hwKr7xuRhUBLZEQg10OUEHqTtSCk0Kfgb7muIgalWTV2AipINaXPgOSITEQWFEwl8p8_Xdk',
    }).then(async (currentToken) => {
      if (currentToken) {
        token = currentToken
        console.log(currentToken)
        localStorage.setItem('notification-token', currentToken)

        await ColaboradorApi.setToken(
          empresa,
          { public_id: empresa.colaborador_id },
          token
        )
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.'
        )
        requestPermission()
        // ...
      }
    })
  })
  return token
}
export default getNewToken
