import actions from './actions'

const getDefaultState = () => {
  const user = JSON.parse(localStorage.getItem('user'))

  const data_user = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: {} }
  return {
    ...data_user,
    plantoes: [],
  }
}

const resetState = (state) => {
  Object.assign(state, getDefaultState())
}

export const auth = {
  namespaced: true,

  state: getDefaultState(),
  actions,
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      resetState(state)
    },
    refreshSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    refreshFailure(state) {
      resetState(state)
    },
    logout(state) {
      resetState(state)
    },
    perfilUsuario(state, perfil) {
      state.perfilUsuario = perfil
    },
    setEmpresaAtual(state, empresa) {
      state.empresaAtual = empresa
    },
    setPerfilAtual(state, perfil) {
      state.perfilAtual = perfil
    },
    setPlantoes(state, plantoes) {
      console.log('plantoes->', plantoes)
      state.plantoes = plantoes
      console.log(state)
    },
    RESET_STATE(state) {
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      console.log('resetState')
      resetState(state)
    },
  },
}
