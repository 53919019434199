import axios from 'axios'

class ClientesApi {
  // Clientes de uma Empresa

  async getClientes(empresaPublicID, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/clientes/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getCliente(empresaPublicID, public_id) {
    try {
      console.log("@@@@")
      console.log("public_id: ", public_id)
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/clientes/${public_id}`,
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async adicionarCliente(empresaAtual, novaCliente) {
    const id = empresaAtual.public_id
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${id}/clientes/`,
        novaCliente
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async excluirCliente(empresaAtual, cliente) {
    const empresa_id = empresaAtual.public_id
    const cliente_id = cliente.public_id
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/empresas/${empresa_id}/clientes/${cliente_id}`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarContatosCliente(empresa, cliente, contatos) {
    const empresa_id = empresa.public_id
    try {
      const {
        data,
      } = await axios.patch(
        `api/v1/tenants/empresas/${empresa_id}/clientes/${cliente}/`,
        { contatos: contatos }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async adicionarProduto(fornecedor, novoProduto) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/fornecedores/${fornecedor}/produtos/`,
        novoProduto
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async desativarProduto(fornecedor, id) {
    try {
      await axios.delete(
        `api/v1/tenants/fornecedores/${fornecedor}/produtos/${id}/`
      )
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }

  async adicionarSubProduto(novo) {
    try {
      const { data } = await axios.post('api/v1/tenants/subprodutos/', novo)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async desativarSubProduto(id) {
    try {
      await axios.delete(`api/v1/tenants/subprodutos/${id}/`)
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }

  async adicionarTerminal(fornecedor, novoTerminal) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/`,
        novoTerminal
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarTerminal(fornecedor, terminal) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/${terminal.id}/`,
        terminal
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async excluirTerminal(fornecedor, id) {
    try {
      await axios.delete(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/${id}/`
      )
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }
}

export default new ClientesApi()
