export default class Perfil {
  static Admin = 1
  static Unidade = 2
  static Fornecedor = 3
  static Cliente = 4
  static Transportadora = 5
  static Terminal = 6
  static Prestador = 7
  static Participante = 8
  static ClassificadorOrigem = 9
  static ClassificadorDestino = 10
  static ClassificadorOrigemDestino = 11
  static Parceiro = 12
}
