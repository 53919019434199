import axios from 'axios'

const urls = {
    ordemEtapa: (servico) => `api/v1/patio/servicos/${servico.id}/ordem_etapas/`,
    ordemEtapaDetail: (servico, ordemEtapa) => `api/v1/patio/servicos/${servico.id}/ordem_etapas/${ordemEtapa.public_id}/`,
}


class FinanceiroApi {

    async getOrdemEtapas(servico, params) {
        try {
            const { data } = await axios.get(urls.ordemEtapa(servico), { params })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getOrdemEtapa(servico, ordemEtapa) {
        try {
            const { data } = await axios.get(urls.ordemEtapaDetail(servico, ordemEtapa))
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async addOrdemEtapa(servico, ordemEtapa) {
        try {
            const { data } = await axios.post(urls.ordemEtapa(servico), ordemEtapa)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateOrdemEtapa(servico, ordemEtapa) {
        try {
            const { data } = await axios.patch(urls.ordemEtapaDetail(servico, ordemEtapa), ordemEtapa
            )
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async deleteOrdemEtapa(servico, ordemEtapa) {
        try {
            const { data } = await axios.delete(urls.ordemEtapaDetail(servico, ordemEtapa))
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }



}

export default new FinanceiroApi()
