import GatesApi from '@/services/tenants/gates'


const getFornecedores = async ({ commit, rootState }, empresa = rootState.auth.empresaAtual) => {
  try {
    const fornecedores = await GatesApi.getFornecedores(empresa)
    commit('setFornecedores', fornecedores)
    return Promise.resolve(fornecedores)
  } catch (error) {
    commit('setFornecedores', [])
    return Promise.reject(error)
  }
}

const getFornecedorByPublicID = async ({ rootState }, {publicID, empresa = rootState.auth.empresaAtual}) => {
  try {
    const fornecedor = await GatesApi.getFornecedorByPublicID(empresa, publicID)
    return Promise.resolve(fornecedor)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getFornecedoresByParams = async ({ rootState }, { params, empresa = rootState.auth.empresaAtual }) => {
  try {
    const fornecedores = await GatesApi.getFornecedoresByParams(empresa, params)
    return Promise.resolve(fornecedores)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarFornecedor = async ({ dispatch, rootState }, novoFornecedor) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const fornecedorAdicionado = await GatesApi.adicionarFornecedor(empresaAtual, novoFornecedor)
    // commit('setNovoFornecedor', fornecedorAdicionado)
    await dispatch('getFornecedores');
    return Promise.resolve(fornecedorAdicionado)
  } catch (error) {
    return Promise.reject(error)
  }
}

const excluirFornecedor = async ({ commit, rootState }, fornecedor) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const data = await GatesApi.excluirFornecedor(empresaAtual, fornecedor)
    commit('excluirFornecedor', fornecedor)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarContatos = async (_, payload) => {
  try {
    const fornecedor = await GatesApi.atualizarContatosFornecedor(payload.fornecedor, payload.contatos)
    return Promise.resolve(fornecedor)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarConfig = async (_, payload) => {
  try {
    const fornecedor = await GatesApi.atualizarConfigFornecedor(payload.fornecedor, payload.config)
    return Promise.resolve(fornecedor)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarProduto = async ({ dispatch }, novoProduto) => {
  const fornecedor = novoProduto.fornecedor
  delete novoProduto.fornecedor
  try {
    const produto = await GatesApi.adicionarProduto(fornecedor, novoProduto)
    dispatch('getFornecedores')
    return Promise.resolve(produto)
  } catch (error) {
    return Promise.reject(error)
  }
}

const desativarProduto = async ({ dispatch }, payload) => {
  try {
    const isDeleted = await GatesApi.desativarProduto(payload.fornecedor, payload.id)
    dispatch('getFornecedores')
    return Promise.resolve(isDeleted)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editarProduto = async (_, { fornecedorID, produtoID, payload }) => {
  try {
    const data = await GatesApi.editarProduto(fornecedorID, produtoID, payload)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarCaracteristica = async ({ commit }, { fornecedorID, produtoID, payload }) => {
  try {
    const data = await GatesApi.adicionarCaracteristica(fornecedorID, produtoID, payload)
    commit("ADD_CARACTERISTICA", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const listCaracteristicas = async ({ commit }, { fornecedorID, produtoID }) => {
  try {
    const data = await GatesApi.listCaracteristicas(fornecedorID, produtoID)
    commit("SET_CARACTERISTICAS", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editarCaracteristica = async ({ commit }, { fornecedorID, produtoID, payload }) => {
  try {
    const data = await GatesApi.editarCaracteristica(fornecedorID, produtoID, payload)
    commit("EDIT_CARACTERISTICA", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const excluirCaracteristica = async ({ commit }, { fornecedorID, produtoID, caracteristicaID }) => {
  try {
    await GatesApi.excluirCaracteristica(fornecedorID, produtoID, caracteristicaID)
    commit("EXCLUIR_CARACTERISTICA", caracteristicaID)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const undeleteCaracteristica = async ({ commit }, { fornecedorID, produtoID, caracteristicaID }) => {
  try {
    await GatesApi.undeleteCaracteristica(fornecedorID, produtoID, caracteristicaID)
    commit("UNDELETE_CARACTERISTICA", caracteristicaID)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const editarSubProduto = async (_, { subProdutoID, payload }) => {
  try {
    const data = await GatesApi.editarSubProduto(subProdutoID, payload)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const desativarSubProduto = async ({ dispatch }, id) => {
  try {
    const isDeleted = await GatesApi.desativarSubProduto(id)
    dispatch('getFornecedores')
    return Promise.resolve(isDeleted)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarSubProduto = async ({ dispatch }, novoSubProduto) => {
  try {
    const subProduto = await GatesApi.adicionarSubProduto(novoSubProduto)
    dispatch('getFornecedores')
    return Promise.resolve(subProduto)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarTerminal = async ({ dispatch }, novoTerminal) => {
  const fornecedor = novoTerminal.fornecedor
  try {
    const terminal = await GatesApi.adicionarTerminal(fornecedor, novoTerminal)
    dispatch('getFornecedores')
    return Promise.resolve(terminal)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarTerminal = async ({ dispatch }, terminal) => {
  const fornecedor = terminal.fornecedor
  try {
    const terminalAtualizado = await GatesApi.atualizarTerminal(fornecedor, terminal)
    dispatch('getFornecedores')
    return Promise.resolve(terminalAtualizado)
  } catch (error) {
    return Promise.reject(error)
  }
}

const excluirTerminal = async ({ dispatch }, payload) => {
  try {
    const isDeleted = await GatesApi.excluirTerminal(payload.fornecedor, payload.id)
    dispatch('getFornecedores')
    return Promise.resolve(isDeleted)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarEmbalagem = async ({ dispatch }, novaEmbalagem) => {
  const fornecedor = novaEmbalagem.fornecedor
  try {
    const embalagem = await GatesApi.adicionarEmbalagem(fornecedor, novaEmbalagem)
    dispatch('getFornecedores')
    return Promise.resolve(embalagem)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarEmbalagem = async ({ dispatch }, embalagem) => {
  const fornecedor = embalagem.fornecedor
  try {
    const embalagemAtualizada = await GatesApi.atualizarEmbalagem(fornecedor, embalagem)
    dispatch('getFornecedores')
    return Promise.resolve(embalagemAtualizada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const excluirEmbalagem = async ({ dispatch }, payload) => {
  try {
    const isDeleted = await GatesApi.excluirEmbalagem(payload.fornecedor, payload.id)
    dispatch('getFornecedores')
    return Promise.resolve(isDeleted)
  } catch (error) {
    return Promise.reject(error)
  }
}


// // sub produto
// async getSubProduto({ commit, rootState }) {
//     let itens = await PatioApi.getSubProduto()
//     itens = itens.filter(item => item.proprietario === rootState.auth.empresaAtual.id)
//     commit('setSubProduto', itens)
// },

// async editSubProduto({ dispatch }, novo) {
//     await PatioApi.editSubProduto(novo)
//     dispatch('getSubProduto')
// },

// // sub produto
// setSubProduto(state, subProduto) {
//     state.subProduto = subProduto
// },

// editSubProdutos(state, nova) {
//     const index = state.subProduto
//         .map(function (x) {
//             return x.id
//         })
//         .indexOf(nova.id)
//     state.subProduto.splice(index, 1, nova)
// },


// const getProdutos = async ({ commit, rootState }) => {
//     const empresaAtual = rootState.auth.empresaAtual.id
//     let clientes = await TenantsApi.getClientes(empresaAtual)
//     // produto = produto.filter(item => item.proprietario === rootState.auth.empresaAtual.id)
//     commit('setClientes', clientes)

//     // commit('setProduto', produto)
// },

export default {
  getFornecedores,
  getFornecedorByPublicID,
  getFornecedoresByParams,
  adicionarFornecedor,
  excluirFornecedor,
  adicionarProduto,
  atualizarContatos,
  atualizarConfig,
  desativarProduto,
  adicionarSubProduto,
  desativarSubProduto,
  editarProduto,
  editarSubProduto,
  adicionarTerminal,
  atualizarTerminal,
  excluirTerminal,
  adicionarEmbalagem,
  atualizarEmbalagem,
  excluirEmbalagem,
  adicionarCaracteristica,
  listCaracteristicas,
  editarCaracteristica,
  excluirCaracteristica,
  undeleteCaracteristica
}
