export const getDefaultState = () => {
  return {
    caixasPendentes: [],
    caixasEmAberto: [],
    conferencias: {
      "headers": [],
      "caixas": []
    }
  }
}

export default {
  SET_CAIXAS_PENDENTES(state, payload) {
    state.caixasPendentes = payload
  },
  SET_CAIXAS_EM_ABERTO(state, payload) {
    state.caixasEmAberto = payload
  },
  SET_CONFERENCIAS(state, payload) {
    state.conferencias = payload
  },
  REMOVE_CAIXA_PENDENTE(state, payload) {
    if (state.caixasPendentes.caixas !== undefined)
    {
      if(state.caixasPendentes.caixas.length > 0){
        const index = state.caixasPendentes.caixas.findIndex(caixa => caixa.id === payload.caixa)
        state.caixasPendentes.caixas.splice(index, 1)
      }
    }
  },
  EDIT_CONFERENCIA(state, { publicID, data }) {
    const index = state.conferencias.caixas.findIndex(conferencia => conferencia.public_id === publicID)
    state.conferencias.caixas.splice(index, 1, data)
  },
  REMOVE_CONFERENCIA(state, publicID) {
    const index = state.conferencias.caixas.findIndex(conferencia => conferencia.public_id === publicID)
    state.conferencias.caixas.splice(index, 1)
  },
  ADD_CONFERENCIA(state, payload) {
    state.conferencias.caixas.push(payload)
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}
