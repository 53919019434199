import TenantsApi from '@/services/tenants'
import EmpresaApi from '@/services/tenants/empresa'
import ClientesApi from '@/services/tenants/clientes'
import GatesApi from '@/services/tenants/gates'

const getCategorias = async ({ commit }) => {
  const categorias = await TenantsApi.getCategorias()
  commit('setCategorias', categorias)
}

const getClientes = async (
  { rootState },
  empresa = rootState.auth.empresaAtual,
  params
) => {
  try {
    const clientes = await ClientesApi.getClientes(empresa, params)
    return Promise.resolve(clientes)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getEmpresas = async ({ commit }) => {
  try {
    const empresas = await TenantsApi.getEmpresas()
    commit('setEmpresas', empresas)
    return Promise.resolve(empresas)
  } catch (err) {
    return Promise.reject(err)
  }
}

const cleanEmpresas = async ({ commit }) => {
  const empresas = []
  commit('setEmpresas', empresas)
}

const addEmpresa = async ({ commit }, newTenant) => {
  try {
    const newAddedTenant = await TenantsApi.addEmpresa(newTenant)
    commit('setNewTenant', newAddedTenant)
    return Promise.resolve(newAddedTenant)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarEmpresaCliente = async (_, payload) => {
  try {
    const empresaCliente = await TenantsApi.adicionarCliente(payload)
    return Promise.resolve(empresaCliente)
  } catch (e) {
    return Promise.reject(e)
  }
}

const deleteEmpresa = async ({ commit }, empresa) => {
  try {
    await TenantsApi.deleteEmpresa(empresa)
    commit('deleteEmpresa', empresa)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarContatosEmpresa = async (_, payload) => {
  const empresaAtualizada = await TenantsApi.atualizarContatosEmpresa(
    payload.empresa,
    payload.contatos
  )
  return empresaAtualizada
}

const atualizarPerfisEmpresa = async (_, payload) => {
  try {
    await TenantsApi.atualizarPerfisEmpresa(payload.empresa, payload.perfis)
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

const adicionarAdminEmpresa = async (_, payload) => {
  try {
    await EmpresaApi.adicionarAdminEmpresa(
      payload.user,
      payload.tenantAdminEmpresa,
      payload.empresa,
      payload.grupo,
      payload.tipo
    )
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

const atualizarPerfilRelacionamento = async (_, payload) => {
  try {
    await EmpresaApi.atualizarPerfilRelacionamento(
      payload.tipo,
      payload.relacionamento,
      payload.perfil
    )
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

const getResumoMovimentacao = async (_, payload) => {
  const params = {
    data_inicio: payload.data_inicio,
    data_fim: payload.data_fim,
    servico: payload.servico ? payload.servico.split(',') : null,
    terminal: payload.terminal
      ? payload.terminal.map((t) => t.public_id)
      : null,
  }
  return await GatesApi.getResumoMovimentacao(payload.gate, params)
}

export default {
  getCategorias,
  getEmpresas,
  cleanEmpresas,
  addEmpresa,
  adicionarEmpresaCliente,
  atualizarContatosEmpresa,
  deleteEmpresa,
  atualizarPerfisEmpresa,
  adicionarAdminEmpresa,
  atualizarPerfilRelacionamento,
  getResumoMovimentacao,
  getClientes,
}
