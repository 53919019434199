import axios from 'axios'

class FaturamentoAPI {

    async getFaturamentos(empresa, params) {
        try {
          const { data } = await axios.get(`api/v1/tenants/gates/${empresa.public_id}/faturamento/`, { params })
          return Promise.resolve(data)
        } catch (err) {
          return Promise.reject(err)
        }
      }

      async getFaturamentoByParams(params, gatePublicID) {
        try {
            const { data } = await axios.get(`api/v1/tenants/gates/${gatePublicID}/em_aberto/`, { params })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    /*async getFaturamento(empresa) {
        try {
            const { data } = axios.get(`api/v1/tenants/gates/${empresa.public_id}/faturamento/`)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }*/

    async createFaturamento(proprietario__public_id, novoFaturamento) {
        try {
            const { data } = await axios.post(`/api/v1/tenants/gates/${proprietario__public_id}/faturamento/`, novoFaturamento)
            return Promise.resolve(data)
        } catch (err) {
            return Promise.reject(err)
        }
    }

    async createItemDoFaturamento(gate__public_id, servicoFaturado) {
        try {
            const { data } = await axios.post(`/api/v1/tenants/gates/${gate__public_id}/faturamentoItem/`, servicoFaturado)
            return Promise.resolve(data)
        } catch (err) {
            return Promise.reject(err)
        }
    }

    async updateFaturamento(proprietario, public_id, faturamento) {
        try {
            const { data } = await axios.patch(`/api/v1/tenants/gates/${proprietario}/faturamento/${public_id}/`, faturamento)
            return Promise.resolve(data)
        } catch (err) {
            return Promise.reject(err)
        }
    }

    
    

    async getTotalEmAberto(params, gatePublicID) {
        try {
            const { data } = await axios.get(`api/v1/tenants/gates/${gatePublicID}/total_aberto/`, { params })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getTotalTransportadora(params, gatePublicID) {
        try {
            const { data } = await axios.get(`api/v1/tenants/gates/${gatePublicID}/total_transportadora/`, { params })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

}

export default new FaturamentoAPI()
