<template>
  <v-card class="mb-7">
    <v-toolbar v-if="heading" flat>
      <v-toolbar-title>
        {{ heading }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <slot name="right" />
    </v-toolbar>
    <v-divider></v-divider>
    <div class="pa-2">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'BaseCard',

  props: {
    heading: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),

  methods: {},
}
</script>
