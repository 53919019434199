import axios from 'axios'

class TefApi {

    async getTef(params) {
        try {
            const { data } = await axios.get('/api/v1/patio/tef/', { params })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createTef(tef) {
        try {
            const { data } = await axios.post('/api/v1/patio/tef/', tef)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateTef(tef) {
        const public_id = tef.public_id
        try {
            const { data } = await axios.patch(`/api/v1/patio/tef/${public_id}/`, tef)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async deleteTef(tef) {
        const public_id = tef.public_id
        try {
            const { data } = await axios.delete(`/api/v1/patio/tef/${public_id}/`)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getTefItemList(params) {
        try {
            const { data } = await axios.get(`/api/v1/patio/tefItem/`, { params })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createTefItem(tefItem) {
        try {
            const { data } = await axios.post(`/api/v1/patio/tefItem/`, tefItem)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

   
    async updateTefItem(tefItem) {
        const public_id = tefItem.public_id
        try {
            const { data } = await axios.patch(`/api/v1/patio/tefItem/${public_id}/`, tefItem)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async deleteTefItem(tefItem) {
        const public_id = tefItem.public_id
        try {
            const { data } = await axios.delete(`/api/v1/patio/tefItem/${public_id}/`)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

}

export default new TefApi