import ImplementoApi from '@/services/patio/veiculo/implemento'

const getImplementos = async (
  { commit, rootState },
  params,
  empresa = rootState.auth.empresaAtual
) => {
  if (params && params.proprietario) {
    empresa = params.proprietario
    delete params.proprietario
  }
  try {
    const _implementos = await ImplementoApi.getImplementos(empresa, params)
    commit('SET_IMPLEMENTOS', _implementos.results)
    commit('SET_IMPLEMENTOS_TOTAL', _implementos.count)
    return Promise.resolve(_implementos)
  } catch (error) {
    commit('SET_IMPLEMENTOS', [])
    commit('SET_IMPLEMENTOS_TOTAL', 0)
    return Promise.reject(error)
  }
}
const addImplemento = async ({ commit, rootState }, implemento) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const _implemento = await ImplementoApi.addImplemento(empresa, implemento)
    commit('ADD_IMPLEMENTO', _implemento)
    commit('SET_IMPLEMENTO', _implemento)
    return Promise.resolve(_implemento)
  } catch (error) {
    commit('SET_IMPLEMENTO', {})
    return Promise.reject(error)
  }
}
const updateDocumentosImplemento = async (
  { commit, rootState },
  implemento
) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const _implemento = await ImplementoApi.updateDocumentosImplemento(
      empresa,
      implemento
    )
    commit('EDIT_IMPLEMENTO', _implemento)
    commit('SET_IMPLEMENTO', _implemento)
    return Promise.resolve(_implemento)
  } catch (error) {
    commit('SET_IMPLEMENTO', {})
    return Promise.reject(error)
  }
}
const retrieveImplemento = async (
  { commit, rootState },
  { implemento, empresa }
) => {
  if (!empresa) {
    empresa = rootState.auth.empresaAtual
  }
  try {
    const _implemento = await ImplementoApi.retrieveImplemento(
      empresa,
      implemento
    )
    commit('SET_IMPLEMENTO', _implemento)
    return Promise.resolve(_implemento)
  } catch (error) {
    commit('SET_IMPLEMENTO', {})
    return Promise.reject(error)
  }
}
const editImplemento = async ({ commit }, implemento) => {
  try {
    const _implemento = await ImplementoApi.editImplemento(
      implemento.proprietario,
      {
        ...implemento,
        estado: implemento.estado?.id
          ? implemento.estado.id
          : implemento.estado,
        modalidade: implemento.modalidade?.id
          ? implemento.modalidade.id
          : implemento.modalidade,
      }
    )
    commit('EDIT_IMPLEMENTO', _implemento)
    commit('SET_IMPLEMENTO', _implemento)
    return Promise.resolve(_implemento)
  } catch (error) {
    commit('SET_IMPLEMENTO', {})
    return Promise.reject(error)
  }
}
const deleteImplemento = async ({ commit, rootState }, implemento) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const _implemento = await ImplementoApi.deleteImplemento(
      empresa,
      implemento
    )
    commit('DELETE_IMPLEMENTO', _implemento)
    commit('SET_IMPLEMENTO', {})
    return Promise.resolve(_implemento)
  } catch (error) {
    commit('SET_IMPLEMENTO', {})
    return Promise.reject(error)
  }
}

export default {
  getImplementos,
  addImplemento,
  retrieveImplemento,
  updateDocumentosImplemento,
  editImplemento,
  deleteImplemento,
}
