import Vue from 'vue'

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/pt_BR.json'
import UsuarioApi from '../services/usuario/index'

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

import { cnpj, cpf } from 'cpf-cnpj-validator'
import store from '../store'

const cnpjValido = (value) => {
  return cnpj.isValid(value) || 'CNPJ Inválido'
}

const cpfValido = (value) => {
  return cpf.isValid(value) || 'CPF Inválido'
}

extend('cnpjValido', cnpjValido)

extend('cpfValido', cpfValido)

extend('cpfOuCnpjValido', (value) => {
  return (
    value.length === 7 ||
    value.length === 10 ||
    value.length === 11 ||
    cpfValido(value) === true ||
    cnpjValido(value) === true ||
    'CPF ou CNPJ inválido'
  )
})

extend('diaPosteriorAoAtual', (value) => {
  return new Date(new Date(value).toDateString()) >=
    new Date(new Date().toDateString())
    ? true
    : 'A data deve ser posterior à data atual'
})

extend('cnpjNaoEmpresaAtual', (value) => {
  if (cnpj.strip(value) == store.state.auth.empresaAtual.cnpj) {
    return 'CNPJ da empresa atual'
  } else {
    return true
  }
})

extend('validateEmail', (email) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email) ? true : 'E-mail inválido'
})

extend('verificaEmailCadastrado', async (email) => {
  const response = await UsuarioApi.getUsuarioByParams({ email })

  if (!response) return true

  if (Object.keys(response).length > 0) {
    return 'E-mail já cadastro'
  }
  return true
})

extend('min8', (value) => {
  return value.length > 8 ? true : 'A senha deve ter mais que 8 caracteres'
})

extend('numValid', {
  message: (field) => `O campo ${field} deve ser um número de 0 a 100.`,
  validate: (value) => value >= 0 && value <= 100,
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
