import actions from './actions'

const getDefaultState = () => {
  return {
    remetentesDestinatarios: []
  }
}

export const remetentesDestinatarios = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  mutations: {
    setRemetentesDestinatarios(state, remetentesDestinatarios) {
      state.remetentesDestinatarios = remetentesDestinatarios
    },
    setNovoRemetenteDestinatario(state, remetenteDestinatario) {
      if (state.remetentesDestinatarios) {
        state.remetentesDestinatarios.push(remetenteDestinatario)
      } else {
        state.remetentesDestinatarios = remetenteDestinatario
      }
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
}