
import conferenciaAPI from "@/services/conferencia"


const getPendentes = async ({ commit, rootState }, { params, empresa = rootState.auth.empresaAtual }) => {
  if (!empresa.gate) {
    return Promise.reject({ response: { data: "Empresa não é gate" } })
  }
  try {
    if(params === undefined){

      const data_inicio = new Date(
        new Date(new Date().setHours(0, 0, 0, 0)).setDate(
          new Date().getDate() - new Date().getDay()
        )
      )
        .toISOString()
        .split('T')[0]

      const data_fim = new Date(
        new Date(new Date().setHours(0, 0, 0, 0)).setDate(
          new Date().getDate() 
        )
      )
        .toISOString()
        .split('T')[0]

      
      params = {
        colaborador : '',
        data_inicio : data_inicio,
        data_fim : data_fim,
      }
    }
    const data = await conferenciaAPI.getPendentes(empresa.public_id, params)
    commit("SET_CAIXAS_PENDENTES", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getEmAberto = async ({ commit, rootState }, { params, empresa = rootState.auth.empresaAtual }) => {
  if (!empresa.gate) {
    return Promise.reject({ response: { data: "Empresa não é gate" } })
  }
  try {
    const data = await conferenciaAPI.getEmAberto(empresa.public_id, params)
    commit("SET_CAIXAS_EM_ABERTO", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getConferencias = async ({ commit, rootState }, { params, empresa = rootState.auth.empresaAtual }) => {
  if (!empresa.gate) {
    return Promise.reject({ response: { data: "Empresa não é gate" } })
  }
  try {
    if(params === undefined){

      const data_inicio = new Date(
        new Date(new Date().setHours(0, 0, 0, 0)).setDate(
          new Date().getDate() - new Date().getDay()
        )
      )
        .toISOString()
        .split('T')[0]

      const data_fim = new Date(
        new Date(new Date().setHours(0, 0, 0, 0)).setDate(
          new Date().getDate() 
        )
      )
        .toISOString()
        .split('T')[0]

      
      params = {
        colaborador : '',
        data_inicio : data_inicio,
        data_fim : data_fim,
      }
    }
    
    const data = await conferenciaAPI.getConferencias(empresa.public_id, params)
    commit("SET_CONFERENCIAS", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editConferencia = async ({ commit, rootState }, { conferenciaPublicID, inputData, empresa = rootState.auth.empresaAtual }) => {
  if (!empresa.gate) {
    return Promise.reject({ response: { data: "Empresa não é gate" } })
  }
  try {
    const data = await conferenciaAPI.editConferencia(empresa.public_id, conferenciaPublicID, inputData)
    commit("EDIT_CONFERENCIA", { publicID: conferenciaPublicID, data })
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const deleteConferencia = async ({ commit, rootState }, { conferenciaPublicID, empresa = rootState.auth.empresaAtual }) => {
  if (!empresa.gate) {
    return Promise.reject({ response: { data: "Empresa não é gate" } })
  }
  try {
    const data = await conferenciaAPI.deleteConferencia(empresa.public_id, conferenciaPublicID)
    commit("REMOVE_CONFERENCIA", conferenciaPublicID)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const concluirConferencia = async ({ commit, rootState }, { conferenciaPublicID, empresa = rootState.auth.empresaAtual }) => {
  if (!empresa.gate) {
    return Promise.reject({ response: { data: "Empresa não é gate" } })
  }
  try {
    const data = await conferenciaAPI.concluirConferencia(empresa.public_id, conferenciaPublicID)
    commit("EDIT_CONFERENCIA", { publicID: conferenciaPublicID, data })
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const aprovarCaixa = async ({ commit, rootState }, { inputData, empresa = rootState.auth.empresaAtual }) => {
  if (!empresa.gate) {
    return Promise.reject({ response: { data: "Empresa não é gate" } })
  }
  try {
    const data = await conferenciaAPI.aprovarCaixa(empresa.public_id, inputData)
    commit("REMOVE_CAIXA_PENDENTE", inputData)
    commit("ADD_CONFERENCIA", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const reprovarCaixa = async ({ commit, rootState }, { inputData, empresa = rootState.auth.empresaAtual }) => {
  if (!empresa.gate) {
    return Promise.reject({ response: { data: "Empresa não é gate" } })
  }
  try {
    const data = await conferenciaAPI.reprovarCaixa(empresa.public_id, inputData)
    commit("REMOVE_CAIXA_PENDENTE", inputData)
    commit("ADD_CONFERENCIA", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getPendentes,
  getEmAberto,
  getConferencias,
  editConferencia,
  deleteConferencia,
  concluirConferencia,
  aprovarCaixa,
  reprovarCaixa,
}
