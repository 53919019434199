import axios from 'axios'

class ConferenciaAPI {

  async getPendentes(gatePublicID, params) {
    try {
      const { data } = await axios.get(`api/v1/tenants/empresas/${gatePublicID}/caixas/list_pendentes/`, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getEmAberto(gatePublicID, params) {
    try {
      const { data } = await axios.get(`api/v1/tenants/empresas/${gatePublicID}/caixas/list_em_andamento/`, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getConferencias(gatePublicID, params) {
    try {
      const { data } = await axios.get(`api/v1/tenants/gates/${gatePublicID}/conferencia/`, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editConferencia(gatePublicID, conferenciaPublicID, inputData) {
    try {
      const { data } = await axios.patch(`api/v1/tenants/gates/${gatePublicID}/conferencia/${conferenciaPublicID}/`, inputData)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteConferencia(gatePublicID, conferenciaPublicID) {
    try {
      const { data } = await axios.delete(`api/v1/tenants/gates/${gatePublicID}/conferencia/${conferenciaPublicID}/`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async concluirConferencia(gatePublicID, conferenciaPublicID) {
    try {
      const { data } = await axios.post(`api/v1/tenants/gates/${gatePublicID}/conferencia/${conferenciaPublicID}/concluir_caixa/`,)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async aprovarCaixa(gatePublicID, inputData) {
    try {
      const { data } = await axios.post(`api/v1/tenants/gates/${gatePublicID}/conferencia/aprovar_caixa/`, inputData)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async reprovarCaixa(gatePublicID, inputData) {
    try {
      const { data } = await axios.post(`api/v1/tenants/gates/${gatePublicID}/conferencia/reprovar_caixa/`, inputData)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new ConferenciaAPI()
