
import TefApi from "@/services/tef"

const getTefs = async ({ commit }, params) => {
    try {
        const tefData = await TefApi.getTef(params)
        commit('SET_TEF', tefData)
        return Promise.resolve(tefData)
    } catch (error) {
        return Promise.reject(error)
    }
}

const addTef = async ({ dispatch }, tefData) => {
    try {
        const tef = await TefApi.createTef(tefData)
        dispatch('getTefs')
        return Promise.resolve(tef)
    } catch (error) {
        return Promise.reject(error)
    }
}

const updateTef = async ({ dispatch }, tef) => {
    try {
        tef = await TefApi.updateTef(tef)
        dispatch('getTefs')
        return Promise.resolve(tef)
    } catch (error) {
        return Promise.reject(error)
    }
}


const getTefItems = async ({ commit }, params) => {
    try {
        const tefItemData = await TefApi.getTefItemList(params)
        commit('SET_TEF_ITEMS', tefItemData)
    } catch (error) {
        return Promise.reject
    }
}

const addTefItem = async ({ dispatch }, tefItem) => {
    try {
        tefItem = await TefApi.createTefItem(tefItem)
        dispatch('getTefItems')
        return Promise.resolve(tefItem)
    } catch (error) {
        return Promise.reject(error)
    }
}


const updateTefItems = async ({ dispatch }, tefItem) => {
    try {
        tefItem = await TefApi.updateTefItem(tefItem)
        dispatch('getTefItems')
        return Promise.resolve(tefItem)
    } catch (error) {
        return Promise.reject(error)
    }
}


const deleteTefItems = async ({ dispatch }, tefItem) => {
    try {
        tefItem = await TefApi.deleteTefItem(tefItem)
        dispatch('getTefItems')
        return Promise.resolve(tefItem)
    } catch (error) {
        return Promise.reject(error)
    }
}


export default {
    getTefs,
    addTef,
    updateTef,
    getTefItems,
    addTefItem,
    updateTefItems,
    deleteTefItems
}
