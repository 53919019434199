
import RegraPrestadorServicoAPI from "@/services/regraPrestadorServico"

const getRegrasPrestadores = async ({ commit, rootState }, empresa = rootState.auth.empresaAtual) => {
  try {
    const data = await RegraPrestadorServicoAPI.getRegrasPrestadores(empresa.public_id)
    commit("SET_REGRAS", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addRegraPrestador = async ({ commit, rootState }, { dados, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await RegraPrestadorServicoAPI.addRegraPrestador(empresa.public_id, dados)
    commit("ADD_REGRA", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editRegraPrestador = async ({ commit, rootState }, { dados, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await RegraPrestadorServicoAPI.editRegraPrestador(empresa.public_id, dados)
    commit("EDIT_REGRA", data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const deleteRegraPrestador = async ({ commit, rootState }, { regraPublicID, empresa = rootState.auth.empresaAtual }) => {
  try {
    await RegraPrestadorServicoAPI.deleteRegraPrestador(empresa.public_id, regraPublicID)
    commit("DELETE_REGRA", regraPublicID)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getRegrasPrestadores,
  addRegraPrestador,
  editRegraPrestador,
  deleteRegraPrestador
}
