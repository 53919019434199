export const getDefaultState = () => {
  return {
    conjuntos: [],
    conjunto: {},
  }
}

const RESET_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

const SET_CONJUNTOS = (state, payload) => {
  state.conjuntos = payload
}
const SET_CONJUNTO = (state, payload) => {
  state.conjunto = payload
}
const EDIT_CONJUNTO = (state, payload) => {
  const index = state.conjuntos.findIndex(
    (o) => o.public_id === payload.public_id
  )
  if (index != -1) {
    state.conjuntos.splice(index, 1, payload)
  }
}
const ADD_CONJUNTO = (state, payload) => {
  state.conjuntos.push(payload)
}
const DELETE_CONJUNTO = (state, payload) => {
  state.conjuntos = state.conjuntos.filter(
    (o) => o.public_id !== payload.public_id
  )
}

export default {
  RESET_STATE,
  SET_CONJUNTOS,
  SET_CONJUNTO,
  EDIT_CONJUNTO,
  ADD_CONJUNTO,
  DELETE_CONJUNTO,
}
