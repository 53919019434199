import axios from 'axios'

class GatesApi {
  // Fornecedores de um Gate

  async getByParams(params) {
    try {
      const { data } = await axios.get(`api/v1/tenants/gates/`, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getFornecedores(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/gates/${empresa.public_id}/fornecedores/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async createFornecedorPrestadorServico(
    gate__public_id,
    fornecedor__public_id,
    prestadorServico
  ) {
    try {
      const { data } = await axios.get(
        `/api/v1/tenants/gates/${gate__public_id}/fornecedores/${fornecedor__public_id}/servico-autorizado/`,
        prestadorServico
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getResumoMovimentacao(gate, params) {
      try {
        const {
          data,
        } = await axios.get(
          `api/v1/tenants/gates/${gate}/resumo_movimentacao/`,
          { params }
        )
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    }

  async getSolicitacaoEmAberto(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/gates/${empresa.public_id}/solicitacao_em_aberto/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getFornecedorByPublicID(empresa, publicID) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/gates/${empresa.public_id}/fornecedores/${publicID}/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getFornecedoresByParams(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/gates/${empresa.public_id}/fornecedores/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async adicionarFornecedor(empresaAtual, novoFornecedor) {
    const id = empresaAtual.public_id
    try {
      const { data } = await axios.post(
        `api/v1/tenants/gates/${id}/fornecedores/`,
        novoFornecedor
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editarFornecedor(empresaAtual, fornecedor) {
    const idEmpresa = empresaAtual.public_id
    const idFornecedor = fornecedor.public_id
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/gates/${idEmpresa}/fornecedores/${idFornecedor}/`,
        fornecedor
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async excluirFornecedor(empresaAtual, fornecedor) {
    const gate_id = empresaAtual.public_id
    const fornecedor_id = fornecedor.public_id
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/gates/${gate_id}/fornecedores/${fornecedor_id}`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarContatosFornecedor(fornecedor, contatos) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/fornecedores/${fornecedor}/`,
        {
          contatos: contatos,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarConfigFornecedor(fornecedor, config) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/fornecedores/${fornecedor}/`,
        {
          config,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async adicionarProduto(fornecedor, novoProduto) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/fornecedores/${fornecedor}/produtos/`,
        novoProduto
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async desativarProduto(fornecedor, id) {
    try {
      await axios.get(
        `api/v1/tenants/fornecedores/${fornecedor}/produtos/${id}/desativar/`
      )
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }

  async editarProduto(fornecedorID, produtoID, dados) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/fornecedores/${fornecedorID}/produtos/${produtoID}/`,
        dados
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async adicionarCaracteristica(fornecedorID, produtoID, dados) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/fornecedores/${fornecedorID}/produtos/${produtoID}/caracteristicas/`,
        dados
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async editarCaracteristica(fornecedorID, produtoID, dados) {
    try {
      const { data } = await axios.put(
        `api/v1/tenants/fornecedores/${fornecedorID}/produtos/${produtoID}/caracteristicas/${dados.public_id}/`,
        dados
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async excluirCaracteristica(fornecedorID, produtoID, caracteristicaID) {
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/fornecedores/${fornecedorID}/produtos/${produtoID}/caracteristicas/${caracteristicaID}/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async undeleteCaracteristica(fornecedorID, produtoID, caracteristicaID) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/fornecedores/${fornecedorID}/produtos/${produtoID}/caracteristicas/${caracteristicaID}/undelete/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async listCaracteristicas(fornecedorID, produtoID) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/fornecedores/${fornecedorID}/produtos/${produtoID}/caracteristicas/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async adicionarSubProduto(novo) {
    try {
      const { data } = await axios.post('api/v1/tenants/subprodutos/', novo)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async desativarSubProduto(id) {
    try {
      await axios.get(`api/v1/tenants/subprodutos/${id}/desativar/`)
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }

  async editarSubProduto(subProdutoID, payload) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/subprodutos/${subProdutoID}/`,
        payload
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async adicionarTerminal(fornecedor, novoTerminal) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/`,
        novoTerminal
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarTerminal(fornecedor, terminal) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/${terminal.id}/`,
        terminal
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async excluirTerminal(fornecedor, id) {
    try {
      await axios.delete(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/${id}/`
      )
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }

  async adicionarEmbalagem(fornecedor, novaEmbalagem) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/fornecedores/${fornecedor}/embalagens/`,
        novaEmbalagem
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarEmbalagem(fornecedor, embalagem) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/fornecedores/${fornecedor}/embalagens/${embalagem.id}/`,
        embalagem
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async excluirEmbalagem(fornecedor, id) {
    try {
      await axios.delete(
        `api/v1/tenants/fornecedores/${fornecedor}/embalagens/${id}/`
      )
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }

  async buscarTerminaisFornecedores(gate_id) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/gates/${gate_id}/terminais_fornecedores/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new GatesApi()
