
import classificacaoAPI from "@/services/classificacao"

const addClassificacao = async ({ rootState }, { dados, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await classificacaoAPI.addClassificacao(empresa.public_id, dados)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const listClassificacoes = async ({ rootState }, { params, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await classificacaoAPI.listClassificacoes(empresa.public_id, params)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const listRefugados = async ({ rootState }, { params, empresa = rootState.auth.empresaAtual }) => {
  try {

    const data = await classificacaoAPI.listRefugados(empresa.public_id, params)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getClassificacaoComprovante = async ({ rootState }, { classificacaoID, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await classificacaoAPI.getClassificacaoComprovante(empresa.public_id, classificacaoID)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getClassificacao = async ({ rootState }, { classificacaoID, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await classificacaoAPI.getClassificacao(empresa.public_id, classificacaoID)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editClassificacao = async ({ rootState }, { classificacaoID, dados, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await classificacaoAPI.editClassificacao(empresa.public_id, classificacaoID, dados)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const deleteClassificacao = async ({ rootState }, { classificacaoID, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await classificacaoAPI.deleteClassificacao(empresa.public_id, classificacaoID)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const aprovarClassificacao = async ({ rootState }, { classificacaoID, dados = {}, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await classificacaoAPI.aprovarClassificacao(empresa.public_id, classificacaoID, dados)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
const reprovarClassificacao = async ({ rootState }, { classificacaoID, dados, empresa = rootState.auth.empresaAtual }) => {
  try {
    const data = await classificacaoAPI.reprovarClassificacao(empresa.public_id, classificacaoID, dados)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  addClassificacao,
  listClassificacoes,
  listRefugados,
  getClassificacaoComprovante,
  getClassificacao,
  editClassificacao,
  aprovarClassificacao,
  reprovarClassificacao,
  deleteClassificacao
}
