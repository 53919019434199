import actions from './actions'

const getDefaultState = () => {
  return {
    clientes: []
  }
}

export const clientes = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  mutations: {
    setClientes(state, clientes) {
      state.clientes = clientes
    },
    setNovoCliente(state, cliente) {
      if (state.clientes) {
        state.clientes.push(cliente)
      } else {
        state.clientes = cliente
      }
    },
    excluirCliente(state, cliente) {
      state.clientes = state.clientes.filter(c => c.public_id != cliente.public_id)
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    }
  },
}