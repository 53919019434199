import actions from './actions'

const getDefaultState = () => {
  return {
    empresas: [],
    categorias: [],
  }
}

export const tenants = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  mutations: {
    setEmpresas(state, empresas) {
      state.empresas = []
      for (const empresa of empresas) {
        state.empresas.push(empresa)
      }
    },
    setNewTenant(state, newTenant) {
      state.empresas.push(newTenant)
    },
    setCategorias(state, categorias) {
      state.categorias = categorias
    },
    setNovoFornecedor(state, novoFornecedor) {
      state.clientes.push(novoFornecedor)
    },
    deleteEmpresa(state, empresa) {
      state.empresas = state.empresas.filter(tenant => tenant.public_id != empresa.public_id)
    },
    EDIT_EMPRESA(state, empresaAtualizada) {
      const empresaAntiga = state.empresas.find(e => e.public_id === empresaAtualizada.public_id)

      Object.assign(empresaAntiga, empresaAtualizada)
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    }
  },
}
