export const getDefaultState = () => {
  return {
    itemToDelete: {},
    itemToActive: {},
  }
}

export default {
  SET_ITEM_TO_DELETE(state, itemToDelete) {
    state.itemToDelete = itemToDelete
  },
  SET_ITEM_TO_ACTIVE(state, itemToActive) {
    state.itemToActive = itemToActive
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}
