export const getDefaultState = () => {
  return {
    implementos: [],
    implementos_total: 0,
    implemento: {},
  }
}

const RESET_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

const SET_IMPLEMENTOS_TOTAL = (state, payload) => {
  state.implementos_total = payload
}
const SET_IMPLEMENTOS = (state, payload) => {
  state.implementos = payload
}
const SET_IMPLEMENTO = (state, payload) => {
  state.implemento = payload
}
const EDIT_IMPLEMENTO = (state, payload) => {
  let antigo_implemento = state.implementos.find(
    (implemento) => implemento.public_id === payload.public_id
  )
  if (antigo_implemento) {
    Object.assign(antigo_implemento, payload)
  }
}
const ADD_IMPLEMENTO = (state, payload) => {
  state.implementos.push(payload)
}
const DELETE_IMPLEMENTO = (state, payload) => {
  state.implementos = state.implementos.filter(
    (o) => o.public_id !== payload.public_id
  )
}

export default {
  RESET_STATE,
  SET_IMPLEMENTOS,
  SET_IMPLEMENTO,
  SET_IMPLEMENTOS_TOTAL,
  EDIT_IMPLEMENTO,
  ADD_IMPLEMENTO,
  DELETE_IMPLEMENTO,
}
