import mutations, { getDefaultState } from './mutations'
import actions from './actions'

import { TipoMovimentacao } from '@/utils/faturamento'

const calcularPorFormaPagamento = (acc, obj, condicao) => {
  if (
    Object.prototype.hasOwnProperty.call(acc, obj.forma_pagamento.descricao)
  ) {
    acc[obj.forma_pagamento.descricao] = condicao
      ? acc[obj.forma_pagamento.descricao] - Number(obj.valor)
      : acc[obj.forma_pagamento.descricao] + Number(obj.valor)
  } else if (obj.forma_pagamento.descricao)
    acc[obj.forma_pagamento.descricao] = Number(obj.valor)
  else acc['ABERTURA'] = Number(obj.valor)
  return acc
}

export const caixa = {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
  getters: {
    temCaixaAberto: (state) => state.caixaAberto.numero !== '',
    totalCancelado: (state) =>
      state.caixaExtrato.servicos
        .filter((s) => s.cancelado)
        .reduce((acc, m) => (acc += Number(m.valor)), 0),
    totalNaoCancelado: (state) =>
      state.caixaExtrato.servicos
        .filter((s) => !s.cancelado)
        .reduce((acc, m) => (acc += Number(m.valor)), 0),
    entradas: (state) =>
      state.caixaAberto.movimentacoes.filter(
        (m) => m.tipo === TipoMovimentacao.Entrada.id
      ),
    saidas: (state) =>
      state.caixaAberto.movimentacoes.filter(
        (m) => m.tipo === TipoMovimentacao.Saida.id
      ),
    totalEntradas: (state, getters) =>
      getters.entradas
        .filter((m) => m.tipo === TipoMovimentacao.Entrada.id)
        .reduce((acc, m) => (acc += Number(m.valor)), 0),
    totalSaidas: (state, getters) =>
      getters.saidas
        .filter((m) => m.tipo === TipoMovimentacao.Saida.id)
        .reduce((acc, m) => (acc += Number(m.valor)), 0),
    movimentacoesServicosCancelados: (state) =>
      state.caixaExtrato.servicos.filter((s) => s.cancelado),
    movimentacoesServicosNaoCancelados: (state) =>
      state.caixaExtrato.servicos.filter((s) => !s.cancelado),
    movimentacoesEntradasManuais: (state) =>
      state.caixaExtrato.manuais.filter(
        (m) => m.tipo === TipoMovimentacao.Entrada.id
      ),
    movimentacoesSaidasManuais: (state) =>
      state.caixaExtrato.manuais.filter(
        (m) => m.tipo === TipoMovimentacao.Saida.id
      ),
    totalServicosPorFormaPagamento: (state) =>
      state.caixaExtrato.servicos
        .filter((s) => !s.cancelado)
        .reduce((acc, movimentacao) => {
          return calcularPorFormaPagamento(
            acc,
            movimentacao,
            movimentacao.cancelado
          )
        }, new Object()),
    totalServicosPorFormaPagamentoCancelados: (state) =>
      state.caixaExtrato.servicos
        .filter((s) => s.cancelado)
        .reduce((acc, movimentacao) => {
          return calcularPorFormaPagamento(
            acc,
            movimentacao,
            movimentacao.cancelado
          )
        }, new Object()),
    totalManuaisPorFormaPagamento: (state) =>
      state.caixaExtrato.manuais.reduce((acc, movimentacao) => {
        return calcularPorFormaPagamento(
          acc,
          movimentacao,
          movimentacao.tipo === TipoMovimentacao.Saida.id
        )
      }, new Object()),
  },
}
