import Vue from 'vue'
import getDefaultState from './state'

const RESET_STATE = (state) => {
  const initialState = getDefaultState()
  for (const f in state) {
    Vue.set(state, f, initialState[f])
  }
}

const ATUALIZA_GATES = (state, gates) => {
  state.gates = gates
}

const ATUALIZA_GATE = (state, data) => {
  if (!data.public_id) return
  const gate = state.gates.find((i) => i.public_id == data.public_id)
  if (data.descricao) {
    delete data.descricao
  }
  if (data.public_id) {
    delete data.public_id
  }
  if (gate) {
    console.warn('atualizando...', gate, data)
    Object.assign(gate, data)
  }
}

export default {
  RESET_STATE,
  ATUALIZA_GATES,
  ATUALIZA_GATE,
}
