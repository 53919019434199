import ConjuntoApi from '@/services/patio/veiculo/conjunto'

const getConjuntos = async ({ commit }, { veiculo, params }) => {
  try {
    const _conjuntos = await ConjuntoApi.getConjuntos(veiculo, params)
    commit('SET_CONJUNTOS', _conjuntos)
    return Promise.resolve(_conjuntos)
  } catch (error) {
    commit('SET_CONJUNTOS', [])
    return Promise.reject(error)
  }
}
const addConjunto = async ({ commit }, { veiculo, conjunto }) => {
  console.log(conjunto)
  try {
    const _conjunto = await ConjuntoApi.addConjunto(veiculo, {
      carreta_1: conjunto.carreta_1 ? conjunto.carreta_1.public_id : null,
      carreta_2: conjunto.carreta_2 ? conjunto.carreta_2.public_id : null,
      carreta_3: conjunto.carreta_3 ? conjunto.carreta_3.public_id : null,
      dolly: conjunto.dolly ? conjunto.dolly.public_id : null,
    })
    commit('ADD_CONJUNTO', _conjunto)
    commit('SET_CONJUNTO', _conjunto)
    return Promise.resolve(_conjunto)
  } catch (error) {
    commit('SET_CONJUNTO', {})
    return Promise.reject(error)
  }
}
const retrieveConjunto = async ({ commit }, { veiculo, conjunto }) => {
  try {
    const _conjunto = await ConjuntoApi.retrieveConjunto(veiculo, conjunto)
    commit('SET_CONJUNTO', _conjunto)
    return Promise.resolve(_conjunto)
  } catch (error) {
    commit('SET_CONJUNTO', {})
    return Promise.reject(error)
  }
}
const utilizarConjunto = async ({ commit }, { veiculo, conjunto }) => {
  try {
    const _conjunto = await ConjuntoApi.utilizarConjunto(veiculo, conjunto)
    commit('SET_CONJUNTO', _conjunto)
    return Promise.resolve(_conjunto)
  } catch (error) {
    commit('SET_CONJUNTO', {})
    return Promise.reject(error)
  }
}

export default {
  getConjuntos,
  addConjunto,
  retrieveConjunto,
  utilizarConjunto,
}
