import { Sequences } from '@/utils/permissions'

export default [
  {
    path: '/logistica',
    component: () => import('@/layouts/full-layout/Layout'),
    children: [
      {
        name: 'senha-atendimento',
        path: 'senha-atendimento',
        component: () => import('@/views/patio/SenhaAtendimento'),
        meta: {
          title: 'Senhas de Atendimento',
        },
      },
      {
        name: 'NovaTriagem',
        path: 'nova-triagem',
        props: true,
        component: () => import('@/views/patio/NovaTriagem'),
        meta: {
          title: 'Nova Triagem',
        },
      },
      {
        name: 'NovoAgendamento',
        path: 'novo-agendamento',
        props: true,
        component: () => import('@/views/patio/NovoAgendamento'),
        meta: {
          title: 'Novo Agendamento',
        },
      },
      {
        name: 'Gerenciamento de Serviços',
        path: 'gerenciamentoServicos',
        component: () => import('@/views/patio/GerenciamentoServico'),
        meta: {
          title: 'Gerenciamento de Serviços',
          BasicPermissions: [Sequences.GerenciamentoServico],
        },
      },
      {
        name: 'Classificacao',
        path: 'classificacao',
        component: () => import('@/views/patio/Classificacao'),
        meta: {
          title: 'Gerenciamento de Classificações',
          BasicPermissions: [Sequences.Classificacao],
        },
      },
      {
        name: 'Programacao Unidade',
        path: 'cotas/unidade',
        component: () => import('@/views/patio/programacao/ProgramacaoUnidade'),
        meta: {
          title: 'Programação Unidade',
          BasicPermissions: [Sequences.CotasUnidade],
        },
      },
      {
        name: 'Nova Programacao Unidade',
        path: 'cotas/v2/unidade',
        component: () =>
          import('@/views/patio/programacao/NovaProgramacaoUnidade'),
        meta: {
          title: 'Programação Unidade',
          BasicPermissions: [Sequences.CotasUnidade],
        },
      },
      {
        name: 'Programacao Fornecedor',
        path: 'cotas/fornecedor',
        component: () =>
          import('@/views/patio/programacao/ProgramacaoFornecedor'),
        meta: {
          title: 'Programação Fornecedor',
          BasicPermissions: [Sequences.CotasFornecedor],
        },
      },
      {
        name: 'Programacao Regiao',
        path: 'cotas/regiao',
        component: () => import('@/views/patio/programacao/ProgramacaoRegiao'),
        meta: {
          title: 'Programação Região',
          BasicPermissions: [Sequences.CotasRegiao],
        },
      },
      {
        name: 'Nova Programacao Região',
        path: 'cotas/v2/regiao',
        component: () =>
          import('@/views/patio/programacao/NovaProgramacaoRegiao'),
        meta: {
          title: 'Programação Região',
          BasicPermissions: [Sequences.CotasRegiao],
        },
      },
      {
        name: 'Programacao Cliente',
        path: 'cotas/cliente',
        component: () => import('@/views/patio/programacao/ProgramacaoCliente'),
        meta: {
          title: 'Programação Cliente',
          BasicPermissions: [Sequences.CotasCliente],
        },
      },
      {
        name: 'Programacao Transportadora',
        path: 'cotas/transportadora',
        component: () =>
          import('@/views/patio/programacao/ProgramacaoTransportadora'),
        meta: {
          title: 'Programação Transportadora',
          BasicPermissions: [Sequences.CotasTransportadora],
        },
      },
      {
        name: 'regioes',
        path: 'regioes',
        component: () => import('@/views/patio/Regiao'),
        meta: {
          title: 'Regiões',
          BasicPermissions: [Sequences.Regioes],
        },
      },
      {
        name: 'representantes',
        path: 'representantes',
        component: () => import('@/views/patio/Representante'),
        meta: {
          title: 'Representantes',
          BasicPermissions: [Sequences.Representantes],
        },
      },
      {
        name: 'representante-territorial',
        path: 'cotas/representante-territorial/:representantePublicID',
        props: true,
        component: () => import('@/views/patio/RepresentanteTerritorial'),
      },
      {
        name: 'Solicitação de Baixas',
        path: 'solicitacao',
        component: () => import('@/views/patio/SolicitacaoBaixas'),
        meta: {
          title: 'Solicitação de Baixas',
          BasicPermissions: [Sequences.SolicitacaoBaixa],
        },
      },
      {
        name: 'Fluxo de caminhões',
        path: 'fluxo-caminhoes',
        component: () => import('@/views/patio/fluxo/Caminhoes'),
        meta: {
          title: 'Fluxo de caminhões',
          BasicPermissions: [],
        },
      },
    ],
  },
  {
    path: '/logistica',
    component: () => import('@/layouts/blank-layout/Blanklayout'),
    children: [
      {
        name: 'Resumo',
        path: 'Resumo',
        component: () => import('@/views/patio/Resumo'),
        meta: {
          title: 'Resumos',
          refresh_token: true,
          // BasicPermissions: []
        },
      },
      {
        name: 'Supervisorio',
        path: 'supervisorio',
        component: () => import('@/views/patio/Supervirorio'),
        meta: {
          title: 'Supervisório',
          refresh_token: true,
          // BasicPermissions: []
        },
      },
    ],
  },
]
