import axios from 'axios'

class ConjuntoApi {
  /**
   * Acesso a api de conjunto.
   * -> api/v1/patio/veiculos/{parent_lookup_veiculo_id}/conjuntos/
   */
  //
  getUrlBase = (veiculo) => {
    return `api/v1/patio/veiculos/${veiculo.id}/conjuntos/`
  }
  getUrlDetail = (veiculo, conjunto) => {
    return `api/v1/patio/veiculos/${veiculo.id}/conjuntos/${conjunto.public_id}/`
  }
  urls = {
    get: this.getUrlBase,
    post: this.getUrlBase,
    retrieve: this.getUrlDetail,
    // patch: this.getUrlDetail,
    // delete: this.getUrlDetail,
    utilizar: (veiculo, public_id) => {
      return this.getUrlDetail(veiculo, public_id) + 'utilizar/'
    },
  }
  async getConjuntos(veiculo, params) {
    try {
      const url = this.urls.get(veiculo)
      const { data } = await axios.get(url, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async addConjunto(veiculo, conjunto) {
    try {
      const url = this.urls.post(veiculo)
      const { data } = await axios.post(url, conjunto)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async retrieveConjunto(veiculo, conjunto) {
    try {
      const url = this.urls.retrieve(veiculo, conjunto)
      const { data } = await axios.get(url)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async utilizarConjunto(veiculo, conjunto) {
    try {
      const url = this.urls.utilizar(veiculo, conjunto)
      console.log(url)
      const { data } = await axios.post(url)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async editConjunto(veiculo, conjunto) {
    try {
      const url = this.urls.patch(veiculo, conjunto)
      const { data } = await axios.patch(url, conjunto)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async deleteConjunto(veiculo, conjunto) {
    try {
      const url = this.urls.delete(veiculo, conjunto)
      const { data } = await axios.delete(url)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new ConjuntoApi()
