export const getDefaultState = () => {
  return {
    formasPagamento: [],
  }
}

export default {
  SET_FORMAS_PAGAMENTO(state, payload) {
    state.formasPagamento = payload
  },
  ADD_FORMA_PAGAMENTO(state, payload) {
    state.formasPagamento.push(payload)
  },
  EDIT_FORMA_PAGAMENTO(state, data) {
    const index = state.formasPagamento.findIndex(fp => fp.public_id === data.public_id)
    state.formasPagamento.splice(index, 1, data)
  },
  REMOVE_FORMA_PAGAMENTO(state, payload) {
    const index = state.formasPagamento.findIndex(fp => fp.public_id === payload.public_id)
    state.formasPagamento.splice(index, 1)
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}
