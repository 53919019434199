import Vue from 'vue'
import actions from './actions'
import mutations from './mutations'
import getDefaultState from './state'

const state = Vue.util.extend({}, getDefaultState())

export const supervisorio = {
  namespaced: true,
  state,
  actions,
  mutations,
}
