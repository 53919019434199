import actions from './actions'

import Perfil from '@/utils/perfil'

const getDefaultState = () => {
  return {
    contratos: [],
    armazens: [],
    terminaisFornecedores: [],
  }
}
export const contratos = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  getters: {
    terminais: (state) => {
      return state.contratos.filter((c) => {
        return c.perfil === Perfil.Terminal
      })
    },
    fornecedores: (state) => {
      return state.contratos.filter((c) => {
        return c.perfil === Perfil.Fornecedor
      })
    },
    prestadoresServico: (state) => {
      return state.contratos.filter((c) => {
        return c.perfil === Perfil.Prestador
      })
    },
    participantes: (state) => {
      return state.contratos.filter((c) => {
        return c.perfil === Perfil.Participante
      })
    },
    parceiros: (state) => {
      return state.contratos.filter((c) => {
        return c.perfil === Perfil.Parceiro
      })
    },
  },
  mutations: {
    RESET_ARMAZEM(state) {
      state.armazens.splice(0)
    },
    ADD_ARMAZEM(state, ...payload) {
      state.armazens.push(...payload)
    },
    DELETE_ARMAZEM(state, payload) {
      const index = state.armazens.findIndex(
        (o) => o.public_id === payload.public_id
      )
      state.armazens.splice(index, 1)
    },
    SET_ARMAZENS(state, payload) {
      state.armazens = payload
    },
    EDIT_ARMAZEM(state, payload) {
      const index = state.armazens.findIndex(
        (o) => o.public_id === payload.public_id
      )
      state.armazens.splice(index, 1, payload)
    },
    UPDATE_PARCERIA(state, payload) {
      state.contratos.forEach((contrato) => {
        if (contrato.public_id === payload.contrato.public_id) {
          contrato.parceria = payload.parceria
        }
      })
    },
    SET_TERMINAIS_FORNECEDORES(state, payload) {
      state.terminaisFornecedores = payload
    },

    setContratos(state, contratos) {
      state.contratos = contratos
    },
    setNovoContrato(state, contrato) {
      state.contratos.push(contrato)
    },
    desativarContrato(state, contrato) {
      const contratoItem = state.contratos.find(
        (c) => c.public_id === contrato.public_id
      )
      Object.assign(contratoItem, { active: false })
    },
    ativarContrato(state, contrato) {
      const contratoItem = state.contratos.find(
        (c) => c.public_id === contrato.public_id
      )
      Object.assign(contratoItem, { active: true })
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
}
