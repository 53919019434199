import axios from 'axios'

class ProdutosApi {
  async getProduto(client_id) {
    const { data } = await axios.get(`api/v1/tenants/clientes/${client_id}/produtos/`);
    return data
  }

  async addProduto(novoProduto, client_id) {
    const { data } = await axios.post(`api/v1/tenants/clientes/${client_id}/produtos/`, novoProduto);
    return data
  }

  async editProduto(produto, client_id) {
    const { id } = produto
    const { data } = await axios.put(`api/v1/tenants/clientes/${client_id}/produtos/${id}`, produto);
    return data
  }

  async 
}

export default new ProdutosApi;