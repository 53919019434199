import { collaboratorAbility, tenantAbility } from "@/plugins/casl"

const SET_COLLABORATOR_ABILITIES = "SET_COLLABORATOR_ABILITIES"
const SET_TENANT_ABILITIES = "SET_TENANT_ABILITIES"

const converter = (basicPermissions) => {
  const abilities = Object.entries(basicPermissions).map(([sequence, permissions]) => ({
    action: permissions,
    subject: String(sequence)
  }))
  return abilities
}

export const abilities = {
  namespaced: true,
  state: {
    collaboratorAbilities: [],
    tenantAbilities: [],
  },
  actions: {
    updateCollaboratorAbilities({ commit }, basicPermissions = {}) {
      const abilities = converter(basicPermissions)
      collaboratorAbility.update(abilities)
      commit(SET_COLLABORATOR_ABILITIES, abilities)
    },
    updateTenantAbilities({ commit }, basicPermissions = {}) {
      const abilities = converter(basicPermissions)
      tenantAbility.update(abilities)
      commit(SET_TENANT_ABILITIES, abilities)
    },
  },
  mutations: {
    [SET_COLLABORATOR_ABILITIES](state, abilities) {
      state.collaboratorAbilities = abilities
    },
    [SET_TENANT_ABILITIES](state, abilities) {
      state.tenantAbilities = abilities
    },
    RESET_STATE() {}
  },
}
