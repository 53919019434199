import { getDefaultState } from "./index"

const MESSAGE_UPDATE = (state, message) => {
    state.message.message = message
}

const MESSAGE_COLOR = (state, color) => {
    state.message.color = color
}

const TOGGLE_MESSAGE = (state) => {
    state.message.show = !state.message.show
}

const CLOSE_DIALOG = (state) => {
    state.dialog.show = false
}

const OPEN_DIALOG = (state) => {
    state.dialog.show = true
}

const DIALOG_UPDATE = (state, payload) => {
    state.dialog.title = payload.title
    state.dialog.message = payload.message
}

const RESET_STATE = (state) => {
    Object.assign(state, getDefaultState())
}

export default {
    MESSAGE_UPDATE,
    MESSAGE_COLOR,
    TOGGLE_MESSAGE,
    CLOSE_DIALOG,
    OPEN_DIALOG,
    DIALOG_UPDATE,
    RESET_STATE
}