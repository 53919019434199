import mutations, { getDefaultState } from './mutations'
import actions from './actions'

/*const getDefaultState = () => {
    return {
        tefs: []
    }
}*/

export const tef = {
    namespaced: true,
    state: getDefaultState(),
    mutations,
    actions
}
