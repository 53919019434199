const setMessage = ({ commit }, message) => {
    commit('MESSAGE_UPDATE', message)
    commit('TOGGLE_MESSAGE')
}

const successMessage = ({ commit }, message) => {
    commit('MESSAGE_UPDATE', message)
    commit('MESSAGE_COLOR', 'success')
    commit('TOGGLE_MESSAGE')
}

const errorMessage = ({ commit }, message) => {
    commit('MESSAGE_UPDATE', message)
    commit('MESSAGE_COLOR', 'error')
    commit('TOGGLE_MESSAGE')
}

const toggleMessage = ({ commit }) => {
    commit('TOGGLE_MESSAGE')
}

const setDialogMessage = ({ commit }, payload) => {
    commit('DIALOG_UPDATE', payload)
    commit('OPEN_DIALOG')
}

const closeDialog = ({ commit }) => {
    commit('CLOSE_DIALOG')
}

export default {
    setMessage,
    successMessage,
    errorMessage,
    toggleMessage,
    setDialogMessage,
    closeDialog
}
