import { Sequences } from '@/utils/permissions'

export default [
  {
    path: '/financeiro',
    component: () => import('@/layouts/full-layout/Layout'),
    children: [
      {
        name: 'servicos',
        path: 'servicos',
        component: () => import('@/views/financeiro/Servico'),
        meta: {
          title: 'Serviços e Tarifas',
          BasicPermissions: [Sequences.Servicos],
        },
      },
      {
        name: 'ordemEtapas',
        path: 'servicos/ordem',
        component: () => import('@/views/financeiro/servico/OrdemEtapa'),
        meta: {
          title: 'Ordem das etapas',
          BasicPermissions: [Sequences.Servicos],
        },
      },
      {
        name: 'convenios',
        path: 'convenios',
        component: () => import('@/views/financeiro/Convenio'),
        meta: {
          title: 'Convênios',
          BasicPermissions: [Sequences.Convenios],
        },
      },
      {
        name: 'caixa',
        path: 'caixa',
        component: () => import('@/views/financeiro/caixa/Caixa'),
        meta: {
          title: 'Relatório de caixa',
          BasicPermissions: [Sequences.Caixa],
        },
      },
      {
        name: 'conferencia',
        path: 'conferencia',
        component: () => import('@/views/financeiro/conferencia/Conferencia'),
        meta: {
          title: 'Conferência de caixa',
          BasicPermissions: [Sequences.Conferencia],
        },
      },
      {
        name: 'formaPagamento',
        path: 'forma-pagamento',
        component: () =>
          import('@/views/financeiro/forma-pagamento/FormaPagamento'),
        meta: {
          title: 'Formas de Pagamento',
          BasicPermissions: [Sequences.FormaPagamento],
        },
      },
      {
        name: 'faturamento',
        path: 'faturamento',
        component: () => import('@/views/financeiro/faturamento/Relatorio'),
        meta: {
          title: 'Relatório de caixa',
          BasicPermissions: [Sequences.Faturamento],
        },
      },
      {
        name: 'relatorioServico',
        path: 'relatorioServico',
        component: () => import('@/views/financeiro/servico/Relatorio'),
        meta: {
          title: 'Relatório de servico',
        },
      },
      {
        name: 'relatorioParceiros',
        path: 'relatorioParceiros',
        component: () => import('@/views/financeiro/parceiros/Relatorio'),
        meta: {
          title: 'Relatório de parceiros',
        },
      },
      {
        name: 'relatorioResumoMovimentacao',
        path: 'relatorioResumoMovimentacao',
        component: () => import('@/views/financeiro/resumo-movimentacao/Relatorio'),
        meta: {
          title: 'Relatório de Movimentacao',
        },
      },
      {
        name: 'relatorioServicoEixo',
        path: 'relatorioServicoEixo',
        component: () => import('@/views/financeiro/servicoEixo/Relatorio'),
        meta: {
          title: 'Relatório de servico por eixo',
        },
      },
    ],
  },
  {
    path: '/financeiro/caixa/relatorio',
    component: () => import('@/layouts/blank-layout/Blanklayout'),
    children: [
      {
        name: 'extrato',
        path: 'extrato',
        props: true,
        component: () => import('@/views/financeiro/caixa/Extrato'),
        meta: {
          title: 'Extrato de caixa',
        },
      },
      // {
      //   name: 'resumo',
      //   path: 'resumo',
      //   props: true,
      //   component: () => import('@/views/financeiro/caixa/Resumo'),
      //   meta: {
      //     title: "Resumo de caixa"
      //   }
      // },
    ],
  },
]
