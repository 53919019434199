import axios from 'axios'

class ClassificacaoAPI {

  async addClassificacao(empresaID, payload) {
    try {
      const { data } = await axios.post(`api/v1/tenants/empresas/${empresaID}/classificacao/`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async listClassificacoes(empresaID, params) {
    try {
      const { data } = await axios.get(`api/v1/tenants/empresas/${empresaID}/classificacao/`, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getClassificacaoComprovante(empresaID, clasificacaoID) {
    try {
      const { data } = await axios.get(`api/v1/tenants/empresas/${empresaID}/classificacao/${clasificacaoID}/comprovante/`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getClassificacao(empresaID, clasificacaoID) {
    try {
      const { data } = await axios.get(`api/v1/tenants/empresas/${empresaID}/classificacao/${clasificacaoID}/`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editClassificacao(empresaID, clasificacaoID, dados) {
    try {
      const { data } = await axios.patch(`api/v1/tenants/empresas/${empresaID}/classificacao/${clasificacaoID}/`, dados)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteClassificacao(empresaID, classificacaoID) {
    try {
      const { data } = await axios.delete(`api/v1/tenants/empresas/${empresaID}/classificacao/${classificacaoID}/`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async listRefugados(empresaID, params) {
    try {
      const { data } = await axios.get(`api/v1/tenants/empresas/${empresaID}/classificacao/refugados/`, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async aprovarClassificacao(empresaID, clasificacaoID, dados) {
    try {
      const { data } = await axios.patch(`api/v1/tenants/empresas/${empresaID}/classificacao/${clasificacaoID}/aprovar/`, dados)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async reprovarClassificacao(empresaID, clasificacaoID, dados) {
    try {
      const { data } = await axios.patch(`api/v1/tenants/empresas/${empresaID}/classificacao/${clasificacaoID}/reprovar/`, dados)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

}

export default new ClassificacaoAPI()
