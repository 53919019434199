export class Sequences {
  static Colaboradores = 1010
  static Cancelados = 10090
  static Grupos = 1020
  static Controladores = 1030
  static Veiculos = 2010
  static Motoristas = 2020
  static motivoCancelamento = 2091
  static itemLaudoCheckList = 2100
  static Transportadoras = 2030
  static Clientes = 2040
  static Terminais = 2050
  static Parceiros = 2055
  static Prestadores = 2060
  static RegrasPrestadores = 2070
  static Participantes = 2080
  static Regioes = 3010
  static Representantes = 3020
  static GerenciamentoServico = 3030
  static SolicitacaoBaixa = 3040
  static CotasUnidade = 3051
  static CotasFornecedor = 3052
  static CotasRegiao = 3053
  static CotasCliente = 3054
  static CotasTransportadora = 3055
  static Classificacao = 3060
  static FluxoCaminhoes = 3200
  static CargaDescarga = 10010
  static ExtracaoCargaDescarga = 7010
  static RetornouOrigem = 10020
  static Servicos = 4010
  static Convenios = 4020
  static Caixa = 4030
  static Faturamento = 4040
  static Conferencia = 4050
  static FormaPagamento = 4060
  static Fornecedores = 5010
  static Navios = 5020
  static Pedidos = 5030
  static Estacionamento = 6010
  static SistemaVeiculoCores = 9011
  static SistemaVeiculoMarcas = 9012
  static SistemaPerfis = 9030
  static SistemaEmpresas = 9040
  static SistemaCarroceria = 9050
  static SistemaModalidades = 9060
  static RelatorioPedidos = 10100
  static Implementos = 9999999
}

export class BasicPermissions {
  static VISUALIZAR = 'list'
  static CADASTRAR = 'create'
  static EDITAR = 'update'
  static EXCLUIR = 'delete'
}

export class CustomPermissions {
  static CANCELAR = 'cancel'
  static PAUSAR = 'pause'
  static PESAR = 'weigh'
  static AVANCAR_ETAPAS = 'advance_steps'
  static RECUAR_ETAPAS = 'backoff_steps'
  static VISUALIZAR_COMPROVANTE_AGENDAMENTO = 'view_schedule_receipt'
  static VISUALIZAR_COMPROVANTE_TRIAGEM = 'view_sorting_receipt'
  static VISUALIZAR_COMPROVANTE_LIMPEZA = 'view_cleaning_receipt'
  static ENVIAR_CLASSIFICACAO = 'send_classification'
  static ENVIAR_CADASTRO_MOTORISTA = 'enviar_cadastro_motorista'
  static CANCELAR_SERVICO = 'cancel_service'
  static SERVICO_ADICIONAL = 'additional_service'
  static APROVAR_SOLICITADOS = 'toapprove_solicitados'
  static APROVAR_LIBERADOS = 'toapprove_liberados'
  static APROVAR_EMTRANSITO = 'toapprove_emtransito'
  static RECUSAR_SOLICITADOS = 'refuse_solicitados'
  static RECUSAR_LIBERADOS = 'refuse_liberados'
  static RECUSAR_EMTRANSITO = 'refuse_emtransito'
  static ABA_PENDENTES = 'aba_pendentes'
  static ABA_APROVADOS = 'aba_aprovados'
  static ABA_REPROVADOS = 'aba_reprovados'
  static ABA_CONCLUIDOS = 'aba_concluidos'
  static GENERATE_CLASSIFICACAO_DESTINO = 'generate_classificacao_destino'
  static MANAGE_CLASSIFICACAO_ORIGEM = 'manage_classificacao_origem'
  static APROVAR_CLASSIFICACAO_ORIGEM = 'toapprove_classificacao_origem'
  static VISUALIZAR_COMPROVANTE_CLASSIFICACAO_ORIGEM =
    'visualizar_comprovante_classificacao_origem'
  static RETORNAR_A_ORIGEM = 'retornar_a_origem'
  static CHANGE_QUOTA = 'change_quota'
  static IGNORAR_DATA_TRIAGEM = 'bypass_date'
  static ENTRADA_BUFFER = 'entrada_buffer'
  static SAIDA_BUFFER = 'saida_buffer'
  static MAGNIFYING_GLASS = 'magnifying_glass'
  static APROVAR_CONFERENCIA = 'aprovar_conferencia'
  static REPROVAR_CONFERENCIA = 'reprovar_conferencia'
  static AGENDAR = "agendar"
  static EXPORTAR_CSV = "exportar_csv"
  static DISTRIBUIR_TRANSPORTADORA = "distribuir_transportadora"
  static SUSPENDER = "suspender"
}
