import mutations, { getDefaultState } from './mutations'
import actions from './actions'
import getters from './getters'

export const formaPagamento = {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
  getters
}
