import TransportadorasApi from '@/services/tenants/transportadoras'

const getTransportadoras = async (
  { commit, rootState },
  empresa = rootState.auth.empresaAtual
) => {
  try {
    const transportadoras = await TransportadorasApi.getTransportadoras(empresa)
    commit('setTransportadoras', transportadoras)
    return Promise.resolve(transportadoras)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getTransportadorasSimple = async (
  { rootState },
  { empresa = rootState.auth.empresaAtual, params }
) => {
  try {
    const transportadora = await TransportadorasApi.getTransportadorasSimple(
      empresa,
      params
    )
    return Promise.resolve(transportadora)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getTransportadoraEspecifica = async (
  { rootState },
  empresa = rootState.auth.empresaAtual,
  params
) => {
  try {
    const transportadora = await TransportadorasApi.getTransportadoraEspecifica(
      empresa,
      params
    )
    return Promise.resolve(transportadora)
  } catch (error) {
    return Promise.reject(error)
  }
}

const adicionarTransportadora = async (
  { commit, rootState },
  novaTransportadora
) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const transportadoraAdicionada = await TransportadorasApi.adicionarTransportadora(
      empresa,
      novaTransportadora
    )
    commit('setNovaTransportadora', transportadoraAdicionada)
    return Promise.resolve(transportadoraAdicionada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarTransportadora = async (
  { commit, rootState },
  transportadora
) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const transportadoraAtualizada = await TransportadorasApi.atualizarTransportadora(
      empresa,
      transportadora
    )
    commit('atualizarTransportadora', transportadoraAtualizada)
    return Promise.resolve(transportadoraAtualizada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const excluirTransportadora = async ({ commit, rootState }, transportadora) => {
  const empresaAtual = rootState.auth.empresaAtual

  try {
    await TransportadorasApi.excluirTransportadora(empresaAtual, transportadora)
    commit('excluirTransportadora', transportadora)
    return Promise.resolve(true)
  } catch (error) {
    return Promise.reject(error)
  }
}

const atualizarContatos = async ({ rootState }, payload) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const transportadora = await TransportadorasApi.atualizarContatosTransportadora(
      empresaAtual,
      payload.transportadora,
      payload.contatos
    )
    return Promise.resolve(transportadora)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  adicionarTransportadora,
  atualizarTransportadora,
  getTransportadoras,
  atualizarContatos,
  excluirTransportadora,
  getTransportadoraEspecifica,
  getTransportadorasSimple,
}
