import ProdutosApi from '@/services/produtos'

const getDefaultState = () => {
  return {
    produtos: [],
  }
}

export const produtos = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setProduto(state, produtos) {
      state.produtos = produtos
    },

    addProduto(state, nova) {
      state.produtos.push(nova)
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getProduto({ commit }, client_id) {
      const itens = await ProdutosApi.getProduto(client_id)
      commit('setProduto', itens)
    },
    async addProduto({ dispatch }, novo, client_id) {
      await ProdutosApi.addProduto(novo, client_id)
      dispatch('getProduto')
    },
    async editProduto({ dispatch }, novo, client_id) {
      await ProdutosApi.editProduto(novo, client_id)
      dispatch('getProduto')
    }
  },
}