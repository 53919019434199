import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
// import localForage from 'localforage'

import { agendamentos } from './agendamentos'
import { auth } from './auth'
import { gerencial } from './gerencial'
import { tenants } from './tenants'
import { fornecedores } from './fornecedores'
import { transportadoras } from './transportadoras'
import { motoristas } from './motoristas'
import { caixa } from './caixa'
import { clientes } from './clientes'
import { contratos } from './contratos'
import { patio } from './patio'
import { distribuicao } from './distribuicao'
import { subProdutos } from './subprodutos'
import { produtos } from './produtos'
import { usuario } from './usuario'
import { menu } from './menu'
import { template } from './template'
import { remetentesDestinatarios } from './remetentesDestinatarios'
import { faturamento } from './faturamento'
import { solicitacaoBaixa } from './solicitacaoBaixa'
import { regraPrestadorServico } from './regraPrestadorServico'
import { programacao } from './programacao'
import { conferencia } from './conferencia'
import { formaPagamento } from './formaPagamento'
import { dashboard } from './dashboard'
import { relatorioCargaDescarga } from './relatorioCargaDescarga'
import { relatorioRetornouOrigem } from './relatorioRetornouOrigem'
import { gerenciamentoServico } from './gerenciamentoServico'
import { relatorioFaturamento } from './relatorioFaturamento'
import { relatorioCotasCliente } from './relatorioCotasCliente'
import { relatorioServico } from './relatorioServico'
import { relatorioParceiro } from './relatorioParceiro'
import { resumoMovimentacao } from './resumoMovimentacao'
import { resumoTerminal } from './resumoTerminal'
import { relatorioServicoEixo } from './relatorioServicoEixo'
import { relatorioCotasUnidade } from './relatorioCotasUnidade'
import { relatorioPedidos } from './relatorioPedidos'
import { relatorioCotasRegiao } from './relatorioCotasRegiao'
import { relatorioCancelados } from './relatorioCancelados'
import { classificacao } from './classificacao'
import { abilities } from './abilities'
import { financeiro } from './financeiro'
import { programacaoAtendidos } from './programacaoAtendidos'
import { tef } from './tef'
import { extracaoCargaDescarga } from './extracaoCargaDescarga'
import { implemento } from './patio/veiculo/implemento'
import { conjunto } from './patio/veiculo/conjunto'
import { conjunto_historico } from './patio/veiculo/historico'
import { params } from './params'
import { logistica } from './logistica'
import { supervisorio } from './supervisorio'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'rcsist',
  storage: window.localStorage,
  modules: ['auth', 'agendamentos', 'menu', 'abilities'],
})

const modules = {
  auth,
  agendamentos,
  gerencial,
  tenants,
  caixa,
  clientes,
  contratos,
  fornecedores,
  transportadoras,
  patio,
  distribuicao,
  subProdutos,
  dashboard,
  produtos,
  usuario,
  menu,
  motoristas,
  template,
  remetentesDestinatarios,
  faturamento,
  solicitacaoBaixa,
  regraPrestadorServico,
  programacao,
  conferencia,
  formaPagamento,
  relatorioCargaDescarga,
  relatorioRetornouOrigem,
  gerenciamentoServico,
  relatorioFaturamento,
  classificacao,
  relatorioCotasUnidade,
  relatorioServico,
  relatorioParceiro,
  relatorioServicoEixo,
  resumoMovimentacao,
  relatorioCotasRegiao,
  relatorioCotasCliente,
  relatorioPedidos,
  resumoTerminal,
  abilities,
  financeiro,
  relatorioCancelados,
  programacaoAtendidos,
  tef,
  extracaoCargaDescarga,
  implemento,
  conjunto,
  conjunto_historico,
  params,
  logistica,
  supervisorio,
}

export default new Vuex.Store({
  modules,
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: 'white', //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: '',
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload
    },
  },
  actions: {
    resetAllStates: ({ commit }) => {
      for (let module of Object.keys(modules)) {
        commit(`${module}/RESET_STATE`, { root: true })
      }
    },
  },
  getters: {},
  plugins: [vuexLocalStorage.plugin],
})
