import axios from 'axios'

class GrupoApi {
  async getGrupos(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/grupos/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getAllGrupos() {
    try {
      const { data } = await axios.get('api/v1/tenants/grupos/')
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addGrupo(empresa, novoGrupo) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${empresa.public_id}/grupos/`,
        novoGrupo
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async delGrupo(empresa, grupo) {
    const id = empresa.public_id
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/empresas/${id}/grupos/${grupo.public_id}/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateGrupo(grupoPublicId, payload) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/grupos/${grupoPublicId}/`,
        payload
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async setMenu(grupo, menu) {
    try {
      const { data } = await axios.patch(`api/v1/tenants/grupos/${grupo}/`, {
        menu,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async setSubProduto(grupo, sub_produtos, fornecedor, terminal) {
    try {
      const { data } = await axios.patch(`api/v1/tenants/grupos/${grupo}/`, {
        sub_produtos,
        fornecedor,
        terminal,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async setNome(grupo, nome) {
    try {
      const { data } = await axios.patch(`api/v1/tenants/grupos/${grupo}/`, {
        nome,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async setPermissoes(grupo, permissoes) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/grupos/${grupo}/permissoes/`,
        {
          permissoes,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new GrupoApi()
