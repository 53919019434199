import solicitacaoBaixaAPI from '@/services/solicitacaoBaixa'

const getDefaultState = () => {
  return {
    terminais: [],
    emPatio: [],
    solicitacoes: [],
    liberados: [],
    emTransito: [],
    aprovados: [],
  }
}

export const solicitacaoBaixa = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_TERMINAIS(state, terminais) {
      state.terminais = terminais
    },
    SET_EM_PATIO(state, triagens) {
      state.emPatio = triagens
    },
    SET_SOLICITACOES(state, solicitacoes) {
      state.solicitacoes = solicitacoes
    },
    SET_LIBERADOS(state, liberados) {
      state.liberados = liberados
    },
    SET_EM_TRANSITO(state, emTransito) {
      state.emTransito = emTransito
    },
    SET_APROVADOS(state, aprovados) {
      state.aprovados = aprovados
    },
    ADD_SOLICITACOES(state, solicitacoes) {
      state.solicitacoes.push(...solicitacoes)
    },
    ADD_LIBERADOS(state, liberados) {
      state.liberados.push(...liberados)
    },
    ADD_EM_TRANSITO(state, emTransito) {
      state.emTransito.push(...emTransito)
    },
    REMOVE_EM_PATIO(state, solicitacoes) {
      state.emPatio = state.emPatio.filter(
        (o) => !solicitacoes.map((s) => s.triagem).includes(o.public_id)
      )
    },
    REMOVE_SOLICITACOES(state, solicitacoes) {
      state.solicitacoes = state.solicitacoes.filter(
        (o) => !solicitacoes.includes(o.public_id)
      )
    },
    REMOVE_LIBERADOS(state, liberados) {
      state.liberados = state.liberados.filter(
        (o) => !liberados.includes(o.public_id)
      )
    },
    REMOVE_EM_TRANSITO(state, emTransito) {
      state.emTransito = state.emTransito.filter(
        (o) => !emTransito.includes(o.public_id)
      )
    },
  },
  actions: {
    async getAgendamentosEmPatio({ commit }, params) {
      try {
        const data = await solicitacaoBaixaAPI.getAgendamentosEmPatio(params)
        commit('SET_EM_PATIO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async addSolicitacaoBaixa({ commit }, dados) {
      try {
        const data = await solicitacaoBaixaAPI.addSolicitacaoBaixa(dados)
        commit('REMOVE_EM_PATIO', dados)
        commit('ADD_SOLICITACOES', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async finalizarSolicitacaoBaixa({ commit }, dados) {
      try {
        const data = await solicitacaoBaixaAPI.finalizarSolicitacaoBaixa(dados)
        commit('REMOVE_SOLICITACOES', dados.solicitacoes)
        if (dados.aprovar) {
          commit('ADD_LIBERADOS', data)
        }
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getSolicitacoesBaixa({ commit }, params) {
      try {
        const data = await solicitacaoBaixaAPI.getSolicitacoesBaixa(params)
        commit('SET_SOLICITACOES', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getLiberados({ commit }, params) {
      try {
        const data = await solicitacaoBaixaAPI.getLiberados(params)
        commit('SET_LIBERADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getEmTransito({ commit }, params) {
      try {
        const data = await solicitacaoBaixaAPI.getEmTransito(params)
        commit('SET_EM_TRANSITO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAprovados({ commit }, params) {
      try {
        const data = await solicitacaoBaixaAPI.getAprovados(params)
        commit('SET_APROVADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async liberarCaminhoes({ commit, rootState }, dados) {
      try {
        const params = { proprietario: rootState.auth.empresaAtual.public_id }
        const data = await solicitacaoBaixaAPI.liberarCaminhoes(dados, params)
        commit('REMOVE_LIBERADOS', dados.liberados)
        if (dados.aprovar) {
          commit('ADD_EM_TRANSITO', data)
        } else {
          const data = await solicitacaoBaixaAPI.getSolicitacoesBaixa(params)
          commit('SET_SOLICITACOES', data)
        }
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async confirmarTransito({ commit, rootState }, dados) {
      try {
        const params = {
          parent_lookup_proprietario__public_id:
            rootState.auth.empresaAtual.public_id,
        }
        const data = await solicitacaoBaixaAPI.confirmarTransito(dados, params)
        commit('REMOVE_EM_TRANSITO', dados.emTransito)
        if (dados.aprovar == false) {
          const data = await solicitacaoBaixaAPI.getLiberados(params)
          commit('SET_LIBERADOS', data)
        }
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getResumoSolicitacaoBaixa(_, params) {
      try {
        const data = await solicitacaoBaixaAPI.getResumoSolicitacaoBaixa(params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getResumoExtraFields(_, params) {
      try {
        const data = await solicitacaoBaixaAPI.getResumoExtraFields(params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
