import axios from 'axios'

class ColaboradorApi {
  async addColaborador(empresa, colaborador) {
    const id = empresa.public_id
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${id}/colaboradores/`,
        colaborador
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async desativarColaborador(empresa, colaborador) {
    const id = empresa.public_id
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${id}/colaboradores/${colaborador.public_id}/desativar/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getColaboradores(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/colaboradores/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getColaboradoresInativos(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/colaboradores/listagem_inativos/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async ativarColaborador(empresa, colaborador) {
    const id = empresa.public_id
    try {
      console.log(colaborador)
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${id}/colaboradores/${colaborador.public_id}/ativar/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getColaboradorByCPF(cpf, empresa) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/colaboradores/`,
        { params: { cpf } }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateGrupoColaborador(empresa, colaborador, grupoPublicId, perfis) {
    const id = empresa.public_id
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/empresas/${id}/colaboradores/${colaborador.public_id}/`,
        {
          perfis,
          grupo: grupoPublicId,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async setToken(empresa, colaborador, token) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/empresas/${empresa.public_id}/colaboradores/${colaborador.public_id}/set-token/`,
        {
          token,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new ColaboradorApi()
