import FaturamentoAPI from '@/services/faturamento'

const getFaturamento = async (
  { commit, rootState },
  empresa = rootState.auth.empresaAtual
) => {
  try {
    const faturamento = await FaturamentoAPI.getFaturamentos(empresa)
    commit('SET_FATURAMENTO', faturamento)
    return Promise.resolve(faturamento)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getFaturamentoByParams = async (
  { commit, rootState },
  params,
  empresa = rootState.auth.empresaAtual
) => {
  if (params.empresa) {
    empresa = params.empresa
    delete params.empresa
  }
  try {
    const faturamento = await FaturamentoAPI.getFaturamentoByParams(
      params,
      empresa.public_id
    )
    commit('SET_FATURAMENTO', faturamento)
    return Promise.resolve(faturamento)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addNovoFaturamento = async ({ rootState }, novoFaturamento) => {
  const empresa = rootState.auth.empresaAtual.public_id
  try {
    const data = await FaturamentoAPI.createFaturamento(
      empresa,
      novoFaturamento
    )
    //commit('ADD_FATURAMENTO', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editFaturamentoStatusPago = async (
  { dispatch, rootState },
  faturamento
) => {
  const empresa = rootState.auth.empresaAtual.public_id
  const public_id = faturamento.public_id
  try {
    const data = await FaturamentoAPI.updateFaturamento(empresa, public_id, {
      status: 3,
    })
    dispatch('getFaturamento')
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}

const editFaturamentoStatusFaturado = async (
  { dispatch, rootState },
  faturamento
) => {
  const empresa = rootState.auth.empresaAtual.public_id
  const public_id = faturamento.public_id
  try {
    const data = await FaturamentoAPI.updateFaturamento(empresa, public_id, {
      status: 2,
    })
    dispatch('getFaturamento')
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}

const editFaturamentoStatusCancelado = async (
  { dispatch, rootState },
  faturamento
) => {
  const empresa = rootState.auth.empresaAtual.public_id
  const public_id = faturamento.public_id
  try {
    const data = await FaturamentoAPI.updateFaturamento(empresa, public_id, {
      status: 1,
      observacao: faturamento.observacao,
    })
    dispatch('getFaturamento')
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}

const getTotalEmAberto = async (
  { rootState },
  params,
  empresa = rootState.auth.empresaAtual
) => {
  try {
    if (params.empresa) {
      empresa = params.empresa
      delete params.empresa
    }
    const data = await FaturamentoAPI.getTotalEmAberto(
      params,
      empresa.public_id
    )
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getTotalTransportadora = async (
  { rootState },
  params,
  empresa = rootState.auth.empresaAtual
) => {
  try {
    if (params.empresa) {
      empresa = params.empresa
      delete params.empresa
    }
    const data = await FaturamentoAPI.getTotalTransportadora(
      params,
      empresa.public_id
    )
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getFaturamento,
  getFaturamentoByParams,
  getTotalEmAberto,
  getTotalTransportadora,
  editFaturamentoStatusPago,
  editFaturamentoStatusFaturado,
  editFaturamentoStatusCancelado,
  addNovoFaturamento,
}
