const getDefaultState = () => {
  return {
    params: {},
  }
}

export const relatorioServicoEixo = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    UPDATE_PARAMS(state, params) {
      state.params = params
    }
  },
}
