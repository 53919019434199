import axios from 'axios'

class PerfilApi {
    async getPerfis() {
        try {
            const { data } = await axios.get(`api/v1/tenants/perfis/`)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getAllGrupos() {
        try {
            const { data } = await axios.get('api/v1/tenants/grupos/')
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async addPerfil(novoPerfil) {
        try {
            const { data } = await axios.post(`api/v1/tenants/perfis/`, novoPerfil)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async delPerfil(perfil) {
        try {
            const { data } = await axios.delete(`api/v1/tenants/perfis/${perfil.public_id}/`)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updatePerfil(perfilPublicId, payload) {
      try {
          const { data } = await axios.patch(`api/v1/tenants/perfis/${perfilPublicId}/`, payload)
          return Promise.resolve(data)
      } catch (error) {
          return Promise.reject(error)
      }
    }

    async setMenu(perfil, menu) {
        try {
            const { data } = await axios.patch(`api/v1/tenants/perfis/${perfil}/`, { menu })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async setNome(perfil, nome) {
        try {
            const { data } = await axios.patch(`api/v1/tenants/perfis/${perfil}/`, { nome })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getMenusByPerfilId(perfilId, params) {
      const { data } = await axios.get(`api/v1/tenants/perfis/${perfilId}/menus/`, {params})
      return data
    }
}

export default new PerfilApi()
