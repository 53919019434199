import axios from 'axios'

class ImplementoApi {
  /**
   * Acesso a api de implemento.
   * -> api/v1/tenants/tenants/{parent_lookup_proprietario__public_id}/implementos/
   */
  //
  getUrlBase = (empresa) => {
    return `api/v1/tenants/tenants/${empresa.public_id}/implementos/`
  }
  getUrlDetail = (empresa, implemento) => {
    return `api/v1/tenants/tenants/${empresa.public_id}/implementos/${implemento.public_id}/`
  }
  urls = {
    get: this.getUrlBase,
    post: this.getUrlBase,
    retrieve: this.getUrlDetail,
    patch: this.getUrlDetail,
    delete: this.getUrlDetail,
  }
  async getImplementos(empresa, params) {
    try {
      const url = this.urls.get(empresa)
      const { data } = await axios.get(url, { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async updateDocumentosImplemento(empresa, implemento) {
    try {
      const url = this.urls.patch(empresa, implemento)
      const { data } = await axios.patch(url, {
        documentos: implemento.documentos,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async addImplemento(empresa, implemento) {
    try {
      const url = this.urls.post(empresa)
      const { data } = await axios.post(url, implemento)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async retrieveImplemento(empresa, implemento) {
    try {
      const url = this.urls.retrieve(empresa, implemento)
      const { data } = await axios.get(url)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async editImplemento(empresa, implemento) {
    try {
      const url = this.urls.patch(empresa, implemento)
      const { data } = await axios.patch(url, implemento)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async deleteImplemento(empresa, implemento) {
    try {
      const url = this.urls.delete(empresa, implemento)
      const { data } = await axios.delete(url)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new ImplementoApi()
