<template>
  <v-app
    id="rotacertasistemas"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { onMessage } from 'firebase/messaging'
export default {
  name: 'App',

  inject: ['messaging'],
  components: {},
  data() {
    return {
      messaging: null,
    }
  },
  methods: {
    ...mapActions('template', ['successMessage']),
  },
  mounted() {
    this.messaging = this.$messaging
    console.log('Firebase cloud messaging object', this.$messaging)
    onMessage(this.$messaging, (payload) => {
      const message = {}
      console.log(payload)
      message[payload.notification.title] = payload.notification.body
      this.successMessage(message)
    })
  },
}
</script>

<style>
.positive-value {
  color: green;
}
.positive-value::before {
  content: '+ ';
}
.negative-value {
  color: red;
}
.negative-value::before {
  content: '- ';
}
</style>
