import { Sequences } from '@/utils/permissions'

export default [
  {
    path: '/administrativo',
    component: () => import('@/layouts/full-layout/Layout'),
    children: [
      {
        name: 'Motoristas',
        path: 'motoristas',
        component: () => import('@/views/administrativo/motorista/Motorista'),
        meta: {
          title: 'Motoristas',
          BasicPermissions: [Sequences.Motoristas],
        },
      },

      {
        name: 'Terminais',
        path: 'terminais',
        props: true,
        component: () => import('@/views/administrativo/terminal/Terminal'),
        meta: {
          title: 'Terminais',
          BasicPermissions: [Sequences.Terminais],
        },
      },
      {
        name: 'Parceiros',
        path: 'parceiros',
        props: true,
        component: () => import('@/views/administrativo/parceiro/Parceiro'),
        meta: {
          title: 'Parceiros',
          BasicPermissions: [Sequences.Parceiros],
        },
      },
      {
        name: 'Participantes',
        path: 'participantes',
        props: true,
        component: () =>
          import('@/views/administrativo/participante/Participante'),
        meta: {
          title: 'Participantes',
          BasicPermissions: [Sequences.Participantes],
        },
      },
      {
        name: 'Pretadores',
        path: 'prestadores',
        props: true,
        component: () =>
          import('@/views/administrativo/prestadorServico/PrestadorServico'),
        meta: {
          title: 'Prestadores',
          BasicPermissions: [Sequences.Prestadores],
        },
      },
      {
        name: 'RegraPrestador',
        path: 'regras-prestadores',
        props: true,
        component: () =>
          import(
            '@/views/administrativo/regraPrestadorServico/RegraPrestadorServico'
          ),
        meta: {
          title: 'Regras de Prestadores de Serviços',
          BasicPermissions: [Sequences.RegrasPrestadores],
        },
      },
      {
        name: 'Transportadoras',
        path: 'transportadoras',
        component: () =>
          import('@/views/administrativo/transportadora/Transportadora'),
        meta: {
          title: 'Transportadoras',
          BasicPermissions: [Sequences.Transportadoras],
        },
      },
      {
        name: 'Clientes',
        path: 'clientes',
        component: () => import('@/views/administrativo/cliente/Cliente'),
        meta: {
          title: 'Clientes',
          BasicPermissions: [Sequences.Clientes],
        },
      },
      {
        name: 'Implementos',
        path: 'implementos',
        component: () => import('@/views/patio/veiculo/Implemento'),
        meta: {
          title: 'Implementos',
          // BasicPermissions: [Sequences.Implementos],
        },
      },
      {
        name: 'Veículos',
        path: 'veiculos',
        component: () => import('@/views/patio/veiculo/Veiculo'),
        meta: {
          title: 'Veículos',
          BasicPermissions: [Sequences.Veiculos],
        },
      },
      {
        name: 'Blacklist',
        path: 'Blacklist',
        component: () => import('@/views/patio/BlackList'),
        meta: {
          title: 'Blacklist',
        },
      },
      {
        name: 'Itens Laudo CheckList',
        path: 'itensLaudoCheckList',
        component: () => import('@/views/patio/ItensLaudoCheckList'),
        meta: {
          title: 'Itens Laudo CheckList',
        },
      },
      {
        name: 'Motivo de Cancelamento',
        path: 'motivoCancelamento',
        component: () => import('@/views/patio/MotivoCancelamento'),
        meta: {
          title: 'Motivo de Cancelamento',
        },
      },
      {
        name: 'Tef',
        path: 'tef',
        component: () => import('@/views/patio/Tef'),
        meta: {
          title: 'Gerenciamento de Tef',
        },
      },
      {
        name: 'Periféricos',
        path: 'perifericos',
        component: () =>
          import('@/views/administrativo/perifericos/Perifericos'),
        meta: {
          title: 'Periféricos',
          //BasicPermissions: [Sequences.Veiculos]
        },
      },
      {
        name: 'EtapasPeriféricos',
        path: 'etapasPerifericos',
        component: () =>
          import('@/views/administrativo/perifericos/EtapaPeriferico'),
        meta: {
          title: 'Etapa Periféricos',
          //BasicPermissions: [Sequences.Veiculos]
        },
      },
    ],
  },
]
