import axios from 'axios'
class LogisticaApi {
  //
  async getFluxoCaminhoes(empresa, params) {
    try {
      const { data } = await axios.get(`/api/v1/patio/janelaCargaDescarga/`, {
        params: { empresa: empresa, ...params },
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getJanelasCargaDescarga(empresa, params) {
    try {
      const { data } = await axios.get(`/api/v1/patio/janelaCargaDescarga/`, {
        params: { proprietario: empresa, ...params },
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getJanelaCargaDescarga(janela) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/janelaCargaDescarga/${janela.public_id}/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async addJanelaCargaDescarga(empresa, janela) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${empresa.public_id}/janelaCargaDescarga/`,
        janela
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async editJanelaCargaDescarga(empresa, janela) {
    try {
      const { data } = await axios.put(
        `/api/v1/tenants/empresas/${empresa.public_id}/janelaCargaDescarga/${janela.public_id}/`,
        janela
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async deleteJanelaCargaDescarga(empresa, janela) {
    try {
      const { data } = await axios.delete(
        `/api/v1/tenants/empresas/${empresa.public_id}/janelaCargaDescarga/${janela.public_id}/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new LogisticaApi()
