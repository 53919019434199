const getDefaultState = () => {
  return {
    params: {
      data_inicio: {
        value: '',
        visible: false,
      },
      data_fim: {
        value: '',
        visible: false,
      },
      gate: {
        value: '',
        visible: false,
      },
      parceiro: {
        value: '',
        visible: false,
      },
      proprietario: {
        value: '',
        visible: false,
      },
      terminal: {
        value: '',
        visible: false,
      },
      servico: {
        value: null,
        visible: false,
      },
    },
  }
}

export const relatorioParceiro = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
      localStorage.setItem(
        'filterOptionsDetailsParceiros',
        JSON.stringify(state.params)
      )
    },
    UPDATE_PARAMS(state, params) {
      state.params = params
    },
  },
}
