import { ConferenciaStatus } from "@/utils/conferencia"

export default {
  aprovados(state) {
    let itens = {}
    itens.headers = state.conferencias.headers
    itens.caixas = state.conferencias.caixas.filter(conferencia => conferencia.status === ConferenciaStatus.Aprovado.name)
    return itens
  },
  reprovados(state) {
    let itens = {}
    itens.headers = state.conferencias.headers
    itens.caixas = state.conferencias.caixas.filter(conferencia => conferencia.status === ConferenciaStatus.Reprovado.name)
    return itens
  },
  concluidas(state) {
    let itens = {}
    itens.headers = state.conferencias.headers
    itens.caixas = state.conferencias.caixas.filter(conferencia => conferencia.status === ConferenciaStatus.Concluida.name)
    return itens
  }
}
