import axios from 'axios'
import router from '../router'
import store from '../store'
import AuthService from '../services/auth.service'
const TOKEN_INVALID_OR_EXPIRED_MESSAGE = 'Login expirado!'

if (process.env.VUE_APP_API_URL) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
} else
  throw 'Não foi possível encontrar a variável VUE_APP_API_URL. Configure o arquivo .env.local'

axios.interceptors.request.use(
  (config) => {
    const userData = JSON.parse(localStorage.getItem('user'))
    const token = userData ? userData.access : null

    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`
      // const storage = JSON.parse(localStorage.getItem('rcsist'))
      // if (
      //     config.method == 'get' &&
      //     storage &&
      //     storage.auth &&
      //     storage.auth.empresaAtual
      // ) {
      //     // const tenantid = !storage.auth.empresaAtual.system_admin
      //     //   ? storage.auth.empresaAtual.id
      //     //   : null
      //     // // if (tenantid) {
      //     // //   config.params = { tenant: tenantid }
      //     // // }
      // }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      if (
        router.app?._route?.meta?.refresh_token &&
        error.config.url != AuthService.refresh_url
      ) {
        error.refreshed = true
        const user = JSON.parse(localStorage.getItem('user'))
        store.dispatch('auth/refresh', user.refresh)
      } else {
        store.dispatch(
          'template/errorMessage',
          TOKEN_INVALID_OR_EXPIRED_MESSAGE
        )
        setTimeout(() => {
          store.dispatch('auth/logout')
          router.push('/auth/login/')
        }, 3000)
      }
    }
    return Promise.reject(error)
  }
)
