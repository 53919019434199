import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'
import '@/scss/template/overrides.scss'
Vue.use(Vuetify)

const theme = {
  primary: '#00113a', // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  secondary: '#00aa9e',
  tertiary: '#fba31a',

  info: '#00aa9e',
  success: '#21c1d6',
  accent: '#fc4b6c',
  default: '#00113a',
}

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  lang: {
    locales: {pt},
    current: 'pt'
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
})
