import axios from 'axios'

class MotoristasApi {

    // Motoristas de um Gate

    async getMotoristas(empresaAtual) {
        const id = empresaAtual.public_id
        try {
            const { data } = await axios.get(`api/v1/tenants/empresas/${id}/motoristas/`)
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getMotorista(empresaAtual, params) {
        const id = empresaAtual.public_id
        try {
            const { data } = await axios.get(`api/v1/tenants/empresas/${id}/motoristas/`, {
                params
            })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async excluirMotorista(motorista) {
        try {
            await axios.delete(`api/v1/motoristas/${motorista.public_id}/`)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getMotoristaByCpf(cpf) {
        try {
            const { data } = await axios.get(`api/v1/motoristas/?user.cpf=${cpf}`)
            return Promise.resolve(data[0])
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default new MotoristasApi()
