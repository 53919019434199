import { Sequences } from "@/utils/permissions"

export default [
    {
        path: '/comercial',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                name: 'Fornecedores',
                path: 'fornecedores',
                props: true,
                component: () => import('@/views/comercial/fornecedores/Fornecedores'),
                meta: {
                    title: "Fornecedores",
                    BasicPermissions: [Sequences.Fornecedores]
                }
            },
            {
                name: 'navios',
                path: 'navios',
                component: () => import('@/views/patio/Navio'),
                meta: {
                    title: "Navios",
                    BasicPermissions: [Sequences.Navios]
                }
            },
            {
                name: 'pedidos',
                path: 'pedidos',
                component: () => import('@/views/patio/Pedido'),
                meta: {
                    title: "Pedidos",
                    BasicPermissions: [Sequences.Pedidos]
                }
            },
        ],
    }
]
