import FinanceiroApi from '@/services/financeiro'

const getOrdemEtapas = async ({ commit, }, params) => {
    const servico = params.servico
    delete params.servico
    try {
        const ordemEtapas = await FinanceiroApi.getOrdemEtapas(servico, params)
        commit('SET_ORDEM_ETAPAS', ordemEtapas)
        return Promise.resolve(ordemEtapas)
    } catch (error) {
        commit('SET_ORDEM_ETAPAS', [])
        return Promise.reject(error)
    }
}

const getOrdemEtapa = async ({ commit }, ordemEtapa) => {
    try {
        const servico = ordemEtapa.servico
        const _ordemEtapa = await FinanceiroApi.getOrdemEtapa(servico, ordemEtapa)
        commit('SET_ORDEM_ETAPA', _ordemEtapa)
        return Promise.resolve(_ordemEtapa)
    } catch (error) {
        return Promise.reject(error)
    }
}

const addOrdemEtapa = async (state, novaOrdemEtapa) => {
    try {
        const servico = novaOrdemEtapa.servico
        const ordemEtapa = await FinanceiroApi.addOrdemEtapa(servico, novaOrdemEtapa)
        // dispatch('getOrdemEtapas', { servico: servico })
        return Promise.resolve(ordemEtapa)
    } catch (error) {
        return Promise.reject(error)
    }
}

const updateOrdemEtapa = async (state, ordemEtapa) => {
    try {
        const servico = ordemEtapa.servico
        const _ordemEtapa = await FinanceiroApi.updateOrdemEtapa(servico, ordemEtapa)
        // dispatch('getOrdemEtapas', { servico: servico })
        return Promise.resolve(_ordemEtapa)
    } catch (error) {
        return Promise.reject(error)
    }
}

const deleteOrdemEtapa = async (state, ordemEtapa) => {
    try {
        const servico = ordemEtapa.servico
        const data = await FinanceiroApi.deleteOrdemEtapa(servico, ordemEtapa)
        // dispatch('getOrdemEtapas', { servico: servico })
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export default {
    getOrdemEtapas,
    getOrdemEtapa,
    addOrdemEtapa,
    updateOrdemEtapa,
    deleteOrdemEtapa,
}