import { Sequences } from '@/utils/permissions'

export default [
  {
    path: '/sistema',
    component: () => import('@/layouts/full-layout/Layout'),
    children: [
      {
        path: 'veiculos',
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
          {
            name: 'Cores',
            path: 'cores',
            component: () => import('@/views/administrativo/veiculos/Cor'),
            meta: {
              title: 'Cores',
              BasicPermissions: [Sequences.SistemaVeiculoCores],
            },
          },
          {
            name: 'Marcas',
            path: 'marcas',
            component: () => import('@/views/administrativo/veiculos/Marca'),
            meta: {
              title: 'Marcas',
              BasicPermissions: [Sequences.SistemaVeiculoMarcas],
            },
          },
        ],
      },
      {
        name: 'Empresas',
        path: 'empresas',
        props: true,
        component: () => import('@/views/gerencial/empresas/Empresas'),
        meta: {
          title: 'Empresas',
          BasicPermissions: [Sequences.SistemaEmpresas],
        },
      },

      {
        name: 'Carrocerias',
        path: 'carrocerias',
        component: () => import('@/views/administrativo/veiculos/Carroceria'),
        meta: {
          title: 'Carrocerias',
          BasicPermissions: [Sequences.SistemaCarroceria],
        },
      },
      {
        name: 'Modalidades',
        path: 'modalidades',
        component: () => import('@/views/administrativo/veiculos/Modalidade'),
        meta: {
          title: 'Modalidades',
          BasicPermissions: [Sequences.SistemaModalidades],
        },
      },
      {
        name: 'Perfis',
        path: 'perfis',
        component: () => import('@/views/sistema/Perfis'),
        meta: {
          title: 'Perfis',
          BasicPermissions: [Sequences.SistemaPerfis],
        },
      },
      {
        name: 'Performance',
        path: 'performance',
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
          {
            name: 'Gates',
            path: 'gates',
            component: () => import('@/views/sistema/performance/Gates.vue'),
            meta: {
              title: 'Performance dos Gates',
              // BasicPermissions: [],
            },
          },
        ],
      },
    ],
  },
]
