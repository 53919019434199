export default {
  fields: {
    "business_name": "Nome Fantasia",
    "trade_name": "Razão Social",
    "address": "Endereço",
    "district": "Bairro",
    "zip_code": "CEP",
    "city": "Cidade",
    "state": "Estado",
    "phone": "Telefone",
    "user": "Usuário",
  }
}