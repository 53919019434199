import FormaPagamentoAPI from "@/services/forma-pagamento"

export default {
  async getFormasPagamento({ rootState, commit }, params) {
    try {
      const gatePublicID = rootState.auth.empresaAtual.public_id
      const data = await FormaPagamentoAPI.getFormasPagamento(gatePublicID, params)
      commit("SET_FORMAS_PAGAMENTO", data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getFormaPagamento({ rootState }, formaPagamentoPublicID) {
    try {
      const gatePublicID = rootState.auth.empresaAtual.public_id
      const data = await FormaPagamentoAPI.getFormaPagamento(gatePublicID, formaPagamentoPublicID)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async addFormaPagamento({ rootState, commit }, dados) {
    try {
      const gatePublicID = rootState.auth.empresaAtual.public_id
      const data = await FormaPagamentoAPI.addFormaPagamento(gatePublicID, { ...dados, gate: gatePublicID })
      commit("ADD_FORMA_PAGAMENTO", data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async editFormaPagamento({ rootState, commit }, dados) {
    try {
      const gatePublicID = rootState.auth.empresaAtual.public_id
      const data = await FormaPagamentoAPI.editFormaPagamento(gatePublicID, dados)
      commit("EDIT_FORMA_PAGAMENTO", data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async deleteFormaPagamento({ rootState, commit }, formaPagamentoPublicID) {
    try {
      const gatePublicID = rootState.auth.empresaAtual.public_id
      const data = await FormaPagamentoAPI.deleteFormaPagamento(gatePublicID, formaPagamentoPublicID)
      commit("REMOVE_FORMA_PAGAMENTO", { public_id: formaPagamentoPublicID })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
