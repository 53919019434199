export const getDefaultState = () => {
  return {
    programacoesUnidade: [],
    programacoesUnidadeResumo: {
      detalhes: [],
      headers: [],
      resumo: [],
    },
    programacoesRegiaoResumo: {
      detalhes: [],
      headers: [],
      resumo: [],
    },
    programacoesFornecedor: [],
    programacoesCliente: [],
    programacoesRegiao: [],
    programacoesTransportadora: [],

    programacaoUnidade: null,
    programacaoFornecedor: null,
    programacaoCliente: null,
    programacaoRegiao: null,
    programacaoTransportadora: null,

    programacaoDetalhe: null,

    params: {
      gate: null,
      tipo_operacao: null,
      fornecedor: null,
      produto: null,
      sub_produto: null,
      terminal: null,
      data_inicio: null,
      data_fim: null,
      regiao: [],
      tipo_frete: null,
    },
  }
}

const RESET_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

const SET_PROGRAMACAO_DETALHE = (state, payload) => {
  state.programacaoDetalhe = payload
}

const SET_PROGRAMACOES_UNIDADE = (state, payload) => {
  state.programacoesUnidade = payload
}

const SET_PROGRAMACOES_UNIDADE_RESUMO = (state, payload) => {
  state.programacoesUnidadeResumo = payload
}

const SET_PROGRAMACOES_REGIAO_RESUMO = (state, payload) => {
  state.programacoesRegiaoResumo = payload
}

const SET_PROGRAMACOES_FORNECEDOR = (state, payload) => {
  state.programacoesFornecedor = payload
}

const SET_PROGRAMACOES_REGIAO = (state, payload) => {
  state.programacoesRegiao = payload
}

const SET_PROGRAMACOES_CLIENTE = (state, payload) => {
  state.programacoesCliente = payload
}

const SET_PROGRAMACAO_UNIDADE = (state, payload) => {
  state.programacaoUnidade = payload
}

const SET_PROGRAMACAO_FORNECEDOR = (state, payload) => {
  state.programacaoFornecedor = payload
}

const SET_PROGRAMACAO_REGIAO = (state, payload) => {
  state.programacaoRegiao = payload
}

const SET_PROGRAMACAO_CLIENTE = (state, payload) => {
  state.programacaoCliente = payload
}

const EDIT_PROGRAMACO_UNIDADE = (state, payload) => {
  const index = state.programacoesUnidade.findIndex(
    (o) => o.public_id === payload.public_id
  )
  state.programacoesUnidade.splice(index, 1, payload)
}
const ADD_PROGRAMACAO_UNIDADE = (state, payload) => {
  state.programacoesUnidade.push(payload)
}

const EDIT_PROGRAMACO_FORNECEDOR = (state, payload) => {
  const index = state.programacoesFornecedor.findIndex(
    (o) => o.public_id === payload.public_id
  )
  state.programacoesFornecedor.splice(index, 1, payload)
}

const ADD_PROGRAMACAO_FORNECEDOR = (state, payload) => {
  state.programacoesFornecedor.push(payload)
}

const EDIT_PROGRAMACO_REGIAO = (state, payload) => {
  const index = state.programacoesRegiao.findIndex(
    (o) => o.public_id === payload.public_id
  )
  state.programacoesRegiao.splice(index, 1, payload)
}

const ADD_PROGRAMACAO_REGIAO = (state, payload) => {
  state.programacoesRegiao.push(payload)
}

const EDIT_PROGRAMACO_CLIENTE = (state, payload) => {
  const index = state.programacoesCliente.findIndex(
    (o) => o.public_id === payload.public_id
  )
  state.programacoesCliente.splice(index, 1, payload)
}

const ADD_PROGRAMACAO_CLIENTE = (state, payload) => {
  state.programacoesCliente.push(payload)
}

const SET_PARAMS = (state, payload) => {
  Object.assign(state.params, payload)
}

export default {
  RESET_STATE,

  SET_PROGRAMACOES_UNIDADE,
  SET_PROGRAMACAO_DETALHE,
  SET_PROGRAMACOES_FORNECEDOR,
  SET_PROGRAMACOES_REGIAO,
  SET_PROGRAMACOES_CLIENTE,

  SET_PROGRAMACOES_UNIDADE_RESUMO,
  SET_PROGRAMACOES_REGIAO_RESUMO,

  SET_PROGRAMACAO_UNIDADE,
  SET_PROGRAMACAO_FORNECEDOR,
  SET_PROGRAMACAO_REGIAO,
  SET_PROGRAMACAO_CLIENTE,

  EDIT_PROGRAMACO_UNIDADE,
  EDIT_PROGRAMACO_FORNECEDOR,
  EDIT_PROGRAMACO_REGIAO,
  EDIT_PROGRAMACO_CLIENTE,

  ADD_PROGRAMACAO_UNIDADE,
  ADD_PROGRAMACAO_FORNECEDOR,
  ADD_PROGRAMACAO_REGIAO,
  ADD_PROGRAMACAO_CLIENTE,

  SET_PARAMS,
}
