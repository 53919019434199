import PatioApi from '@/services/patio'

const getProgramacoesUnidade = async ({ commit, rootState }, params) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const programacoesUnidade = await PatioApi.getProgramacoesUnidade(
      empresa,
      params
    )
    commit('SET_PROGRAMACOES_UNIDADE', programacoesUnidade)
    return Promise.resolve(programacoesUnidade)
  } catch (error) {
    commit('SET_PROGRAMACOES_UNIDADE', [])
    return Promise.reject(error)
  }
}

const getProgramacoesUnidadeResumo = async ({ commit, rootState }, params) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const programacoesUnidadeResumo = await PatioApi.getProgramacoesUnidadeResumo(
      empresa,
      params
    )
    commit('SET_PROGRAMACOES_UNIDADE_RESUMO', programacoesUnidadeResumo)
    return Promise.resolve(programacoesUnidadeResumo)
  } catch (e) {
    commit('SET_PROGRAMACOES_UNIDADE_RESUMO', [])
    return Promise.reject(e)
  }
}

const getProgramacoesRegiaoResumo = async ({ commit, rootState }, params) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const programacoesRegiaoResumo = await PatioApi.getProgramacoesRegiaoResumo(
      empresa,
      params
    )
    commit('SET_PROGRAMACOES_REGIAO_RESUMO', programacoesRegiaoResumo)
    return Promise.resolve(programacoesRegiaoResumo)
  } catch (e) {
    commit('SET_PROGRAMACOES_REGIAO_RESUMO', [])
    return Promise.reject(e)
  }
}

const getProgramacaoDetalhe = async ({ commit }, programacao) => {
  try {
    const programacaoDetalhe = await PatioApi.getProgramacaoDetalhe(
      programacao.type,
      programacao.public_id
    )
    commit('SET_PROGRAMACAO_DETALHE', programacaoDetalhe)
    return Promise.resolve(programacaoDetalhe)
  } catch (e) {
    commit('SET_PROGRAMACAO_DETALHE', null)
    return Promise.reject(e)
  }
}

const getProgramacoesFornecedor = async ({ commit, rootState }, params) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const programacoesFornecedor = await PatioApi.getProgramacoesFornecedor(
      empresa,
      params
    )
    commit('SET_PROGRAMACOES_FORNECEDOR', programacoesFornecedor)
    return Promise.resolve(programacoesFornecedor)
  } catch (error) {
    commit('SET_PROGRAMACOES_FORNECEDOR', [])
    return Promise.reject(error)
  }
}
const getProgramacoesFornecedorSimple = async (
  { commit, rootState },
  params
) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const programacoesFornecedor = await PatioApi.getProgramacoesFornecedorSimple(
      empresa,
      params
    )
    commit('SET_PROGRAMACOES_FORNECEDOR', programacoesFornecedor)
    return Promise.resolve(programacoesFornecedor)
  } catch (error) {
    commit('SET_PROGRAMACOES_FORNECEDOR', [])
    return Promise.reject(error)
  }
}

const getProgramacoesRegiao = async ({ commit, rootState }, params) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const programacoesRegiao = await PatioApi.getProgramacoesRegiao(
      empresa,
      params
    )
    commit('SET_PROGRAMACOES_REGIAO', programacoesRegiao)
    return Promise.resolve(programacoesRegiao)
  } catch (error) {
    commit('SET_PROGRAMACOES_REGIAO', [])
    return Promise.reject(error)
  }
}

const getProgramacoesCliente = async ({ commit, rootState }, params) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const programacoesCliente = await PatioApi.getProgramacoesCliente(
      empresa,
      params
    )
    commit('SET_PROGRAMACOES_CLIENTE', programacoesCliente)
    return Promise.resolve(programacoesCliente)
  } catch (error) {
    commit('SET_PROGRAMACOES_CLIENTE', [])
    return Promise.reject(error)
  }
}

const getProgramacaoUnidade = async (
  { commit },
  programacaoUnidadePublicID
) => {
  try {
    const programacaoUnidade = await PatioApi.getProgramacaoUnidade(
      programacaoUnidadePublicID
    )
    commit('SET_PROGRAMACAO_UNIDADE', programacaoUnidade)
    return Promise.resolve(programacaoUnidade)
  } catch (error) {
    commit('SET_PROGRAMACAO_UNIDADE', null)
    return Promise.reject(error)
  }
}

const getProgramacaoFornecedor = async (
  { commit },
  programacaoFornecedorPublicID
) => {
  try {
    const data = await PatioApi.getProgramacaoFornecedor({
      public_id: programacaoFornecedorPublicID,
    })
    commit('SET_PROGRAMACAO_FORNECEDOR', data)
    return Promise.resolve(data)
  } catch (error) {
    commit('SET_PROGRAMACAO_FORNECEDOR', {})
    return Promise.reject(error)
  }
}

const getProgramacaoRegiao = async ({ commit }, programacaoRegiaoPublicID) => {
  try {
    const data = await PatioApi.getProgramacaoRegiao({
      public_id: programacaoRegiaoPublicID,
    })
    commit('SET_PROGRAMACAO_REGIAO', data)
    return Promise.resolve(data)
  } catch (error) {
    commit('SET_PROGRAMACAO_REGIAO', {})
    return Promise.reject(error)
  }
}

const getProgramacaoCliente = async (
  { commit },
  programacaoClientePublicID
) => {
  try {
    const data = await PatioApi.getProgramacaoCliente({
      public_id: programacaoClientePublicID,
    })
    commit('SET_PROGRAMACAO_CLIENTE', data)
    return Promise.resolve(data)
  } catch (error) {
    commit('SET_PROGRAMACAO_CLIENTE', {})
    return Promise.reject(error)
  }
}

const editProgramacaoUnidade = async ({ commit, rootState }, novoValor) => {
  try {
    const empresa = rootState.auth.empresaAtual.public_id
    const programacaoUnidadeAlterada = await PatioApi.editProgramacaoUnidade(
      novoValor,
      empresa
    )
    commit('EDIT_PROGRAMACO_UNIDADE', programacaoUnidadeAlterada)
    return Promise.resolve(programacaoUnidadeAlterada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addProgramacaoUnidade = async ({ commit }, novoProgramacaoUnidade) => {
  try {
    const programacaoUnidadeAdicionada = await PatioApi.addProgramacaoUnidade(
      novoProgramacaoUnidade
    )
    commit('ADD_PROGRAMACAO_UNIDADE', programacaoUnidadeAdicionada)
    return Promise.resolve(programacaoUnidadeAdicionada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editProgramacaoFornecedor = async ({ commit, rootState }, novoValor) => {
  try {
    const empresa = rootState.auth.empresaAtual.public_id
    const programacaoFornecedorAlterada = await PatioApi.editProgramacaoFornecedor(
      novoValor,
      empresa
    )
    commit('EDIT_PROGRAMACO_FORNECEDOR', programacaoFornecedorAlterada)
    return Promise.resolve(programacaoFornecedorAlterada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addProgramacaoFornecedor = async ({ commit }, programacaoFornecedor) => {
  try {
    const data = await PatioApi.addProgramacaoFornecedor(programacaoFornecedor)
    commit('ADD_PROGRAMACAO_FORNECEDOR', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editProgramacaoRegiao = async ({ commit, rootState }, novoValor) => {
  try {
    const empresa = rootState.auth.empresaAtual.public_id
    const programacaoRegiaoAlterada = await PatioApi.editProgramacaoRegiao(
      novoValor,
      empresa
    )
    commit('EDIT_PROGRAMACO_REGIAO', programacaoRegiaoAlterada)
    return Promise.resolve(programacaoRegiaoAlterada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addProgramacaoRegiao = async ({ commit }, programacaoRegiao) => {
  try {
    const data = await PatioApi.addProgramacaoRegiao(programacaoRegiao)
    commit('ADD_PROGRAMACAO_REGIAO', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editProgramacaoCliente = async ({ commit, rootState }, novoValor) => {
  try {
    const empresa = rootState.auth.empresaAtual.public_id
    const programacaoClienteAlterada = await PatioApi.editProgramacaoCliente(
      novoValor,
      empresa
    )
    commit('EDIT_PROGRAMACO_CLIENTE', programacaoClienteAlterada)
    return Promise.resolve(programacaoClienteAlterada)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addProgramacaoCliente = async ({ commit }, programacaoCliente) => {
  try {
    const data = await PatioApi.addProgramacaoCliente(programacaoCliente)
    commit('ADD_PROGRAMACAO_CLIENTE', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getProgramacoesUnidade,
  getProgramacaoDetalhe,
  getProgramacoesFornecedor,
  getProgramacoesFornecedorSimple,
  getProgramacoesRegiao,
  getProgramacoesCliente,

  getProgramacoesUnidadeResumo,
  getProgramacoesRegiaoResumo,

  getProgramacaoUnidade,
  getProgramacaoFornecedor,
  getProgramacaoRegiao,
  getProgramacaoCliente,

  editProgramacaoUnidade,
  editProgramacaoFornecedor,
  editProgramacaoRegiao,
  editProgramacaoCliente,

  addProgramacaoUnidade,
  addProgramacaoFornecedor,
  addProgramacaoRegiao,
  addProgramacaoCliente,
}
