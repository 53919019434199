import TenantsApi from '@/services/tenants'


const adicionarRemetenteDestinatario = async ({ commit }, novoRemetenteDestinatario) => {
  const remetenteDestinatarioAdicionado = await TenantsApi.adicionarRemetenteDestinatario(novoRemetenteDestinatario)
  commit('novoRemetenteDestinatario', remetenteDestinatarioAdicionado)
}


const getRemetentesDestinatarios = async ({ commit }, empresaAtual) => {
  const remetentesDestinatarios = await TenantsApi.getRemetentesDestinatarios(empresaAtual)
  commit('setRemetentesDestinatarios', remetentesDestinatarios)
}

const atualizarContatoRemetentesDestinatarios = async (_, payload) => {
  const contatoAtualizado = await TenantsApi.atualizarContatoRemetentesDestinatarios(payload.empresa, payload.cliente, payload.contatos)
  return contatoAtualizado
}

const deleteRemetenteDestinatario = async (_, remetenteDestinatario) => {
  await TenantsApi.deleteRemetenteDestinatario(remetenteDestinatario)
}

export default {
  adicionarRemetenteDestinatario,
  getRemetentesDestinatarios,
  atualizarContatoRemetentesDestinatarios,
  deleteRemetenteDestinatario
}