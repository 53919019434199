import axios from 'axios'

class MenuApi {
  async getMenu() {
    try {
      const { data } = await axios.get('api/v1/menus/')
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getUserMenu(user, grupo) {
    try {
      const { data } = await axios.get(`api/v1/users/${user.public_id}/menus/${grupo.public_id}/`)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getEmpresaAcessos(empresa) {
      try {
          const { data } = await axios.get(`api/v1/tenants/empresas/${empresa}/acessosPermitidos/`)
          return Promise.resolve(data)
      } catch(err) {
          return Promise.reject(err)
      }
  }
}

export default new MenuApi()
