import axios from 'axios'

class TransportadorasApi {
  // Fornecedores de um Gate

  async getTransportadoras(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/transportadoras/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getTransportadorasSimple(empresa, params) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/transportadoras/simple/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getTransportadoraEspecifica(empresa, params) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/transportadoras/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async adicionarTransportadora(empresaAtual, novaTransportadora) {
    const id = empresaAtual.public_id
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${id}/transportadoras/`,
        novaTransportadora
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarTransportadora(empresaAtual, transportadora) {
    const id = empresaAtual.public_id
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/empresas/${id}/transportadoras/${transportadora.public_id}/`,
        transportadora
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async excluirTransportadora(empresaAtual, transportadora) {
    const empresa_id = empresaAtual.public_id
    const transportadora_id = transportadora.public_id
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/empresas/${empresa_id}/transportadoras/${transportadora_id}`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarContatosTransportadora(empresa, transportadora, contatos) {
    const empresa_id = empresa.public_id
    try {
      const {
        data,
      } = await axios.patch(
        `api/v1/tenants/empresas/${empresa_id}/transportadoras/${transportadora}/`,
        { contatos: contatos }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async adicionarProduto(fornecedor, novoProduto) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/fornecedores/${fornecedor}/produtos/`,
        novoProduto
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async desativarProduto(fornecedor, id) {
    try {
      await axios.delete(
        `api/v1/tenants/fornecedores/${fornecedor}/produtos/${id}/`
      )
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }

  async adicionarSubProduto(novo) {
    try {
      const { data } = await axios.post('api/v1/tenants/subprodutos/', novo)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async excluirSubProduto(id) {
    try {
      await axios.delete(`api/v1/tenants/subprodutos/${id}/`)
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }

  async adicionarTerminal(fornecedor, novoTerminal) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/`,
        novoTerminal
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarTerminal(fornecedor, terminal) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/${terminal.id}/`,
        terminal
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async excluirTerminal(fornecedor, id) {
    try {
      await axios.delete(
        `api/v1/tenants/fornecedores/${fornecedor}/terminais/${id}/`
      )
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(false)
    }
  }
}

export default new TransportadorasApi()
