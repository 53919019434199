import axios from 'axios'

class TenantsApi {
  // TENANTS - Empresas em geral

  async getTenantByCPFOrCNPJ(params) {
    try {
      const { data } = await axios.get(`api/v1/tenants/empresas/`, { params })
      return Promise.resolve(data.results)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getEmpresas(params) {
    try {
      const { data } = await axios.get('api/v1/tenants/empresas/', { params })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getEmpresaByPublicID(publicID) {
    try {
      const { data } = await axios.get(`api/v1/tenants/empresas/${publicID}/`)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addEmpresa(novaEmpresa) {
    try {
      const { data } = await axios.post('api/v1/tenants/empresas/', novaEmpresa)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteEmpresa(empresa) {
    try {
      await axios.delete(`api/v1/tenants/empresas/${empresa.public_id}/`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarContatosEmpresa(tenant, contatos) {
    const { data } = await axios.patch(`api/v1/tenants/tenants/${tenant}/`, { contatos })
    return data
  }

  async atualizarPerfisEmpresa(empresa, perfis) {
    const { data } = await axios.post(`api/v1/tenants/empresas/${empresa}/perfis/`, { perfis })
    return data
  }

  // Clientes de Tenants

  async getClientes(empresaAtual) {
    const id = empresaAtual.public_id
    const { data } = await axios.get(`api/v1/tenants/tenants/${id}/clientes/`)
    return data
  }

  async getClientesById(empresaAtual) {
    const id = empresaAtual
    const { data } = await axios.get(`api/v1/tenants/tenants/${id}/clientes/`)
    return data
  }

  async adicionarCliente(novoCliente) {
    try {
      const id = novoCliente.empresa
      const { data } = await axios.post(`api/v1/tenants/empresas/${id}/clientes/`, novoCliente)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarContatosCliente(cliente, contatos) {
    const { data } = await axios.patch(`api/v1/tenants/clientes/${cliente}/`, { contatos: contatos })
    return data
  }

  // ***************
  // Transportadoras
  // ***************

  async getTransportadoras(empresaAtual) {
    const id = empresaAtual.id
    const { data } = await axios.get(`api/v1/tenants/tenants/${id}/transportadoras/`)
    return data
  }

  async adicionarTransportadora(novaTransportadora) {
    try {
      const id = novaTransportadora.empresa
      const { data } = await axios.post(`api/v1/tenants/tenants/${id}/transportadoras/`, novaTransportadora)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async atualizarContatoTransportadoras(empresa, transportadora, contatos) {
    const { data } = await axios.patch(`api/v1/tenants/tenants/${empresa}/transportadoras/${transportadora}/`, { contatos })
    return data
  }

  async deleteTransportadora(transportadora) {
    const { empresaId, publicId } = transportadora
    await axios.delete(`api/v1/tenants/tenants/${empresaId}/transportadoras/${publicId}`)
  }

  // ***************
  // Rementente-Destinatario
  // ***************

  async getRemetentesDestinatarios(empresaAtual) {
    const id = empresaAtual.id
    const { data } = await axios.get(`api/v1/tenants/tenants/${id}/remetentes_destinatarios/`)
    return data
  }

  async adicionarRemetenteDestinatario(novoRemetenteDestinatario) {
    const id = novoRemetenteDestinatario.empresa
    const { data } = await axios.post(`api/v1/tenants/tenants/${id}/remetentes_destinatarios/`, novoRemetenteDestinatario)
    return data
  }

  async atualizarContatoRemetentesDestinatarios(empresa, remetenteDestinatario, contatos) {
    const { data } = await axios.patch(`api/v1/tenants/tenants/${empresa}/remetentes_destinatarios/${remetenteDestinatario}/`, { contatos })
    return data
  }

  async deleteRemetenteDestinatario(remetenteDestinatario) {
    const { empresaId, publicId } = remetenteDestinatario
    await axios.delete(`api/v1/tenants/tenants/${empresaId}/remetentes_destinatarios/${publicId}`)
  }

  async editProduto(novo) {
    const id = novo.id
    const { data } = await axios.patch(
      `api/v1/patio/produtos/${id}/`,
      novo
    )
    return data
  }

  // sub produto
  async getSubProduto() {
    const { data } = await axios.get('api/v1/tenants/subprodutos/')
    return data
  }

  async editSubProduto(novo) {
    const id = novo.id
    const { data } = await axios.patch(
      `api/v1/tenants/subprodutos/${id}/`,
      novo
    )
    return data
  }

  async getCategorias() {
    const { data } = await axios.get('api/v1/tenants/categorias/')
    return data
  }

  async getDestinatario(empresaAtual, params) {
    try {
      empresaAtual = empresaAtual.public_id
      const { data } = await axios.get(`/api/v1/tenants/empresas/${empresaAtual}/remetentes_destinatarios/`, {
        params
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export default new TenantsApi()
