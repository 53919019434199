import MotoristasApi from '@/services/tenants/motoristas'

const getMotoristas = async ({ commit, rootState }) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const motoristas = await MotoristasApi.getMotoristas(empresa)
    commit('setMotoristas', motoristas)
    return Promise.resolve(motoristas)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getMotorista = async ({ commit, rootState }, params) => {
  const empresa = rootState.auth.empresaAtual
  try {
    const itens = await MotoristasApi.getMotorista(empresa, params)
    commit('setMotoristas', itens)
    return Promise.resolve(itens)
  } catch (err) {
    return Promise.reject(err)
  }
}

const excluirMotorista = async ({ commit }, motorista) => {
  try {
    await MotoristasApi.excluirMotorista(motorista)
    commit("excluirMotorista", motorista)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getMotoristas,
  getMotorista,
  excluirMotorista
}