// import { Sequences } from "@/utils/permissions"

export default [
    {
        path: '/extracao',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                name: 'CargaDescarga',
                path: 'carga-descarga',
                props: true,
                component: () => import('@/views/extracao/CargaDescarga.vue'),
                meta: {
                    title: "Extração Carga/Descarga",
                    BasicPermissions: []
                }
            },
        ],
    }
]
