import axios from 'axios'

class DistribuicaoApi {
  async getDistribuicao(navio_id, params) {
    try {
      const { data } = await axios.get(
        `api/v1/patio/navios/${navio_id}/distribuicao/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async addDistribuicao(distribuicao) {
    try {
      const { navio } = distribuicao
      const { data } = await axios.post(
        `api/v1/patio/navios/${navio}/distribuicao/`,
        distribuicao
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async editDistribuicao(distribuicao) {
    try {
      const { id, navio } = distribuicao
      const { data } = await axios.patch(
        `api/v1/patio/navios/${navio}/distribuicao/${id}/`,
        distribuicao
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async deleteDistribuicao(distribuicao) {
    try {
      const { id, navio } = distribuicao
      const { data } = await axios.delete(
        `api/v1/patio/navios/${navio.id}/distribuicao/${id}/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export default new DistribuicaoApi()
