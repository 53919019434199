import { Object } from 'core-js'
import Vue from 'vue'
import getDefaultState from './state'

const RESET_STATE = (state) => {
  const initialState = getDefaultState()
  for (const f in state) {
    Vue.set(state, f, initialState[f])
  }
}
const ATUALIZA_TIPOS_FRETE = (state, tipos_frete) => {
  state.tiposFrete = tipos_frete
}
const ATUALIZA_FORNECEDORES = (state, fornecedores) => {
  state.fornecedores = fornecedores
}

const ATUALIZA_CLIENTES = (state, clientes) => {
  state.clientes = clientes
}

const ATUALIZA_GATES = (state, gates) => {
  state.gates = gates
}

const ATUALIZA_TRANSPORTADORAS = (state, transportadoras) => {
  state.transportadoras = transportadoras
}

const ATUALIZA_TERMINAIS = (state, terminais) => {
  state.terminais = terminais
}

const ATUALIZA_AGENDAMENTO = (state, obj) => {
  state.agendamento = Object.assign({}, state.agendamento, obj)
}
const ATUALIZA_ATENDIMENTO = (state, obj) => {
  state.atendimento = Object.assign({}, state.atendimento, obj)
}

const CONCAT_TRANSPORTADORAS = (state, obj) => {
  state.transportadoras = state.transportadoras.concat(obj)
}

const ATUALIZA_ESTACIONAMENTOS = (state, estacionamentos) => {
  state.estacionamentos = estacionamentos
}
const ATUALIZA_SETORES = (state, setores) => {
  state.setores = setores
}
const ATUALIZA_VAGAS = (state, vagas) => {
  state.vagas = vagas
}

const ATUALIZA = (state, obj) => {
  Object.assign(state, obj)
}

export default {
  RESET_STATE,
  ATUALIZA_TIPOS_FRETE,
  ATUALIZA_FORNECEDORES,
  ATUALIZA_CLIENTES,
  ATUALIZA_GATES,
  ATUALIZA_TRANSPORTADORAS,
  ATUALIZA_TERMINAIS,
  ATUALIZA_AGENDAMENTO,
  ATUALIZA_ATENDIMENTO,
  CONCAT_TRANSPORTADORAS,
  ATUALIZA_ESTACIONAMENTOS,
  ATUALIZA_SETORES,
  ATUALIZA_VAGAS,
  ATUALIZA
}
