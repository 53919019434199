import axios from 'axios'
class MyIntegerEnum {
  constructor(id, name) {
    this._id = id
    this._name = name
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  static create(id, name) {
    return new this(id, name)
  }
}
const getUrl = (path, params) => {
  const uri = axios.getUri({
    url: path,
    params,
  })
  const base = axios.defaults.baseURL
  const slash = base.slice(-1) == '/' ? '' : '/'
  const url = base + slash + uri
  return url
}
const downloadUrl = async (url, filename = 'download.xlsx', config = {}) => {
  config['responseType'] = 'blob'
  try {
    const { data } = await axios.get(url, config)
    const _url = URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = _url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (error) {
    return Promise.reject(error)
  }
}
export { MyIntegerEnum, downloadUrl, getUrl }
