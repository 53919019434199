<template>
  <form-wizard
    id="base-form-wizard"
    @on-complete="$emit('on-complete')"
    color="#00aa9e"
    :class="{ hasError }"
    :title="title"
    :subtitle="null"
  >
    <slot />

    <template slot="prev">
      <v-btn color="black" dark>
        <i class="mdi mdi-chevron-left mr-1"></i>
        Anterior
      </v-btn>
    </template>
    <template slot="next">
      <v-btn class="mr-2" @click="$emit('close')">
        Cancelar
        <i class="mdi mdi-close ml-1"></i>
      </v-btn>
      <v-btn color="info">
        Próximo
        <i class="mdi mdi-chevron-right ml-1"></i>
      </v-btn>
    </template>
    <template slot="finish">
      <v-btn class="mr-2" @click="$emit('close')">
        Cancelar
        <i class="mdi mdi-close ml-1"></i>
      </v-btn>
      <v-btn color="success">
        Finalizar
        <i class="mdi mdi-content-save ml-1"></i>
      </v-btn>
    </template>
  </form-wizard>
</template>

<script>
import { FormWizard } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'BaseFormWizard',

  props: {
    hasError: Boolean,
    title: {
      type: String,
      required: true,
    },
  },

  components: { FormWizard },
}
</script>

<style lang="scss">
.hasError .wizard-navigation .wizard-nav {
  .active a .wizard-icon-circle > div {
    background-color: transparent !important;
    i {
      color: #fff !important;
    }
  }
}

#base-form-wizard .wizard-navigation .wizard-nav {
  .active {
    a .wizard-icon-circle > div {
      background-color: transparent !important;
      i {
        color: #00aa9e;
      }
    }
  }
  li:not(.active ~ li):not(.active) {
    .wizard-icon-circle {
      background-color: #00aa9e;
      i {
        color: white;
      }
    }
    span {
      color: #00aa9e;
    }
  }
}
</style>