import axios from 'axios'

class AuthService {
  refresh_url = 'api/v1/token/refresh/'

  async login(user) {
    try {
      const { data } = await axios.post('api/v1/token/', {
        username: user.username,
        password: user.password,
      })
      if (data.access) {
        localStorage.setItem('user', JSON.stringify(data))
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`
      }
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async refresh(token) {
    try {
      const user = JSON.parse(localStorage.getItem('user'))
      const { data } = await axios.post(this.refresh_url, {
        refresh: token,
      })
      if (data.access) {
        user.access = data.access
        user.refresh = data.refresh
        localStorage.setItem('user', JSON.stringify(user))
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`
      }
      return user
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async reset(user) {
    try {
      const { data } = await axios.post('api/v1/token_reset/', {
        username: user.username,
      })
      if (data.access) {
        localStorage.setItem('user', JSON.stringify(data))
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`
      }
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getUserByParams(params) {
    try {
      const { data } = await axios.get('api/v1/users/', {
        params,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updatePerfilUsuario(perfil) {
    try {
      const _ = require('lodash')
      const { data } = await axios.patch(
        'api/v1/accounts/profile/',
        _.omit(perfil, '[id]')
      )
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changePassword(passwordInfo, isManager, usuario) {
    try {
      if (isManager) {
        await axios.post(`api/v1/users/${usuario}/password/`, passwordInfo)
      } else {
        await axios.post('api/v1/accounts/change-password/', passwordInfo)
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  logout() {
    localStorage.removeItem('user')
    delete axios.defaults.headers.common['X-CurrentTenant']
    delete axios.defaults.headers.common['Authorization']
  }

  async getPerfilUsuario() {
    try {
      const { data } = await axios.get('api/v1/accounts/profile')
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getPlantoes(params) {
    try {
      const { data } = await axios.get('/api/v1/plantoes/', { params })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getPlantão(public_id) {
    try {
      const { data } = await axios.get(`/api/v1/plantoes/${public_id}/`)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new AuthService()
