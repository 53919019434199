import axios from 'axios'

class EmpresaApi {
  async adicionarMotorista(novoMotorista) {
    const id = novoMotorista.empresa
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${id}/motoristas/`,
        novoMotorista
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editarMotorista(motorista) {
    const { empresa, public_id } = motorista
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/empresas/${empresa}/motoristas/${public_id}/`,
        motorista
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async adicionarAdminEmpresa(user, tenantAdminEmpresa, empresa, grupo, tipo) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${empresa}/colaboradores/`,
        {
          user,
          tenantAdminEmpresa,
          grupo,
          empresa,
          tipo,
        }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async atualizarPerfilRelacionamento(tipo, relacionamento, perfil) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/${tipo}/${relacionamento}/`,
        {
          perfil,
        }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async updateEmpresa(empresaPublicId, payload) {
    try {
        const { data } = await axios.patch(`api/v1/tenants/empresas/${empresaPublicId}/`, payload)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
  }

  async setMenu(empresa, menu) {
    try {
        const { data } = await axios.patch(`api/v1/tenants/empresas/${empresa}/`, { menu })
        console.log(data);
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
  }

  async getMenusByEmpresa(empresa, params) {
    const { data } = await axios.get(`api/v1/tenants/empresas/${empresa.empresa}/menus/`, {params})
    console.log(data);
    return data
  }
}

export default new EmpresaApi()
