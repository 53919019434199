export const getDefaultState = () => {
  return {
    historico: [],
    historico_simple: [],
    historico_item: {},
  }
}

const RESET_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

const SET_HISTORICO = (state, payload) => {
  state.historico = payload
}
const SET_HISTORICO_SIMPLE = (state, payload) => {
  state.historico_simple = payload
}
const SET_HISTORICO_ITEM = (state, payload) => {
  state.historico_item = payload
}

export default {
  RESET_STATE,
  SET_HISTORICO,
  SET_HISTORICO_ITEM,
  SET_HISTORICO_SIMPLE,
}
