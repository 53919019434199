import axios from 'axios'

class UsuarioApi {
  async getUsuarioByCpf(cpf) {
    try {
      const { data } = await axios.get(`api/v1/users/?cpf=${cpf}`)
      return Promise.resolve(data[0])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getUsuarioByParams(params) {
    try {
      const { data } = await axios.get(`api/v1/users/`, {
        params
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getUsuarios() {
    const { data } = await axios.get('api/v1/users/')
    return data
  }

  async getPerfilUsuarioAtual() {
    const { data } = await axios.get('/api/v1/users/current/')
    return data
  }


  async addUsuario(usuario) {
    try {
      const { data } = await axios.post('api/v1/accounts/register/', usuario)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editarUsuario(usuario) {
    try {
      const { data } = await axios.patch(`api/v1/users/${usuario.public_id}/`, usuario)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new UsuarioApi()
