export const getDefaultState = () => {
  return {
    faturamento: [],
  }
}

export default {
  SET_FATURAMENTO(state, faturamento) {
    state.faturamento = faturamento
  },
  ADD_FATURAMENTO(state, novoFaturamento) {
    state.faturamento.push(novoFaturamento)
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}


