import axios from 'axios'

class ContratosApi {
  async getContratos(empresaPublicID, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/contratos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getContratosInativos(empresaPublicID, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/contratos/inativos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getCliente(empresaPublicID, clientePublicID) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/clientes/${clientePublicID}/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getClientes(empresaPublicID, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/clientes/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getClientesSimple(empresaPublicID, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/clientes/simple/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getTransportadoras(empresaPublicID, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/transportadoras/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getTransportadorasSimple(empresaPublicID, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/transportadoras/simple/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getPossiveisGates(empresaPublicID) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/possiveis-gates/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async adicionarContrato(empresaAtual, novoContrato) {
    const id = empresaAtual.public_id
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${id}/contratos/`,
        novoContrato
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async desativarContrato(empresaAtual, contrato) {
    const empresa_id = empresaAtual.public_id
    try {
      await axios.delete(
        `api/v1/tenants/empresas/${empresa_id}/contratos/${contrato.public_id}`
      )
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async ativarContrato(empresaAtual, contrato) {
    const empresa_id = empresaAtual.public_id
    try {
      await axios.get(
        `api/v1/tenants/empresas/${empresa_id}/contratos/${contrato.public_id}/activate/`
      )
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarContrato(empresa, contrato, contatos) {
    const empresa_id = empresa.public_id
    try {
      const {
        data,
      } = await axios.patch(
        `api/v1/tenants/empresas/${empresa_id}/contratos/${contrato}/`,
        { contatos }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async atualizarParceria(empresa, contrato, parceria) {
    const empresa_id = empresa.public_id
    try {
      const {
        data,
      } = await axios.patch(
        `api/v1/tenants/empresas/${empresa_id}/contratos/${contrato}/`,
        { parceria }
      )
      return Promise.resolve(data.parceria)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addArmazens(empresa, contratoPublicID, armazens) {
    const empresaPublicID = empresa.public_id
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${empresaPublicID}/contratos/${contratoPublicID}/armazens/`,
        armazens
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editArmazem(empresa, contratoPublicID, armazem) {
    const empresaPublicID = empresa.public_id
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/empresas/${empresaPublicID}/contratos/${contratoPublicID}/armazens/${armazem.public_id}/`,
        armazem
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteArmazem(empresa, contratoPublicID, armazemPublicID) {
    const empresaPublicID = empresa.public_id
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/empresas/${empresaPublicID}/contratos/${contratoPublicID}/armazens/${armazemPublicID}/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getArmazens(empresa, contratoPublicID) {
    const empresaPublicID = empresa.public_id
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/contratos/${contratoPublicID}/armazens/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getRelatorioParceria(contrato, params) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/parceria/${contrato}/relatorio/`,
        params
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export default new ContratosApi()
