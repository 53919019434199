import actions from './actions'
import mutations from './mutations'


export const getDefaultControlador = () => {
  return {
    tipo: {
      id: null,
      name: ''
    },
    objetos: [],
    utilizador: null,
    limitador: 9999,
  }
}

export const getDefaultState = () => {
  return {
    grupos: [],
    todosGrupos: [],
    colaboradores: [],
    colaboradoresInativos: [],
    perfis: [],
    controladores: [],
    controlador: getDefaultControlador()
  }
}

export const gerencial = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  mutations,
}
