import SubProdutosApi from '@/services/subprodutos'

const getDefaultState = () => {
  return {
    subProdutos: [],
  }
}

export const subProdutos = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setSubProduto(state, subProdutos) {
      state.subProdutos = subProdutos
    },

    addSubProduto(state, nova) {
      state.subProdutos.push(nova)
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getSubProduto({ commit }, params) {
      const itens = await SubProdutosApi.getSubProduto(params)
      commit('setSubProduto', itens)
    },
    async addSubProduto({ dispatch }, novo) {
      await SubProdutosApi.addSubProduto(novo)
      dispatch('getSubProduto')
    },
    async editSubProduto({ dispatch }, novo) {
      await SubProdutosApi.editSubProduto(novo)
      dispatch('getSubProduto')
    }
  },
}