import { Sequences } from '@/utils/permissions'

export default [
  {
    path: '/patio',
    component: () => import('@/layouts/full-layout/Layout'),
    children: [
      {
        name: 'Estacionamento',
        path: 'Estacionamento',
        component: () => import('@/views/patio/Estacionamento'),
        meta: {
          title: 'Estacionamentos',
          BasicPermissions: [Sequences.Estacionamento],
        },
      },
      {
        name: 'Setores',
        path: 'Estacionamento/Setores',
        component: () => import('@/views/patio/Setores/'),
        meta: {
          title: 'Setores',
        },
      },
      {
        name: 'Setor',
        path: 'Estacionamento/Setores/Setor',
        component: () => import('@/views/patio/Setor/'),
        meta: {
          title: 'Setor',
        },
      },
      {
        name: 'embalagem',
        path: 'embalagem',
        component: () => import('@/views/patio/Embalagem'),
        meta: {
          title: 'Embalagem',
        },
      },

      {
        name: 'cancela',
        path: 'cancela',
        component: () => import('@/views/patio/Cancela'),
        meta: {
          title: 'Cancela',
        },
      },

      {
        name: 'FormasPagamento',
        path: 'FormasPagamento',
        component: () => import('@/views/patio/FormaPagamento'),
        meta: {
          title: 'Formas de Pagamento',
        },
      },
      {
        name: 'Terminal',
        path: 'Terminal',
        component: () => import('@/views/patio/Terminal'),
        meta: {
          title: 'Terminais',
        },
      },
      {
        name: 'Gestao de Terminal',
        path: 'GestaoTerminal',
        component: () => import('@/views/patio/GestaoTerminal'),
        meta: {
          title: 'Gestão de Terminais',
        },
      },
      {
        name: 'Gestao Cliente Produtos',
        path: 'GestaoClienteProdutos',
        component: () => import('@/views/patio/GestaoClienteProdutos'),
        meta: {
          title: 'Gestão Produtos do Cliente',
        },
      },
      {
        name: 'Unidade',
        path: 'Unidade',
        component: () => import('@/views/patio/Unidade'),
        meta: {
          title: 'Unidades',
        },
      },
    ],
  },
]
