import actions from './actions'

export const logistica = {
  namespaced: true,
  state: {
    janela_carga_descarga: {},
    janelas_carga_descarga: [],
  },
  mutations: {
    SET_JANELA_CARGA_DESCARGA(state, janela_carga_descarga) {
      state.janela_carga_descarga = janela_carga_descarga
    },
    SET_JANELAS_CARGA_DESCARGA(state, janelas_carga_descarga) {
      state.janelas_carga_descarga = janelas_carga_descarga
    },
    ADD_JANELA_CARGA_DESCARGA(state, janela_carga_descarga) {
      state.janelas_carga_descarga.push(janela_carga_descarga)
    },
    EDIT_JANELA_CARGA_DESCARGA(state, nova_janela_carga_descarga) {
      let antiga_janela_carga_descarga = state.janelas_carga_descarga.find(
        (janela_carga_descarga) =>
          janela_carga_descarga.public_id ===
          nova_janela_carga_descarga.public_id
      )
      if (antiga_janela_carga_descarga) {
        Object.assign(antiga_janela_carga_descarga, nova_janela_carga_descarga)
      }
    },
    DELETE_JANELA_CARGA_DESCARGA(state, janela_carga_descarga) {
      state.janelas_carga_descarga = state.janelas_carga_descarga.filter(
        (r) => r.public_id !== janela_carga_descarga.public_id
      )
    },
  },
  actions,
}
