import Vue from 'vue'
import Toasted from 'vue-toasted';

Vue.use(Toasted, { iconPack: 'custom-class' })

Vue.toasted.register('error',
    (payload) => {
        if (!payload.message) {
            return "Oops!! Ocorreu algum erro."
        }
        return payload.message;
    },
    {

        type: 'error',
        icon: {
            name: 'fas fa-times',
        },
        action: {
            text: 'Fechar',
            onClick: (e, toastObject) => {
                toastObject.goAway(0);
            }
        },
    }
)

Vue.toasted.register('success',
    (payload) => {
        if (!payload.message) {
            return "Oops!! Ocorreu algum erro."
        }
        return payload.message;
    },
    {

        type: 'success',
        icon: {
            name: 'fas fa-check',
        },
        action: {
            text: 'Fechar',
            onClick: (e, toastObject) => {
                toastObject.goAway(0);
            }
        },
    }
)