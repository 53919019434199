import patioApi from '@/services/patio'

const reset = ({ commit }) => {
  commit('RESET_STATE')
}

const atualizaGates = async ({ commit, rootState }, params) => {
  try {
    let empresa
    if ('proprietario' in params) {
      empresa = params.proprietario
      delete params.proprietario
    } else {
      empresa = rootState.auth.empresaAtual.public_id
    }
    let supervisorio = false
    if ('supervisorio' in params) {
      supervisorio = params.supervisorio
      delete params.supervisorio
    }
    const data = await patioApi.getGates(empresa, params, supervisorio)
    commit('ATUALIZA_GATES', data.results)
  } catch (err) {
    return Promise.reject(err)
  }
}
const getGates = async ({ rootState }, params) => {
  try {
    let empresa
    if ('proprietario' in params) {
      empresa = params.proprietario
      delete params.proprietario
    } else {
      empresa = rootState.auth.empresaAtual
    }
    let supervisorio = false
    if ('supervisorio' in params) {
      supervisorio = params.supervisorio
      delete params.supervisorio
    }
    const data = await patioApi.getGates(
      empresa.public_id,
      params,
      supervisorio
    )
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}
const pesar = async ({ rootState }, pesagem) => {
  let empresa
  if ('proprietario' in pesagem) {
    empresa = pesagem.proprietario
    delete pesagem.proprietario
  } else {
    empresa = rootState.auth.empresaAtual
  }
  const gate_service = pesagem.gate_service
  try {
    const data = await patioApi.pesar(empresa.public_id, gate_service, pesagem)
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}
const ocrManual = async ({ rootState }, ocrManual) => {
  let empresa
  if ('proprietario' in ocrManual) {
    empresa = ocrManual.proprietario
    delete ocrManual.proprietario
  } else {
    empresa = rootState.auth.empresaAtual
  }
  const gate_service = ocrManual.gate_service
  try {
    const data = await patioApi.ocrManual(
      empresa.public_id,
      gate_service,
      ocrManual
    )
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export default {
  reset,
  atualizaGates,
  getGates,
  pesar,
  ocrManual,
}
