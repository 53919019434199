import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import gerencial from './gerencial'
import administrativo from './administrativo'
import logistica from './logistica'
import comercial from './comercial'
import financeiro from './financeiro'
import patio from './patio'
import sistema from './sistema'
import relatorios from './relatorios'
import extracao from './extracao'
import store from '@/store'
import { collaboratorAbility, tenantAbility } from '@/plugins/casl'

Vue.use(Router)

const ACCESS_DENIED_MESSAGE =
  'Ops! Você não tem permissão para acessar esta tela'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes: [
    {
      path: '/',
      redirect: 'dashboards/analytical',
      component: () => import('@/layouts/full-layout/Layout'),
      children: [
        {
          name: 'Home',
          path: '',
          component: () => import('@/layouts/blank-layout/Blanklayout'),
        },
        // {
        //     name: 'Analytical',
        //     path: 'dashboards/analytical',
        //     component: () => import('@/views/dashboards/Analytical'),
        // },
      ],
    },
    ...patio,
    ...gerencial,
    ...administrativo,
    ...logistica,
    ...comercial,
    ...financeiro,
    ...sistema,
    ...relatorios,
    ...extracao,
    {
      path: '/auth',
      component: () => import('@/layouts/blank-layout/Blanklayout'),
      children: [
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/auth/Login'),
        },
        {
          name: 'Reset',
          path: 'reset',
          component: () => import('@/views/auth/Reset'),
        },
        {
          name: 'Send Success',
          path: 'sendSuccess',
          component: () => import('@/views/auth/SendSuccess'),
        },
        {
          name: 'First Access',
          path: 'firstAccess',
          component: () => import('@/views/auth/FirstAccess'),
        },
      ],
    },
    {
      name: 'Consulta',
      path: '/consulta',
      component: () => import('@/views/Consulta'),
    },
    {
      path: '*',
      component: () => import('@/views/404'),
    },
  ],
})

function getItemWithSequence(object, result) {
  if (Object.prototype.hasOwnProperty.call(object, 'sequence'))
    result.push(object.sequence)

  for (var i = 0; i < Object.keys(object).length; i++) {
    if (typeof object[Object.keys(object)[i]] == 'object') {
      getItemWithSequence(object[Object.keys(object)[i]], result)
    }
  }
}

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/login', '/auth/reset', '/auth/sendSuccess', '/auth/firstAccess', '/register']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  document.title = to.meta.title || 'Rota Certa Sistemas - App'

  const abilities = store.state.abilities
  collaboratorAbility.update(abilities.collaboratorAbilities)
  tenantAbility.update(abilities.tenantAbilities)
  
  if (authRequired && !loggedIn) {
    next('/auth/login')
  } else if (to.meta.BasicPermissions && to.meta.BasicPermissions.length) {
    const menus = []
    getItemWithSequence(store.state.menu.items, menus)
    if (!to.meta.BasicPermissions.every((p) => menus.includes(p))) {
      store.dispatch('template/errorMessage', ACCESS_DENIED_MESSAGE, {
        root: true,
      })
      next('/')
    } else {
      next()
    }
  } else {
    if((loggedIn != null && JSON.parse(loggedIn).is_reset_senha) && to.name != 'First Access')
    {
      //var user = encodeURIComponent(JSON.parse(loggedIn).public_id);
      //var path = '/auth/firstAccess?user=' + user;
      const path = '/auth/firstAccess';
      next(path)
    }
    else {
      next()
    }
  }
})

function patchRouterMethod(router, methodName) {
  router['old' + methodName] = router[methodName]
  router[methodName] = async function (location) {
    return router['old' + methodName](location).catch((error) => {
      if (error.name === 'NavigationDuplicated') {
        return this.currentRoute
      }
      throw error
    })
  }
}

patchRouterMethod(router, 'push')
patchRouterMethod(router, 'replace')

export default router
