import LogisticaApi from '@/services/logistica'

const getJanelasCargaDescarga = async (
  { commit, rootState },
  params,
  empresa = rootState.auth.empresaAtual
) => {
  try {
    const janelas_carga_descarga = await LogisticaApi.getJanelasCargaDescarga(
      empresa,
      params
    )
    commit('SET_JANELAS_CARGA_DESCARGA', janelas_carga_descarga)
    return Promise.resolve(janelas_carga_descarga)
  } catch (error) {
    commit('SET_JANELAS_CARGA_DESCARGA', [])
    return Promise.reject(error)
  }
}

const getJanelaCargaDescarga = async ({ commit }, janela_carga_descarga) => {
  try {
    const _janela_carga_descarga = await LogisticaApi.getJanelaCargaDescarga(
      janela_carga_descarga
    )
    commit('SET_JANELA_CARGA_DESCARGA', _janela_carga_descarga)
    return Promise.resolve(_janela_carga_descarga)
  } catch (error) {
    return Promise.reject(error)
  }
}

const addJanelaCargaDescarga = async (
  { commit, rootState },
  janela_carga_descarga,
  empresa = rootState.auth.empresaAtual
) => {
  try {
    const _janela_carga_descarga = await LogisticaApi.addJanelaCargaDescarga(
      empresa,
      janela_carga_descarga
    )
    commit('ADD_JANELA_CARGA_DESCARGA', _janela_carga_descarga)
    return Promise.resolve(_janela_carga_descarga)
  } catch (error) {
    return Promise.reject(error)
  }
}

const editJanelaCargaDescarga = async (
  { commit, rootState },
  janela_carga_descarga,
  empresa = rootState.auth.empresaAtual
) => {
  try {
    const _janela_carga_descarga = await LogisticaApi.editJanelaCargaDescarga(
      empresa,
      janela_carga_descarga
    )
    commit('EDIT_JANELA_CARGA_DESCARGA', _janela_carga_descarga)
    return Promise.resolve(_janela_carga_descarga)
  } catch (error) {
    return Promise.reject(error)
  }
}

const deleteJanelaCargaDescarga = async (
  { commit, rootState },
  janela_carga_descarga,
  empresa = rootState.auth.empresaAtual
) => {
  try {
    const data = await LogisticaApi.deleteJanelaCargaDescarga(
      empresa,
      janela_carga_descarga
    )
    commit('DELETE_JANELA_CARGA_DESCARGA', janela_carga_descarga)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getJanelasCargaDescarga,
  getJanelaCargaDescarga,
  addJanelaCargaDescarga,
  editJanelaCargaDescarga,
  deleteJanelaCargaDescarga,
}
