import actions from './actions'

const getDefaultState = () => {
  return {
    fornecedores: [],
    caracteristicas: []
  }
}

export const fornecedores = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  mutations: {
    setFornecedores(state, fornecedores) {
      state.fornecedores = fornecedores
    },
    setNovoFornecedor(state, novoFornecedor) {
      state.fornecedores.push(novoFornecedor)
    },
    excluirFornecedor(state, fornecedor) {
      const index = state.fornecedores.indexOf(fornecedor)
      state.fornecedores.splice(index, 1)
    },
    ADD_CARACTERISTICA(state, caracteristica) {
      state.caracteristicas.push(caracteristica)
    },
    EDIT_CARACTERISTICA(state, caracteristica) {
      const index = state.caracteristicas.findIndex(c => c.public_id === caracteristica.public_id)
      state.caracteristicas.splice(index, 1, caracteristica)
    },
    EXCLUIR_CARACTERISTICA(state, caracteristicaID) {
      const caracteristica = state.caracteristicas.find(c => c.public_id === caracteristicaID)
      Object.assign(caracteristica, { active: false })
    },
    UNDELETE_CARACTERISTICA(state, caracteristicaID) {
      const caracteristica = state.caracteristicas.find(c => c.public_id === caracteristicaID)
      Object.assign(caracteristica, { active: true })
    },
    SET_CARACTERISTICAS(state, caracteristicas) {
      state.caracteristicas = caracteristicas
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    }
  },
  getters: {
    caracteristicasAtivas(state) {
      return state.caracteristicas.filter(c => c.active)
    },
    caracteristicasInativas(state) {
      return state.caracteristicas.filter(c => !c.active)
    },
  }
}
