export default {
  formasPagamentoConvenio(state) {
    return state.formasPagamento.filter(o => o.is_convenio)
  },
  formasPagamento(state) {
    return state.formasPagamento
  },
  formasPagamentoNotConvenio(state) {
    return state.formasPagamento.filter(o => !o.is_convenio)
  }
}
