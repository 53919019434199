import axios from 'axios'

class CaixaAPI {
  async getMeuCaixaAberto(colaborador, empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa}/colaboradores/${colaborador}/caixa-aberto/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getCaixaExtrato(empresaPublicID, caixaID) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresaPublicID}/caixas/${caixaID}/extrato/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async abrirCaixa(colaborador, empresa, payload) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${empresa}/colaboradores/${colaborador}/abrir-caixa/`,
        payload
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fecharCaixa(colaborador, empresa) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/empresas/${empresa}/colaboradores/${colaborador}/fechar-caixa/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async retiradaCaixa(colaborador, empresa, payload) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${empresa}/colaboradores/${colaborador}/retirada-caixa/`,
        payload
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async entradaCaixa(colaborador, empresa, payload) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/empresas/${empresa}/colaboradores/${colaborador}/entrada-caixa/`,
        payload
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editMovimentacao(empresa, payload) {
    try {
      const { data } = await axios.patch(
        `api/v1/tenants/empresas/${empresa}/movimentacoes/${payload.id}/`,
        payload
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changeTransportadora(servicoTriado, transportadora) {
    try {
      const {
        data,
      } = await axios.post(
        `/api/v1/patio/servicoTriado/${servicoTriado.public_id}/trocar_transportadora/`,
        { transportadora: transportadora.public_id }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async deleteMovimentacao(empresa, movimentacao) {
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/empresas/${empresa}/movimentacoes/${movimentacao}/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getResumoCaixa(gatePublicID, caixaPublicID) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${gatePublicID}/caixas/${caixaPublicID}/resumo/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async envia_integracao(gatePublicID, caixaPublicID) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${gatePublicID}/caixas/${caixaPublicID}/envia_integracao/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  
}

export default new CaixaAPI()
