import axios from 'axios'

const getURL = (gate) => `api/v1/tenants/gates/${gate.public_id}/controladores/`
const getDetailURL = (gate, controlador) => getURL(gate) + controlador.public_id + "/"

class ControladoresApi {
  async getControladoresDoGate(gate, params) {
    try {
      const { data } = await axios.get(getURL(gate), { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addControladorDoGate(gate, controlador) {
    try {
      const { data } = await axios.post(getURL(gate), controlador)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editControladorDoGate(gate, controlador) {
    try {
      console.log(controlador)
      const { data } = await axios.put(getDetailURL(gate, controlador), controlador)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async reiniciarControladorDoGate(gate, controlador) {
    try {
      await axios.get(getDetailURL(gate, controlador) + "reiniciar/")
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removerControladorDoGate(gate, controlador) {
    try {
      await axios.delete(getDetailURL(gate, controlador))
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

}

export default new ControladoresApi()
