import Vue from 'vue'
import { Ability } from '@casl/ability'
import store from '@/store'

export const collaboratorAbility = new Ability([])
export const tenantAbility = new Ability([])

//! não usar $can nem $ability
Vue.prototype.$canCollaborator = (...args) => collaboratorAbility.can(...args)
Vue.prototype.$canTenant = (...args) => tenantAbility.can(...args)
Vue.prototype.$canDo = (...args) =>
  (tenantAbility.can(...args) && collaboratorAbility.can(...args)) ||
  store.state.auth.empresaAtual?.system_admin
