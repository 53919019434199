export const getDefaultState = () => {
    return {
      tefs: [],
      tefsItems: []
    }
  }
  
  export default {
    SET_TEF(state, tefs) {
      state.tefs = tefs
    },
    SET_TEF_ITEMS(state, tefItems){
      state.tefsItems = tefItems
    },
    EDIT_TEF_ITEMS(state, tefUpdated) {
      const index = state.tefsItems.map(x => {
        return x.public_id
      }).indexOf(tefUpdated.public_id)
      state.tefItems.splice(index, 1, tefUpdated)
    }
  }
  
  