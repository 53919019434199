import caixaAPI from '@/services/caixa'

const getMeuCaixaAberto = async (
  { commit, rootState },
  colaborador = rootState.auth.user.public_id,
  empresa = rootState.auth.empresaAtual.public_id
) => {
  try {
    const data = await caixaAPI.getMeuCaixaAberto(colaborador, empresa)
    commit('SET_CAIXA_ABERTO', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getCaixaExtrato = async (
  { commit, rootState },
  { caixaID, empresaPublicID = rootState.auth.empresaAtual.public_id }
) => {
  try {
    const data = await caixaAPI.getCaixaExtrato(empresaPublicID, caixaID)
    commit('SET_CAIXA_EXTRATO', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const abrirCaixa = async ({ commit, rootState }, payload) => {
  const colaborador = rootState.auth.user.public_id
  const empresa = rootState.auth.empresaAtual.public_id
  try {
    const data = await caixaAPI.abrirCaixa(colaborador, empresa, payload)
    commit('SET_CAIXA_ABERTO', data)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const fecharCaixa = async ({ commit, rootState }) => {
  const colaborador = rootState.auth.user.public_id
  const empresa = rootState.auth.empresaAtual.public_id
  try {
    const data = await caixaAPI.fecharCaixa(colaborador, empresa)
    commit('RESET_STATE')
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const retiradaCaixa = async ({ dispatch, rootState }, payload) => {
  const colaborador = rootState.auth.user.public_id
  const empresa = rootState.auth.empresaAtual.public_id
  try {
    await caixaAPI.retiradaCaixa(colaborador, empresa, payload)
    dispatch('getMeuCaixaAberto')
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const entradaCaixa = async ({ dispatch, rootState }, payload) => {
  const colaborador = rootState.auth.user.public_id
  const empresa = rootState.auth.empresaAtual.public_id
  try {
    await caixaAPI.entradaCaixa(colaborador, empresa, payload)
    dispatch('getMeuCaixaAberto')
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const editMovimentacao = async ({ dispatch, rootState }, payload) => {
  const empresa = rootState.auth.empresaAtual.public_id
  try {
    await caixaAPI.editMovimentacao(empresa, payload)
    dispatch('getMeuCaixaAberto')
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}
const changeTransportadora = async (_, { servico_triado, transportadora }) => {
  try {
    const data = await caixaAPI.changeTransportadora(
      servico_triado,
      transportadora
    )
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const deleteMovimentacao = async ({ dispatch, rootState }, movimentacao) => {
  const empresa = rootState.auth.empresaAtual.public_id
  try {
    await caixaAPI.deleteMovimentacao(empresa, movimentacao)
    dispatch('getMeuCaixaAberto')
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const getResumoCaixa = async ({ rootState }, caixaPublicID) => {
  const gatePublicID = rootState.auth.empresaAtual.public_id
  try {
    const data = await caixaAPI.getResumoCaixa(gatePublicID, caixaPublicID)
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getMeuCaixaAberto,
  getCaixaExtrato,
  abrirCaixa,
  fecharCaixa,
  retiradaCaixa,
  entradaCaixa,
  editMovimentacao,
  changeTransportadora,
  deleteMovimentacao,
  getResumoCaixa,
}
