import DistribuicaoApi from '@/services/distribuicao'

const getDefaultState = () => {
  return {
    distribuicao: [],
  }
}

export const distribuicao = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setDistribuicao(state, distribuicao) {
      state.distribuicao = distribuicao
    },

    addProduto(state, nova) {
      state.distribuicao.push(nova)
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getDistribuicao({ commit }, navio_id, params) {
      const itens = await DistribuicaoApi.getDistribuicao(navio_id, params)
      commit('setDistribuicao', itens)
    },
    async addDistribuicao(_, distribuicao) {
      try {
        const data = await DistribuicaoApi.addDistribuicao(distribuicao)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async editDistribuicao(_, distribuicao) {
      try {
        const data = await DistribuicaoApi.editDistribuicao(distribuicao)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async deleteDistribuicao({ dispatch }, distribuicao) {
      try {
        const { navio } = distribuicao
        await DistribuicaoApi.deleteDistribuicao(distribuicao)
        dispatch('getDistribuicao', navio.id)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
