export const getDefaultState = () => {
  return {
    regras: [],
  }
}

export default {
  SET_REGRAS(state, payload) {
    state.regras = payload
  },
  ADD_REGRA(state, payload) {
    state.regras.push(payload)
  },
  EDIT_REGRA(state, payload) {
    const regra = state.regras.find(o => o.public_id === payload.public_id)
    Object.assign(regra, payload)
  },
  DELETE_REGRA(state, payload) {
    state.regras = state.regras.filter(o => o.public_id !== payload)
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}
