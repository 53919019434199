export const getDefaultState = () => {
  return {
    caixaAberto: {
      numero: '',
      data_hora_abertura: '',
      data_hora_fechamento: '',
      movimentacoes: [],
      colaborador: ""
    },
    caixaExtrato: {
      caixa: {
        numero: '',
        data_hora_abertura: '',
        data_hora_fechamento: '',
        movimentacoes: [],
        colaborador: ""
      },
      servicos: [],
      manuais: []
    },
  }
}

const RESET_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

const SET_CAIXA_ABERTO = (state, payload) => {
  state.caixaAberto = payload
}

const SET_CAIXA_EXTRATO = (state, payload) => {
  state.caixaExtrato = payload
}


export default {
  SET_CAIXA_ABERTO,
  SET_CAIXA_EXTRATO,
  RESET_STATE
}
