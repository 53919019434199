import actions from './actions'

const getDefaultState = () => {
  return {
    motoristas: [],
  }
}

export const motoristas = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  mutations: {
    setMotoristas(state, motoristas) {
      state.motoristas = motoristas
    },
    setNovoMotirista(state, motorista) {
      if (state.motoristas) {
        state.motoristas.push(motorista)
      } else {
        state.motoristas = motorista
      }
    },
    excluirMotorista(state, motorista) {
      state.motoristas = state.motoristas.filter(m => m.public_id != motorista.public_id);
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
}