import actions from './actions'

export const financeiro = {

  namespaced: true,
  state: {
    entrada: {},
    entradas: [],
    saida: {},
    saidas: [],
    ordemEtapas: [],
    ordemEtapa: {},
  },

  mutations: {

    SET_ENTRADA(state, entrada) {
      state.entrada = entrada
    },
    SET_ENTRADAS(state, entradas) {
      state.entradas = entradas
    },
    ADD_ENTRADA(state, entrada) {
      state.entradas.push(entrada)
    },
    UPDATE_ENTRADA(state, novaEntrada) {
      let antigaEntrada = state.entradas.find(entrada => entrada.public_id === novaEntrada.public_id)
      Object.assign(antigaEntrada, novaEntrada)
    },
    DELETE_ENTRADA(state, entrada) {
      state.entradas = state.entradas.filter(r => r.public_id !== entrada.public_id)
    },

    SET_SAIDA(state, saida) {
      state.saida = saida
    },
    SET_SAIDAS(state, saidas) {
      state.saidas = saidas
    },
    ADD_SAIDA(state, saida) {
      state.saidas.push(saida)
    },
    UPDATE_SAIDA(state, novaEntrada) {
      let antigaEntrada = state.saidas.find(saida => saida.public_id === novaEntrada.public_id)
      Object.assign(antigaEntrada, novaEntrada)
    },
    DELETE_SAIDA(state, saida) {
      state.saidas = state.saidas.filter(r => r.public_id !== saida.public_id)
    },

    SET_ORDEM_ETAPAS(state, ordemEtapas) {
      state.ordemEtapas = ordemEtapas
    },
    SET_ORDEM_ETAPA(state, ordemEtapa) {
      state.ordemEtapa = ordemEtapa
    },
    ADD_ORDEM_ETAPA(state, ordemEtapa) {
      state.ordemEtapas.push(ordemEtapa)
    },
    UPDATE_ORDEM_ETAPA(state, novaOrdemEtapa) {
      let antigaOrdemEtapa = state.ordemEtapas.find(ordemEtapa => ordemEtapa.public_id === novaOrdemEtapa.public_id)
      Object.assign(antigaOrdemEtapa, novaOrdemEtapa)
    },
    DELETE_ORDEM_ETAPA(state, ordemEtapa) {
      state.ordemEtapas = state.ordemEtapas.filter(r => r.public_id !== ordemEtapa.public_id)
    },

  },
  actions
}
