import actions from './actions'

const getDefaultState = () => {
  return {
    transportadoras: [],
    transportadoraEspecifica: '',
    params: {},
  }
}

export const transportadoras = {
  namespaced: true,
  state: getDefaultState(),
  actions,
  mutations: {
    setTransportadoras(state, transportadoras) {
      state.transportadoras = transportadoras
    },
    clearTransportadoras(state) {
      state.transportadoras = []
    },
    concatTransportadoras(state, obj) {
      state.transportadoras = state.transportadoras.concat(obj)
    },
    setTransportadoraEspecifica(state, transportadoraEspecifica) {
      state.transportadoraEspecifica = transportadoraEspecifica
    },
    setNovaTransportadora(state, transportadora) {
      if (state.transportadoras) {
        state.transportadoras.push(transportadora)
      } else {
        state.transportadoras = transportadora
      }
    },
    excluirTransportadora(state, transportadora) {
      state.transportadoras = state.transportadoras.filter(
        (t) => t.public_id != transportadora.public_id
      )
    },
    atualizarTransportadora(state, transportadora) {
      state.transportadoras.map((t) => {
        if (t.public_id === transportadora.public_id) {
          t = transportadora
        }
      })
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    UPDATE_PARAMS(state, params) {
      state.params = params
    },
  },
}
